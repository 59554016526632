import classes from "./PulseButton.module.scss";
import {useHistory} from "react-router-dom";


function PulseButton({text, redirectUrl}) {
    let history = useHistory()

    function handleRedirect() {
        history.push(redirectUrl)
    }

    return (
        <button onClick={handleRedirect} className={classes.button}>{text}</button>
    )
}

export default PulseButton
