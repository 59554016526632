import classes from "./Articles.module.scss";
import img1 from "../../assets/ako-si-vybrat-na-drevostavbu-768x336-1.jpg";
import {useEffect} from "react";


function SpravnaFirma() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Ako si vybrať správnu firmu pre stavbu domu</title>
            <img src={img1} alt="Ako si vybrať správnu firmu pre stavbu domu"/>
            <p>
                <strong>
                    V súvislosti s plánovanou výstavbou rodinného domu si musí stavebník zvyčajne hneď na začiatku zodpovedať na niektoré otázky a urobiť niekoľko dôležitých rozhodnutí. Ako prvé sa musí rozhodnúť, či chce stavať klasiku, teda murovaný dom alebo dopraje svojej rodine skôr pohodlie
                    montovaného domu. Ďalej sa musí rozhodnúť, či chce stavať svojpomocne alebo plánuje využiť služby stavebnej spoločnosti. Ak sa rozhodne pre dom na kľúč, nasleduje ďalšie nemenej dôležité rozhodovanie. Ako si vybrať tú správnu firmu pre stavbu rodinného domu?
                </strong>
            </p>
            <p>
                Stavebných spoločností zaoberajúcich sa vo všeobecnosti výstavbou rodinných domov neustále pribúda. V konečnom dôsledku to však určite nie je na škodu, pretože zdravé prostredie konkurencie hrá jednoznačne v prospech stavebníka.
                <strong>
                    {"\u00A0"}Kvalita realizovaných stavieb sa tak stáva najvyššou prioritou alebo ak chcete, najdôležitejším kritériom{"\u00A0"}
                </strong>
                pri stavbe domu na kľúč.
            </p>
            <p>
                Samozrejme to platí aj pre segment drevostavieb a o dosiahnutie maximálnej kvality sa snažia stavebné spoločnosti zaoberajúce sa výstavbou montovaných domov. Napriek tomu však môžu byť medzi týmito firmami isté odlišnosti. A nemáme tým na mysli technologický postup výstavby
                montovaného domu, ale predovšetkým precíznosť prevedenia stavby a použité materiály. Na rozdiel od minulosti, dnes má každý stavebník pomerne široké možnosti ako si o konkrétnej firme zistiť dostatok informácií.
            </p>
            <h1>
                Začať môžete internetom
            </h1>
            <p>
                Internet vám pri hľadaní odpovede na otázku ako si vybrať správnu firmu na stavbu domu môže byť v mnohom nápomocný. Ak si dáte trošku námahy a
                <strong>
                    {"\u00A0"}pri surfovaní po internete navštívite webové stránky firiem zaoberajúcich sa realizáciou výstavby montovaných domov{"\u00A0"}
                </strong>
                , môžete získať dostatok prvotných informácií.
            </p>
            <p>
                Stavebné spoločnosti tam okrem <a href="/katalog">katalógu domov</a> zvyčajne umiestňujú informácie o práve prebiehajúcich alebo zrealizovaných stavbáchmontovaných domov s priloženými fotografiami.Dozvedieť sa však môžete aj o konkrétnych výhodách alebo službách, ktoré stavebná firma
                ponúka a nájdete tam viacero užitočných informácií týkajúcich sa napríklad skladby stien.
            </p>
            <p>
                Bez povšimnutia by ste nemali nechať aj blog danej firmy, ktorý tiež vie byť zdrojom istých informácií a dokáže o firme čo to napovedať. Internet je výborným a osvedčeným pomocníkom pri získavaní informácií, no pri rozhodovaní sa pre konkrétnu firmu dokážu byť možno ešte osožnejšie
                referencie.
            </p>
            <h1>
                Referencie nepodceňujte, ale ani nepreceňujte
            </h1>
            <p>
                Niet najmenších pochybností, že
                <strong>
                    {"\u00A0"}pri výbere firmy môže dôležitú úlohu zohrať osobná skúsenosť a faktor dôvery v stavebnú spoločnosť.{"\u00A0"}
                </strong>
                Z výpovedí získaných takpovediac z prvej ruky si zvyčajne budete môcť poskladať mozaiku, ktorá vám poskytne najpravdivejšie informácie o danej firme.
            </p>
            <p>
                Pokúste sa preto získať aspoň nejaké referencie od bývalých klientov stavebnej spoločnosti a opýtajte sa na ich pozitívne i negatívne skúsenosti so stavebnou firmou.Pri získavaní informácií sa nebojte zájsť aj ďalej a pýtajte sa bývalých klientov na to, či firma dodržiavala všetky
                dohody. Alebo sa opýtajte na riešenie prípadných komplikácií. Zamestnanci firmy reagovali pružne a profesionálne alebo si problém pohadzovali ako horúci zemiak z ruky do rúk?
            </p>
            <p>
                Tieto informácie môžu mať pomerne veľkú výpovednú hodnotu.Anonymné recenzie z niektorých diskusných fór však netreba preceňovať. Naopak, treba ich brať z rezervou, pretože mnohé z nich môžu byť prinajmenšom zavádzajúce a neobjektívne.
            </p>
            <h1>
                Nechajte si vypracovať cenovú ponuku a veľa sa pýtajte
            </h1>
            <p>
                V prípade, že ste zvážili nazhromaždené informácie a rozhodli ste sa pre konkrétnu stavebnú firmu i pre konkrétny projekt domu nechajte si vypracovať cenovú ponuku.
                <strong>
                    {"\u00A0"}Vyberte si ponuku ktorá vám najviac vyhovuje{"\u00A0"}
                </strong>
                , majte však pritom na zreteli, že neprimerane alebo až podozrivo nízka ponuka môže v sebe skrývať isté riziko. V cenovej ponuke si všímajte či do celkovej ceny je zahrnutá položka za dopravu. Korektná firma nič neskrýva a náklady nevyhnutné na prepravu materiálu alebo zamestnancov
                firmy sama zahrnie do celkovej ceny.
            </p>
            <p>
                <strong>
                    Navštívte stavebnú spoločnosť a pokojne sa pýtajte na všetky možné otázky,{"\u00A0"}
                </strong>
                ktoré vás zaujímajú.
                <strong>
                    {"\u00A0"}Pýtajte sa napríklad na technológiu i priebeh výstavby, na kvalitu alebo životnosť používaných materiálov{"\u00A0"}
                </strong>
                a bez povšimnutia nenechajte ani otázku, prečo si máte nechať postaviť <a href="/katalog">montovaný dom</a> práve od ich spoločnosti. V prípade serióznych, profesionálnych a dôveryhodných odpovedí ktoré sú podložené faktami si môžete vyfúknuť a zagratulovať k výberu tej správnej
                stavebnej firmy.
            </p>
            <h1>
                Ako si teda vybrať správnu firmu pre stavbu domu?
            </h1>
            <p>
                Odpoveď na túto otázku by mohla znieť napríklad takto. S rozumom a pokojom Angličana. Pri výber tej správnej firmy si totiž naozaj treba nechať istý čas na premyslenie a na získanie základných informácií.
                <strong>
                    {"\u00A0"}Kvalita stavby a serióznosť stavebnej firmy totiž na dlhý čas ovplyvnia to, či budete so svojim domom spokojní a či budete na výstavbu domu radi spomínať.
                </strong>
            </p>
        </article>
    )
}

export default SpravnaFirma
