import {DATABASE_TEXTS} from "../../types";

export const setDatabaseTexts = ({texts = {}}) => dispatch => {
    dispatch({
        type: DATABASE_TEXTS,
        payload: {
            texts: texts
        }
    })
}
