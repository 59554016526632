import classes from "./Articles.module.scss";
import img1 from "../../assets/vykurovanie-drevodomy-bruno-768x336.jpg";
import {useEffect} from "react";


function AkoVykurovat() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Ako vykurovať drevodom? Poradíme!</title>
            <img src={img1} alt="Ako vykurovať drevodom?"/>
            <p>
                <strong>
                    Výber optimálnej vykurovacej sústavy pre drevodom nemusí byť až taký jednoduchý ako sa to na prvý pohľad môže zdať. Samozrejme aj pri vykurovaní nízkoenergetického montovaného domu prichádza do úvahy niekoľko možností vykurovania a v princípe sa môžete rozhodnúť pre ktorúkoľvek z
                    nich. Vykurovací systém v prípade moderných drevostavieb s nízkoenergetickým štandardom by však mal byť postavený predovšetkým na moderných technológiách, ktoré okrem iného ponúkajú najúspornejší spôsob kúrenia.
                </strong>
            </p>
            <p>
                Vo všeobecnosti bude pri výbere vhodného vykurovacieho systému pre drevodom potrebné prihliadať nielen na jeho dispozičné riešenie alebo lokalitu umiestnenia stavby. Nízkoenergetické montované domy sa totiž vyznačujú <strong>nadštandardnými tepelno-technickými vlastnosťami</strong> a
                preto bude
                potrebné tento fakt zohľadniť pri výbere i dimenzovaní optimálnej vykurovacej sústavy. Napokon výber vykurovacieho systému môžu tiež ovplyvniť súčasné trendy vykurovania, ktoré kladú veľký dôraz na ekológiu. V nasledujúcich riadkoch vám prinesieme pohľad na niektoré vykurovacie
                systémy, ktoré po všetkých stránkach spĺňajú požiadavky efektívneho a moderného vykurovania.
            </p>
            <h1>
                Tepelné čerpadlá
            </h1>
            <p>
                Technológia tepelných čerpadiel využívajúca obnoviteľné zdroje energie môže byť bez akýchkoľvek pochybností jednou z najlepších odpovedí na otázku ako vykurovať drevodom. <strong>Moderné tepelné čerpadlá</strong> oproti tradičným kotlom <strong>
                dosahujú výrazné úspory</strong>, pričom majú všetky
                predpoklady na to,
                aby počas celého roka podávali spoľahlivé výkony pri vykurovaní interiéru drevodomu alebo pri príprave teplej vody. <strong>Pri porovnaní s plynom môžu tepelné čerpadlá ušetriť až dve tretiny prevádzkových nákladov.</strong> V prípade nízkoenergetických montovaných domov, ktoré
                disponujú riadeným
                vetraním s rekuperáciou tepla sa však tepelný zisk dá ešte o niečo zvýšiť. Zakomponovaním tepelného čerpadla do systému riadeného vetrania sa využije odvádzané teplo, ktoré by inak zostalo nevyužité. Pridanou hodnotou tepelných čerpadiel je možnosť ochladenia interiéru drevodomu
                počas letných horúčav.
            </p>
            <p>
                Tepelné čerpadlá predstavujú ekonomický spôsob vykurovania, ktorý je súčasným trendom v oblasti stavebníctva. Záujem o tepelné čerpadlá každým rokom stúpa. Čo je však potešiteľné, <strong>tieto zariadenia sú cenovo z roka na rok dostupnejšie</strong> a vďaka tomu si ich môže dovoliť
                čoraz viac
                stavebníkov. Tepelné čerpadlá tak môžu spoľahlivo a dlhodobo znižovať aj vaše účty za vykurovanie. Ak sa chcete dozvedieť viac o fungovaní a výhodách týchto zariadení prečítajte si náš článok <a href="/clanky/tepelne-cerpadlo">„Tepelné čerpadlo“</a>.
            </p>
            <h1>
                Podlahové vykurovanie
            </h1>
            <p>
                Pri výbere vykurovacieho systému pre drevodom rozhodne neurobíte chybu ak siahnete po veľkoplošnom podlahovom vykurovaní. A je jedno či to bude elektrické alebo teplovodné podlahové vykurovanie. Nízkoteplotné <strong>podlahové vykurovacie systémy šíria teplo do priestoru rovnomerným
                sálaním,</strong>
                ktoré sa vo všeobecnosti považuje za najoptimálnejší spôsob vykurovania.
            </p>
            <p>
                Elektrický podlahový systém využíva na vykurovanie odporové rohože alebo jednožilové a dvojžilové okruhy káblov v ktorých dochádza k mimoriadne efektívnej premene elektrickej energie na tepelnú. Táto premena dosahuje takmer 100 % efektivitu. Elektrické vykurovacie prvky sa môžu
                ukladať do betónového alebo anhydridového poteru, ďalšou možnosťou je ich uloženie do stavebného lepidla pod keramickú podlahu. Ohrievané časti podlahy následne sálajú teplo do priestoru <a href="/katalog">drevodomu</a>. S inštaláciou elektrického podlahového vykurovania sa spája
                niekoľko výhod. Takáto
                vykurovacia sústava nevyžaduje technickú miestnosť na umiestnenie kotla, poprípade sklad paliva a umožňuje jednoduchú, ale spoľahlivú reguláciu výkonu vykurovania.
            </p>
            <p>
                Teplovodný podlahový systém využíva na vykurovanie teplonosné médium (voda), ktoré cirkuluje v polybuténových rúrkach. Na ohrev vody sa najčastejšie využívajú plynové kondenzačné kotly. <strong>K teplovodnej podlahovej vykurovacej sústave však môže byť pripojené aj tepelné
                čerpadlo</strong>, ktoré si
                s takýmto nízkoteplotným vykurovacím systémom výborne rozumie. Prevádzka vykurovacej sústavy s tepelným čerpadlom je potom mimoriadne efektívna a úsporná.
            </p>
            <h1>
                Infračervené vykurovanie
            </h1>
            <p>
                Napokon dáme priestor netradičnejšiemu, ale zato efektívnemu a bezpečnému spôsobu vykurovania. V prípade infravykurovania ide o moderný spôsob vykurovania, ktorý je však v našich končinách ešte stále <strong>pomerne málo známy a preto aj menej využívaný</strong>. Niektorí z vás sa
                však možno s
                princípom infračerveného vykurovania mali možnosť stretnúť pri návšteve infrasauny. Infrapanely určené na vykurovanie pracujú na podobnom princípe. Ako to teda funguje?
            </p>
            <p>
                Pri vykurovaní infrapanelmi sa využíva elektromagnetické vlnenie, ktoré je podobné slnečnému žiareniu a má priaznivé účinky na ľudský organizmus. Infračervené vlny vyžarujú teplo, ktoré sa využíva predovšetkým pri sálavých vykurovacích sústavách. V praxi je obdobou sálavého
                vykurovania vyššie spomínaný podlahový systém vykurovania. Narozdiel od podlahového vykurovania alebo ostatných vykurovacích systémov, <strong>pri infravykurovaní nedochádza k priamemu ohrevu vzduchu</strong>. Najprv sú ohrievané steny, podlaha alebo okolité predmety a až potom
                dochádza k
                sekundárnemu ohrevu vzduchu. Nedajte sa však pomýliť. Takýmto spôsobom vykurovania sa dosiahne maximálna efektivita bez tepelných strát súvisiacich s priamym ohriatím vzduchu a jeho následným únikom. Princíp sálavého tepla infračerveného vykurovania <strong>umožňuje dosiahnuť tepelný
                komfort
                aj pri nižšej teplote miestnosti</strong> a zároveň znižuje tepelnú stratu drevodomu, ktorá je dôsledkom vonkajších i vnútorných teplotných rozdielov. Aj vďaka tomu sa v súčasnosti infravykurovanie dostáva do popredia pri riešení tepelného komfortu nízkoenergetických a pasívnych
                domov.
            </p>
            <p>
                V prospech infravykurovania vypovedá <strong>dlhšia životnosť, bezpečnosť a bezúdržbovosť vykurovania.</strong> Spomenúť však môžeme aj jednoduchú inštaláciu infrapanelov. Tie môžu byť namontované na stenu alebo strop, ale problémom nie je ani ich zabudovanie do kazetového stropu, či
                sadrokartónu.
                Infrapanely so svojim minimalistickým dizajnom zároveň vyhovujú náročným estetickým kritériám.
            </p>
            <h1>
                Poradíme vám
            </h1>
            <p>
                Dnes sme v našom článku dali priestor modernejším alternatívam vykurovacích systémov, ktoré samozrejme nemusia vyhovovať každému. Možnosti výberu sú však širšie a rozhodnutie ako budete napokon vykurovať drevodom záleží iba na vás. Môžete uprednostniť tradičné plynové kotly alebo
                staviť na klasiku a pri vykurovaní drevodomu používať kotly na fosílne palivá. Vaša spokojnosť je pre nás najvyššou prioritou a preto sa pri výbere vhodného vykurovacieho systému môžete oprieť aj o naše skúsenosti. Posúdime všetky dôležité faktory a navrhneme vám najoptimálnejšie
                riešenie tepelného komfortu vášho drevodomu.
            </p>
        </article>
    )
}

export default AkoVykurovat
