import classes from "./LevelsOfConstructionText.module.scss";
import stromcek from "../../../assets/levels stromcek.png";
import EditWrapper from "../../editWrapper/EditWrapper";
import PulseButton from "../../pulseButton/PulseButton";
import {connect} from "react-redux";


function LevelsOfConstructionText({database}) {
    return (
        <EditWrapper dialogCase="simpleText" name="levels" fullHeight>
            <article className={classes.article}>
                <img src={stromcek} alt="logo"/>
                <p>{window.location.host.split(".").pop() !== "cz" ? database?.text : database?.textCZ}</p>
                <p>{window.location.host.split(".").pop() !== "cz" ? database?.text2 : database?.text2CZ}</p>
                <PulseButton text={window.location.host.split(".").pop() !== "cz" ?  database?.button : database?.buttonCZ} redirectUrl="/stupne-vystavby"/>
            </article>
        </EditWrapper>
    )
}

const mapStateToProps = state => {
    return {
        database: state.databaseTexts?.texts?.levels
    }
}

export default connect(mapStateToProps)(LevelsOfConstructionText)