import classes from "./AboutUs.module.scss";
import img1 from "../../../assets/o nas.jpg"
import logo from "../../../assets/logoFull.png";
import Header from "../../../components/admin/header/Header";
import Navbar from "../../../components/navbar/Navbar";
import PulseButton from "../../../components/pulseButton/PulseButton";
import PriceOfferCTA from "../../../components/priceOfferCTA/PriceOfferCTA";
import {useEffect} from "react";


function AboutUs() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (<span>
            <Header/>
            <Navbar/>
            <PriceOfferCTA/>

            <article className={classes.aboutUs}>
                <section className={classes.description}>
                    <p>
                        {window.location.host.split(".").pop() !== "cz" ? "Drevostavby Bruno vznikli v roku 1991. Okrem drevených domov sa v priebehu rokov portfólio našich služieb rozrástlo o montované domy, domy na kľúč a dnes patria do centra nášho záujmu aj nízkoenergetické domy, spĺňajúce všetky normy a štandardy." : "Firma Dřevostavby Bruno  vznikla v roce 1991. Kromě dřevostaveb se v průběhu let rozrostlo i porfolio našich služeb, a to o montované domy, domy na klíč a v současnosti i nízkoenergetické domy, které naplňují všeobecný zájem a splňují všechny normy a standardy.V současnosti patříme svým podílem na trhu ke slovenské špičce s několika desítkami realizací měsíčně."}
                    </p>
                    <div className={classes.logo}>
                        <img src={logo} alt="logo"/>
                    </div>
                </section>
                    <section className={classes.main}>
                        <h1>
                            PREČO STAVAŤ S NAMI?
                        </h1>
                        {window.location.host.split(".").pop() !== "cz" ?
                            <p>
                                Máme vyše <strong>30 ROKOV SKÚSENOSTÍ</strong> v oblasti navrhovania a výstavby domov, čo nám pomáha robiť našu prácu naozaj kvalitne. Samozrejmosťou je, že v cene vašej stavby je aj prémiová služba <strong>BLOWER DOOR TEST</strong>. Ak vás zaujíma, aké máme
                                realizácie,
                                môžete si pozrieť ktorýkoľvek náš rozostavaný alebo dokončený dom, stačí navštíviť sekciu Realizácie. Sme zodpovední k životnému prostrediu, preto od nás dostanete <strong>ENERGETICKÝ CERTIFIKÁT</strong>, revíziu bleskozvodu, ako aj elektroinštalácie. Pochôdzna časť
                                domu
                                v podkroví je v cene domu. Naše obvodové steny v štandarde majú hrúbku 420MM. Radi vam zrealizujeme aj <strong>TEPELNÉ ČERPADLO A ELEKTROKOTOL</strong>. Ponúkame možnosť akýchkoľvek svojpomocných prac. V momente vydania stavebného povolenia vieme začať stavať. Ak
                                neradi
                                riešite formality, <strong>POMÔŽEME VÁM S VYDANÍM STAVEBNÉHO POVOLENIA</strong>.
                            </p> :
                            <p>
                                Máme více než <strong>30 LET ZKUŠENOSTÍ</strong> v oblasti projektování a výstavby domů, což je důkazem toho, že děláme svou práci opravdu dobře. Samozřejmostí je mimo jiné, že cena Vaší stavby zahrnuje prémiovou službu <strong>BLOWER DOOR TEST</strong>.
                                Máte-li zájem se seznámit s konkrétními případy realizací, můžete si prohlédnout některý z našich postavených nebo dokončených domů, stačí navštívit sekci realizace.
                                Jsme zodpovědní k životnímu, prostředí, proto od nás obdržíte <strong>ENERGETICKÝ CERTIFIKÁT</strong>, samozřejmostí je i revize hromosvodu a elektroinstalace. Pochozí část domu v podkroví je zahrnuta v ceně domu. Obvodové stěny námi zhotovovaných domů mají ve
                                standardu sílu 420 mm. Rádi pro Váš nový dům dodáme a nainstalujeme<strong>TEPELNÉ ČERPADLO A ELEKTROKOTEL.</strong>. Nabízíme možnost jakékoliv svépomocné práce. V okamžiku vydání stavebního povolení můžeme začít stavět. Pokud neradi řešíte formality,<strong>POMŮŽEME
                                VÁM S VYDÁNÍM STAVEBNÍHO POVOLENÍ.</strong>.
                            </p>
                        }
                        <img src={img1} alt="O nás"/>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "DREVODOM, MONTOVANÝ ALEBO NÍZKOENERGETICKÝ DOM? PORADÍME VÁM" : "DŘEVSTAVBA, PREFABRIKOVANÝ NEBO NÍZKOENERGETICKÝ DŮM? PORADÍME VÁM"}
                        </h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ?
                                "Poznáte výhody drevených alebo nízkoenergetických domov? Neviete sa rozhodnúť medzi bungalovom alebo poschodovým domom, či pultovou, sedlovou alebo plochou strechou? Radi vám bezplatne poskytneme odborné poradenstvo. Náš odborný poradca Vás bude kontaktovať, dohodnete si stretnutie a spolu dáme Vášmu novému bývaniu tie správne proporcie." :
                                "Znáte výhody dřevěných nebo nízkoenergetických domů? Nemůžete se rozhodnout mezi bungalovem nebo dvouposchoďovým domem, pultovou, sedlovou či plochou střechou? Rádi Vám zdarma poskytneme odborné poradenství. Náš odborný poradce vás bude kontaktovat, domluví si s Vámi schůzku a společně dodáme vašemu novému bydlení ty správné a ideální proporce."
                            }
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "POSTAVTE DOM BEZ STAROSTÍ" : "POSTAVTE SI DŮM BEZ STAROSTÍ"}
                        </h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ?
                                "Vybrali ste si dom? Môžeme sa pustiť do práce. Po vybavení formalít a stavebného povolenia je vaša stavba už do 4 mesiacov hotová" :
                                "Vybrali jste si dům? Můžeme se pustit do práce. Po vyřízení formalit a stavebního povolení je Vaše stavba hotová do 4 měsíců"
                            }
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "MOŽNOSŤ VÝSTAVBY POČAS CELÉHO ROKA" : "MOŽNOST VÝSTAVBY PO CELÝ ROK"}
                        </h2>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "VLASTNÁ VÝROBA REZIVA A KVH HRANOLOV" : "VLASTNÍ VÝROBA ŘEZIVA A HRANOLŮ KVH"}

                        </h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ?
                                "Vďaka našej drevovýrobe dokážeme vyrobiť rezivo, certifikované KVH hranoly, schody, drevené okná, dvere alebo kompletný krov a steny. Všetky základné úpravy a výroba prebiehajú u nás." :
                                "Díky naší dřevozpracující výrobě jsme schopni vyrobit řezivo, certifikované KVH hranoly, schody, dřevěná okna, dveře nebo kompletní krov a stěny. Všechny základní úpravy a výroba probíhají u nás."
                            }
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "MYSLÍME DO BUDÚCNOSTI" : "MYSLÍME NA BUDOUCNOST"}
                        </h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ? "Všetky naše rodinné domy vyhovujú novej energetickej triede A0." : "Všechny naše rodinné domy odpovídají nové energetické třídě A0."}
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "ZÁRUKA NA DOM 5 ROKOV" : "Na celou budovu vám poskytneme záruku 5 let."}
                        </h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ? "Na celú stavbu Vám dáme záruku 5 rokov." : ""}
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "AK NEMÁTE VLASTNÝ PROJEKT, NÁŠ PROJEKTANT VÁM HO VYPRACUJE" : "POKUD NEMÁTE VLASTNÍ PROJEKT, NÁŠ PROJEKTANT HO PRO VÁS PŘIPRAVÍ"}
                        < /h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ?
                                "Ak ste si nevybrali z nášho katalógu, nevadí, náš projektant príde k Vám na pozemok a spoločne dáme domu tvar podľa Vašich predstáv" :
                                "Pokud jste si nevybrali z našeho katalogu, nevadí, náš projektant k vám přijede na pozemek a společně dáme domu tvar podle vašich představ."
                            }
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "PONÚKAME SLUŽBY HYPOTEKÁRNEHO PORADCU" : "NABÍZÍME SLUŽBY HYPOTEČNÍHO PORADCE"}
                        < /h2>
                        <p>
                            {window.location.host.split(".").pop() !== "cz" ?
                                "Pri riešení hypotéky vám radi poskytneme nášho hypotekárneho špecialistu, ktorý pre vás vyberie vhodnú hypotéku." :
                                "Pro řešení hypotéky Vám nabízíme služby našeho hypotečního specialisty, který pro Vás vybere ten správný a optimální způsob financování."
                            }
                        </p>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "REALIZUJEME VYSOKOKVALITNÉ STAVBY" : "REALIZUJEME VYSOCE KVALITNÍ STAVBY"}
                        < /h2>
                        <ul>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Na naše stavby používame len certifikované materiály" :
                                    "Pro naše stavby používáme pouze certifikované materiály"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Celá stavba je zabezpečená 100 percentou parozábranou + páskami systém Delta" :
                                    "Celý objekt je zajištěn 100 % parozábranou + páskami Delta systému"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Steny oplášťujeme z oboch strán OSB doskou ktorá zabezpečí vysokú stabilitu" :
                                    "Stěny oplášťujeme z obou stran OSB deskami, což zajišťuje vysokou stabilitu"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Do stien dávame certifikovanú lisovanú minerálnu vlnu Knauf, ktorá má vynikajúce tepelnoizolačné vlastnosti a ktorá je ekologicky a hygienicky nezávadná a je odolná voči hmyzu, hlodavcom a plesniam, je objemovo stála to znamená že po čase neklesne" :
                                    "Do stěn vkládáme certifikovanou lisovanou minerální vlnu Knauf, která má vynikající tepelně izolační vlastnosti, je ekologicky a hygienicky neškodná a je odolná vůči hmyzu, hlodavcům a plísním, je objemově stabilní, což znamená, že v průběhu let nedegraduje a nesesouvá se"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Izolujeme hydroizoláciou obvodové steny do výšky 30cm" :
                                    "Izolujeme hydroizolací obvodových stěn do výšky 30 cm"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Vodu robíme z hliník-plastu" :
                                    "Vodovodní a odpadní potrubí zhotovujeme z hliníku a plastu"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Používame protipožiarny sadrokartón v celom dome, v kúpeľni proti vlhkosti" :
                                    "Používáme protipožární sádrokartonové desky v celém domě, v koupelně proti vlhkosti"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Používame komín značky SCHIEDEL" :
                                    "Používáme komín značky SCHIEDEL "
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Rezivo a drevené časti sú impregnované proti škodcom Bochemitom" :
                                    "Řezivo a dřevěné části jsou impregnovány proti škůdcům přípravkem Bochemit"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Používame v obvodovej stene KVH hranoly o rozmere 140/60 , v priečkových stenách o rozmere 140/40 , 100/40" :
                                    "Hranoly o rozměrech 140/60 používáme v obvodové stěně KVH, v příčkách o rozměrech 140/40, 100/40"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Hrúbka našej obvodovej steny je 420 mm, zateplenie používame EPS o hrúbke 200mm" :
                                    "Síla obvodové stěny je 420 mm, používáme EPS izolaci o tloušťce 200 mm"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Strop izolujeme na 40 cm" :
                                    "Strop izolujeme na celkovou tloušťku 400 mm"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Pochôdzná časť stropu v cene domu" :
                                    "Pochozí část stropu je zahrnuta v ceně domu"
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Pasívne okná Salamander 92 v cene domu, pri montáži sa používajú vonkajšie, vnútorné pásky" :
                                    "Pasivní okna Salamander 92 jsou v ceně domu, při montáži se používají venkovní vnitřní pásky."
                                }
                            </li>
                            <li>
                                {window.location.host.split(".").pop() !== "cz" ?
                                    "Podlahové infrakúrenie v takmer celej ploche domu, možnosť, elektrokotla, tepelného čerpadla s dotáciou" :
                                    "Podlahové vytápění téměř v celé ploše domu, možnost elektrického kotle či tepelného čerpadla s dotací"
                                }
                            </li>
                        </ul>
                        <h2>
                            {window.location.host.split(".").pop() !== "cz" ? "ZÁRUKA NA DOM 5 ROKOV" : "ZÁRUKA NA DŮM 5 LET"}
                        < /h2>
                    </section>
                <PulseButton text={window.location.host.split(".").pop() !== "cz" ? "Prejsť do katalógu" : "Přejít do katalogu"} redirectUrl="/katalog"/>
            </article>
    </span>
    )
}


export default AboutUs
