import classes from "./Articles.module.scss";
import img1 from "../../assets/strechy-bruno-768x336.jpg";
import {useEffect} from "react";


function NajvhodnejsiaStrecha() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Aká je najvhodnejšia strecha na drevodom</title>
            <img src={img1} alt="Aká je najvhodnejšia strecha na drevodom"/>
            <h1>
                Aká je najvhodnejšia strecha na drevodom
            </h1>
            <p>
                <strong>
                    Strecha má podstatný vplyv na celkový vzhľad domu. Okrem toho však musí spoľahlivo chrániť interiér montovaného domu pred akýmikoľvek nástrahami počasia. Na výber je pritom niekoľko typov striech a ešte viac druhov strešných krytín. Ktorá konštrukcia strechy by teda mala pri
                    drevodome dostať prednosť alebo presnejšie povedané, dá sa vôbec určiť, ktorý typ strechy je pre montovaný dom najvhodnejší?
                </strong>
            </p>
            <h1>
                Strecha očami normy
            </h1>
            <p>
                Podľa aktuálne
                <strong>
                    {"\u00A0"}platnej technickej normy STN 73 1901{"\u00A0"}
                </strong>
                sa strechy rozdeľujú na
                <strong>
                    {"\u00A0"}ploché, šikmé a strmé.{"\u00A0"}
                </strong>
                Pre konkrétne zaradenie strechy je rozhodujúci sklon vonkajšieho povrchu strešnej krytiny. Za plochú strechu je teda v zmysle tejto normy
                považovaná
                <strong>
                    {"\u00A0"}strecha so sklonom krytiny do 10°.{"\u00A0"}
                </strong>
                Sklon 0° síce nie je vo vyššie uvedenej norme nikde uvedený, spomína sa však v nej odporúčaný sklon povlakovej hydroizolačnej vrstvy (krytiny), ktorý by mal predstavovať najmenej 1° a mal by smerovať k strešným odvodňovacím prvkom.
                <strong>
                    {"\u00A0"}Šikmé strechy majú podľa tejto normy uhol sklonu do 45° a strmé strechy uhol nad 45°.
                </strong>
            </p>
            <h1>
                Ktorý typ strechy uprednostniť
            </h1>
            <p>
                Nielen obvodový plášť montovaného domu, ale aj typ strechy a jej tvar do veľkej miery ovplyvnia energetickú náročnosť domu a celkové náklady na vykurovanie. Členitejšia a komplikovanejšia strecha zároveň logicky znamená vyššie náklady, náročnejšiu realizáciu a zároveň vyššie riziko
                nezvládnutých detailov.
            </p>
            <p>
                Aby však nedošlo k nedorozumeniu, treba hneď na úvod povedať, že všetko závisí od precíznosti prevedenia. Jednoduchšie povedané ako plochá, tak aj šikmá alebo ak chcete sedlová strecha, ktorá je kvalitne zrealizovaná, môže dlhé desaťročia spoľahlivo plniť všetky svoje dôležité
                funkcie. Z tohto pohľadu je ich vzájomné skóre takmer vyrovnané a
                <strong>
                    {"\u00A0"}plochá i šikmá strecha je v princípe rovnako dobrou voľbou pre montovaný dom.{"\u00A0"}
                </strong>
                Aké sú špecifické prednosti, poprípade drobné nedostatky jednotlivých striech si povieme v nasledujúcich riadkoch.
            </p>
            <h1>
                Plochá strecha
            </h1>
            <p>
                Povesť plochých striech utrpela predovšetkým v 70-tych a 80-tych rokoch minulého storočia. Ploché strechy boli vtedy navrhované a realizované ako úplne vodorovné. Výsledok? Stojace kaluže vody, poškodená povlaková krytina a zatekanie.
            </p>
            <p>
                Dnes je však situácia úplne iná a kvalitne zrealizovaná plochá strecha ponúka v porovnaní so sedlovou strechou hneď niekoľko zaujímavých výhod. Viaceré z nich sa pritom priamo spájajú s
                <strong>
                    {"\u00A0"}menšou plochou{"\u00A0"}
                </strong>
                , ktorú takýto konštrukčný typ strechy zaberie. V konečnom dôsledku sú
                tak investičné
                náklady na plochú strechu nižšie a nie je potrebné riešiť ani
                <strong>
                    {"\u00A0"}komplikované strešné detaily.{"\u00A0"}
                </strong> Obvodový plášť je zároveň možné lepšie utesniť, čo
                <strong>
                    {"\u00A0"}zabráni neželanému vzniku tepelných mostov.{"\u00A0"}
                </strong>

                Ak si to teda celé zhrnieme, plochá strecha má najnižšie tepelné straty, poskytne plnohodnotný podstrešný priestor a bez povšimnutia nemôže ostať ani údržba, ktorá je vďaka bezproblémovému prístupu úplne jednoduchá. Okrem už spomínanej úspory energií a finančných prostriedkov možno
                plochú strechu navyše využiť na
                <strong>
                    {"\u00A0"}vybudovanie ihriska, priestrannej terasy{"\u00A0"}
                </strong>
                alebo
                <strong>
                    {"\u00A0"}záhrady.{"\u00A0"}
                </strong>
                Takúto modernú architektúru preto oceníte nielen v mestských podmienkach, ale aj na vidieku pri montovanom dome ležiacom na pozemku s malou výmerou.
            </p>
            <h1>
                Šikmá strecha
            </h1>
            <p>
                Šikmé strechy sú už tradične neodmysliteľným prvkom vidieckej architektúry, no na začiatku 90-tych rokov minulého storočia boli príjemným spestrením nudných „kociek“ stavaných v prímestských lokalitách. Šikmé strechy, ako sme to už vyššie naznačili, majú sklon od 10° do 55°. Všetky
                strechy nad 45 stupňov sa potom považujú za strmé.
            </p>
            <p>
                <strong>
                    Oproti plochým strechám sú šikmé strechy jednoznačne menej vystavované nepriaznivým vplyvom a vrtochom počasia.{"\u00A0"}
                </strong>
                To je zároveň jeden z dôvodov, prečo je vonkajší plášť šikmej strechy menej náročný na materiál. Návrh konštrukcie šikmej strechy je síce o niečo
                náročnejší ako pri plochej
                streche, ale presnosť konštrukčných prvkov je potom zárukou kvality a stability dreveného krovu. Medzi nesporné výhody šikmých striech patrí aj ich bezúdržbovosť a vyššia životnosť krytiny. Šikmé strechy sú náročné na realizáciu poistnej hydroizolácie a parozábrany, ktorá by mala byť
                zhotovená celoplošne a nepriedušne. Vzhľadom na využiteľnosť podkrovia musia byť obvodové múry vytiahnuté minimálne
                <strong>
                    {"\u00A0"}do 1,3 m výšky nad podlahou.
                </strong>
            </p>
            <p>
                Medzi šikmé strechy v princípe radíme sedlový, valbový, stanový alebo manzardový typ strechy. Pultová strecha s odvádzaním vody jedným smerom, menšími tepelnými stratami a lepšou využiteľnosťou vnútorného priestoru má bližšie k plochej streche.
            </p>
            <h1>
                Ploché vs. šikmé strechy? Tentoraz bez víťaza
            </h1>
            <p>
                Ak by sme zhodnotili klady i zápory jednotlivých typov striech a chceli by sme použiť športovú terminológiu, povedali by sme, že ide o vzácnu remízu. Rozhodovanie sa pre konkrétny typ strechy už nie je vďaka kvalitným technológiám o poruchovosti, ale skôr o tom, ako daná strecha
                doladí architektúru domu a poprípade ako zapadne do konkrétnej lokality.
            </p>
        </article>
    )
}

export default NajvhodnejsiaStrecha
