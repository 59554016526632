import classes from "./DeleteItem.module.scss";
import firebaseApp from "../../../firebase";
import {Trash} from "tabler-icons-react";
import {connect} from "react-redux";
import {useState} from "react";
import {setRealizujeme} from "../../../redux/actions/realizujeme";
import {setDatabaseCatalog} from "../../../redux/actions/databaseCatalog";
import {Dialog, DialogContent} from "@mui/material";
import {setDatabaseRealizacie} from "../../../redux/actions/databaseRealizacie";
import {doc, getFirestore, deleteDoc} from "firebase/firestore";


function DeleteItem({itemName, page, admin, setDatabaseCatalog, setDatabaseRealizacie, databaseCatalog, databaseRealizacie, realizujeme, setRealizujeme}) {
    const [opened, setOpened] = useState(false)

    async function remove() {
        const firestore = getFirestore(firebaseApp)
        await deleteDoc(doc(firestore, page, itemName))
            .then(() => {
                const items = page === "realizujeme" ? realizujeme : page === "katalog" ? databaseCatalog.catalog : databaseRealizacie.realizacie
                delete items[itemName]

                if (page === "realizujeme") {
                    setRealizujeme({...items})
                } else {
                    page === "katalog" ?
                        setDatabaseCatalog({...databaseCatalog, catalog: items}) :
                        setDatabaseRealizacie({...databaseRealizacie, realizacie: items})
                }
            })
        setOpened(false)
    }

    function handleEsc(e) {
        if (e.key === "Escape") {
            setOpened(false)
        }
    }

    return (
        <div style={{display: admin ? "block" : "none"}}>
            <Dialog
                open={opened}
                fullWidth
                className={classes.dialogWrapper}
                onKeyUp={handleEsc}
                transitionDuration={1000}
            >
                <DialogContent className={classes.dialogWrapper}>
                    <h1>{`Ste si istý, že chcete vymazať položku ${itemName} z ${page}`}</h1>

                    <div className={classes.buttons}>
                        <button onClick={remove}>ÁNO</button>
                        <button onClick={() => setOpened(false)}>NIE</button>
                    </div>

                </DialogContent>
            </Dialog>

            <Trash onClick={() => setOpened(true)} className={classes.removeIcon}/>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        admin: state.admin,
        realizujeme: state.realizujeme,
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie,
    }
}

export default connect(mapStateToProps, {setDatabaseCatalog, setDatabaseRealizacie, setRealizujeme})(DeleteItem)
