import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {connect} from "react-redux";
import {useState} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {toast} from "react-toastify";
import {setDatabaseRealizacie} from "../../../redux/actions/databaseRealizacie";
import {setDatabaseCatalog} from "../../../redux/actions/databaseCatalog";


function EditGalleryItemInfo({databaseCatalog, databaseRealizacie, value, page, handleClose}) {
    const [state, setState] = useState(value().info)

    function galleryItem() {
        if (page === "katalog" && databaseCatalog?.catalog[value().name]) {
            return databaseCatalog?.catalog[value().name]
        } else if (page === "realizacie" && databaseRealizacie?.realizacie[value().name]) {
            return databaseRealizacie?.realizacie[value().name]
        }
    }

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, page, value().name), {...galleryItem(), info: state}, {merge: true})
            .then(() => {
                // setDatabaseTexts({...databaseTexts, texts: {...databaseTexts.texts, [name]: {...state}}})
            })
        setState(value.info)
        handleClose()
    }

    function handleObjectChange(newValue, key) {
        setState({...state, [key]: newValue})
    }

    return (
        <div>
            <h1>{value().name}</h1>
            <ul>
                {value().info ? Object.entries(value().info).map(([key]) =>
                    <li key={key}>
                        <strong>{key}</strong>
                        <input
                            defaultValue={value().info[key]}
                            placeholder={value().info[key]}
                            onChange={(e) => handleObjectChange(e.target.value, key)}
                        />
                    </li>
                ) : null}
            </ul>
            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie
    }
}

export default connect(mapStateToProps, {setDatabaseCatalog, setDatabaseRealizacie})(EditGalleryItemInfo)