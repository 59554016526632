import classes from "./Articles.module.scss";
import img1 from "../../assets/prirodny_svah.jpg";
import img2 from "../../assets/schody_v_zahrade.jpg";
import {useEffect} from "react";


function SvahovityPozemok() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>
                Svahovitý pozemok – poradíme vám čo s ním
            </title>
            <img src={img1} alt="Svahovitý pozemok – poradíme vám čo s ním"/>
            <p>
                <strong>
                    Hovorí sa, že strmý a svahovitý pozemok ponúka viac individuálnych možností ako rovinatý pozemok, ale vyžaduje oveľa vyššie investície. Jedno i druhé tvrdenie je pravdivé. Pravdou však je aj to, že svahovitý pozemok pri výstavbe rodinného domu alebo hoci aj pri úprave záhrady
                    nemusí byť vždy nevýhodou. Presvedčia vás o tom nasledujúce riadky.
                </strong>
            </p>
            <p>
                <strong>
                    Nenechajte sa znechutiť
                </strong>
            </p>
            <p>
                Niet najmenších pochybností, že stavba rodinného domu na svahovitom pozemku vyžaduje vyššiu finančnú investíciu, než akú by ste vynaložili pri rovinatom pozemku.
                <strong>
                    {"\u00A0"}Náklady na výstavbu domu a na úpravu terénu sa budú samozrejme odvíjať od celkového sklonu pozemku.{"\u00A0"}
                </strong>
                Náklady môžu byť teda rôzne veľké a ak máte to šťastie že „platíte“ za krásny výhľad skutočne strmým pozemkom, musíte počítať s tým, že všetko začne už výberom <a href="/clanky/ako-si-vybrat-spravny-projekt">správneho projektu</a>.
            </p>
            <p>
                Výberom <a href="/katalog">katalógového</a> alebo spracovaním individuálneho projektu to však samozrejme celé nekončí a do ceny sa premietnu zvýšené náklady za prípadný geologický prieskum, výkopové práce, vybudovanie zvýšených základových pásov, vystuženie stien proti pôsobeniu
                tlaku zeminy, prevedenie hydroizolácie, dôkladné zateplenie, či za finálnu úpravu terénu. Všetky spomenuté špecifiká výstavby rodinného domu na svahovitom pozemku však nič nemenia na skutočnosti, že
                <strong>
                    {"\u00A0"}aj vo svahu sa dá postaviť rodinný dom za prijateľné peniaze.{"\u00A0"}
                </strong>
                Nedajte sa preto odradiť počiatočnými komplikáciami, výsledok môže stáť skutočne zato.
            </p>
            <p>
                <strong>
                    Keď rodinný dom využíva vo svoj prospech pozemok
                </strong>
            </p>
            <p>
                Zaujímavým riešením výstavby domu v strmšom svahovitom pozemku môže byť
                <strong>
                    {"\u00A0"}individuálny projekt rodinného domu, ktorý pri osadení na pozemok využíva prirodzený profil terénu.{"\u00A0"}
                </strong>
                Takýto rodinný dom je spravidla rozdelený do dvoch alebo neraz až do troch funkčných celkov.
            </p>
            <p>
                <strong>
                    Najnižšie podlažie domu býva čiastočne vsadené priamo do svahu.
                </strong>
                Nachádza sa tu vstup do domu, garáž, technické miestnosti, WC a jedna izba využívaná ako pracovňa. Z prízemia domu vedie pohodlné a dostatočne priestranné schodisko, ktorým sa dostanete na prvé podlažie.
            </p>
            <p>
                <strong>
                    Na prvom podlaží sa nachádza veľkoryso riešená obývacia miestnosť so zabudovaným krbom a s veľkými francúzskymi presklenými dverami umožňujúcimi východ na terasu.{"\u00A0"}
                </strong>
                Podľa individuálnych požiadaviek môže byť obývacia izba prepojená s jedálňou a kuchyňou, alebo sa môže budúci majiteľ
                domu rozhodnúť pre väčšie súkromie a uprednostní stavebne oddelené riešenie týchto miestností. Na tomto podlaží je situovaná tiež spálňa rodičov s vlastným šatníkom i kúpeľňou a dve detské izby, ktoré poskytnú primerané súkromie deťom.
            </p>
            <p>
                Tip: Chcete sa inšpirovať? Prezrite si realizáciu rodinného domu v Gbeľanoch.
            </p>
            <p>
                <strong>
                    Svahovitý pozemok ponúka viaceré možnosti úprav
                </strong>
            </p>
            <p>
                Po výstavbe rodinného domu sa bude treba vysporiadať s úpravou ostávajúceho terénu. Buďte však bez obáv. Aj ten najnevzhľadnejší svah sa dá vyriešiť celkom elegantne. Najskôr si urobte jasno v tom, či chcete svah rozdeliť na terasy, alebo ho budete riešiť vcelku.
            </p>
            <p>
                <strong>
                    Pri strmom svahu bude pravdepodobne nutné zhotovenie oporných múrov.{"\u00A0"}
                </strong>
                Toto riešenie je náročnejšie, pretože si vyžaduje odborné práce a služby statika. Oporné múry v strmom svahu musia totiž odolávať tlaku zeminy najmä po dlhotrvajúcich výdatných dažďoch. Statik prepočíta tlak zeminy
                a určí do akej hĺbky bude potrebná betonáž základov oporného múru, navrhne jeho spevnenie oceľovými prútmi a urobí návrh drenážneho systému na odvod vody.
                Pri menej strmých svahoch sa zaobídete bez statika a komplikovanejšej výstavby oporného múru a zo sklonu terénu môžete ubrať pomocou vegetačných tvárnic alebo prostredníctvom suchého múrika.
            </p>
            <p>
                <strong>
                    Skladačka z betónových tvárnic
                </strong>
            </p>
            <p>
                Betónové vegetačné tvárnice sa tešia obľube pre rozmanitosť tvarov, veľkostí i farieb. Betónové tvárnice tak môžete efektne zosúladiť s fasádou rodinného domu, so zámkovou dlažbou použitou v chodníku alebo hoci aj s plotom. Za pozornosť stojí tiež rýchla realizácia. Tvárnic netreba
                veľa, stačí osadiť niekoľko radov a dovtedy problémový svah vyzerá ako z katalógu.
            </p>
            <img src={img2} alt="Skladačka z betónových tvárnic"/>
            <p>
                Pri osádzaní betónových tvárnic máte v princípe dve možnosti.
                <strong>
                    {"\u00A0"}Môžete ich ukladať kolmo na seba alebo so sklonom na svah.{"\u00A0"}
                </strong>
                Najmä pri druhej alternatíve vznikne väčší priestor na vysadenie skalničiek, trvaliek alebo byliniek, ktoré v kombinácii s tvárnicami zmenia vzhľad pozemku na nepoznanie.
            </p>
            <p>
                <strong>
                    Suchý múrik je klasika
                </strong>
            </p>
            <p>
                Suchý múrik zhotovený z čiastočne opracovaného lomového kameňa alebo z kamenných dosiek nikdy nič nepokazí. Jeho prirodzená vizáž mu dáva neobyčajne atraktívny vzhľad, takže po jeho realizácii si na pôvodný svah už ani nespomeniete. Pri realizácii klasického suchého múrika sa
                nepoužíva malta, ale zmes zeminy, štrku a piesku, ktorou sa vypĺňajú medzery medzi kameňmi. Do medzier sa potom vysádzajú rôzne trvalky a skalničky, ktoré múrik v konečnom dôsledku nielen skrášlia, ale aj spevnia.
                <strong>
                    {"\u00A0"}Výška kamenného múrika môže byť rôzna, odporúča sa však ísť do 60 až 80 centimetrov.{"\u00A0"}
                </strong>
                Prvý rad kameňov je vhodné zapustiť do terénu, pričom pre lepšiu stabilitu sa kamene ukladajú tak, aby sa suchý múrik opieral smerom do svahu.
            </p>
        </article>
    )
}

export default SvahovityPozemok
