import classes from "./ErrorPage.module.scss";
import PulseButton from "../../../components/pulseButton/PulseButton";


function Gallery404({page}) {
    return (
        <div className={classes.page}>
            <p>Položka sa nenašla</p>
            <PulseButton text={window.location.host.split(".").pop() !== "cz" ? `Prejsť do ${page}` : `Přejít do ${page}`} redirectUrl={`/${page}`}/>
        </div>
    )
}

export default Gallery404