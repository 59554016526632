import Home from "./screens/Home";
import Login from "../components/admin/login/Login";
import Levels from "./screens/levels/Levels";
import AboutUs from "./screens/aboutUs/AboutUs";
import Catalog from "./screens/Catalog";
import Main404 from "./screens/errorPages/404Main";
import Articles from "./screens/articles/Articles";
import PriceOffer from "./screens/priceOffer/PriceOffer";
import FreeProject from "./screens/freeProject/FreeProject";
import Realizujeme from "./screens/Realizujeme";
import Realizations from "./screens/Realisations";
import AdminCMSWrapper from "../components/admin/adminCMS/AdminCMSWrapper";
import AdminGalleryWrapper from "../components/admin/adminGalleryWrapper/AdminGalleryWrapper";
import {BrowserRouter, Switch, Route} from "react-router-dom";


function RouterNavigation() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/o-nas" component={AboutUs}/>
                <Route path="/clanky" component={Articles}/>
                <Route path="/katalog" component={Catalog}/>
                <Route path="/realizacie" component={Realizations}/>
                <Route path="/realizujeme" component={Realizujeme}/>
                <Route exact path="/stupne-vystavby" component={Levels}/>

                <Route exact path="/admin" component={() => <Login/>}/>
                <Route path="/admin/katalog" component={() => <AdminGalleryWrapper page={"katalog"}/>}/>
                <Route path="/admin/realizacie" component={() => <AdminGalleryWrapper page={"realizacie"}/>}/>
                <Route path="/admin/klienti" component={() => <AdminCMSWrapper page={"klienti"}/>}/>

                <Route exact path="/cenova-ponuka" component={PriceOffer}/>
                <Route exact path="/projekt-zadarmo" component={FreeProject}/>

                <Route component={() => <Main404/>}/>
            </Switch>
        </BrowserRouter>
    )
}

export default RouterNavigation

