import classes from "./UploadTab.module.scss";
import * as xlsx from "xlsx";
import Pdf from "@mikecousins/react-pdf";
import Dropzone from "react-dropzone";
import dragAndDrop from "../../../../assets/dragAndDrop.png";
import firebaseApp from "../../../../firebase";
import fileToArrayBuffer from "file-to-array-buffer";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {collection, doc, getDocs, getFirestore, setDoc} from "firebase/firestore";
import {getStorage, ref, uploadBytes, listAll, getDownloadURL, updateMetadata} from "firebase/storage";


function UploadTab({page}) {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [excel, setExcel] = useState({json: [], name: ""})
    const [pdf, setPdf] = useState({buffer: undefined, name: ""})
    const [images, setImages] = useState([])
    const [mainImage, setMainImage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [existingNames, setExistingNames] = useState([])
    const [existingFiles, setExistingFiles] = useState([])
    const [isFirst, setIsFirst] = useState(true)


    const firestore = getFirestore(firebaseApp)
    const storage = getStorage()

    useEffect(() => {
        let files = []
        listAll(ref(storage, "/")).then((res) => {
            res.items.forEach((itemRef) => {
                files.push(itemRef.fullPath)
            });
            setExistingFiles(files)
        })
    }, [storage])

    function handleExcel(file) {
        fileToArrayBuffer(file).then((data) => {
            console.log(data)
            const workbook = xlsx.read(data, {type: "array"})
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)
            setExcel({json: json, name: file.name})
        })
    }


    function onDrop(files) {
        let image = []

        for (const file of files) {
            if (file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
                handleExcel(file)
            } else if (file.type === "application/pdf") {
                setPdf({buffer: file, name: file.name})
            } else if (file.type === "application/jpg" || file.type === "application/jpeg" || file.type === "application/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                image.push({buffer: file, name: file.name})
            }
        }
        setImages([...images, ...image])
    }

    function pdfDocument() {
        if (pdf.buffer) {
            return (
                <div>
                    <title className={classes.title}>{pdf.name}</title>
                    <Pdf file={URL.createObjectURL(pdf.buffer)} className={classes.pdf}/>
                </div>
            )
        }
    }

    function excelDocument() {
        if (excel.json.length > 0) {
            return (
                <ul className={classes.excelList}>
                    {Object.entries(excel?.json[0]).map(([key, val]) =>
                        <li key={key}>{key}: {val}</li>
                    )}
                </ul>
            )
        }
    }

    async function submitWithToast() {
        if (name) {
            if (!existingNames.includes(name)) {
                await toast.promise(submit, {
                    pending: "Prebieha upload do databázy",
                    success: "Upload úspešný",
                    error: "Upload neúspešný"
                });
            } else {
                toast.info(`Dané meno sa už v ${page.toUpperCase()} nachádza, prosím vyberte iné`)
            }
        } else {
            toast.info("Prosim zadajte meno, je to povinný údaj")
        }
    }

    async function submit() {
        setLoading(true)

        const imageRefs = []
        for (const image of images) {
            if (!existingFiles.includes(image.name)) {
                const storageImagesRef = ref(storage, image.name)

                await uploadBytes(storageImagesRef, image.buffer).then(async e => {
                    const file = await getDownloadURL(e.ref)
                    await updateMetadata(storageImagesRef, {cacheControl: "public,max-age=9999999999999999999"})
                    imageRefs.push(file)
                })
            } else {
                const imageRef = ref(storage, image.name);
                const test2 = imageRef.fullPath ? await getDownloadURL(imageRef) : ""
                imageRefs.push(test2)

                toast.info(
                    "Vybrali ste obrázok/obrázky s rovnakým menom ako už existuje/existujú v databáze. Aby sa predišlo pokazeniu niektorej existujúcej položky v katalógu alebo v realizáciách, pre tento obrázok/obrázky boli použité aktuálne obrázky v databáze. Prosím skontrolujte novonahratú položku - v prípade, že niektorý obrázok nie je správny, nahrajte ho znova, ale najprv ho PREMENUJTE.",
                    {autoClose: 20000}
                )
            }
        }

        let pdfRefs = []
        if (pdf.buffer && !existingFiles.includes(pdf.name)) {
            const storagePdfRef = ref(storage, pdf.name)
            await uploadBytes(storagePdfRef, pdf.buffer).then(async e => {
                const file = await getDownloadURL(e.ref)
                await updateMetadata(storagePdfRef, {cacheControl: "public,max-age=9999999999999999999"})
                pdfRefs.push(file)
            })
        } else {
            const pdfRef = ref(storage, pdf.name);
            const test3 = pdfRef.fullPath ? await getDownloadURL(pdfRef) : ""
            pdfRefs.push(test3)
            if (pdf.buffer) toast.info("Vybrali ste pdf s rovnakým menom ako už existuje v databáze. Aby sa predišlo pokazeniu niektorej existujúcej položky v katalógu alebo v realizáciách, pre toto pdf bolo použité aktuálne pdf v databáze. Prosím skontrolujte novonahratú položku - v prípade, že pdf nie je správne, nahrajte ho znova, ale najprv ho PREMENUJTE.", {autoClose: 20000})
        }

        await setDoc(doc(firestore, page === "katalog" ? page : isFirst ? "realizujeme" : "realizacie", name),
            {
                name,
                description,
                info: excel.json[0] ? excel.json[0] : "",
                type: "image",
                pdf: pdf.name ? pdfRefs[0] : "",
                images: images ? imageRefs : "",
                mainImage: images[mainImage] ? imageRefs[mainImage] : "",
                timestamp: new Date().getTime()
            }, {merge: true})

        setName("")
        setDescription("")
        setExcel({json: [], name: ""})
        setPdf({buffer: undefined, name: ""})
        setImages([])
        setMainImage(0)
        setLoading(false)
    }

    function checkbox() {
        return page !== "katalog" ? (
            <div className={classes.checkbox}>
                <div>
                    <input type="checkbox" checked={isFirst} onChange={() => setIsFirst(true)}/>
                    <p>Realizujeme</p>
                </div>
                <div>
                    <input type="checkbox" checked={!isFirst} onChange={() => setIsFirst(false)}/>
                    <p>Dokončené realizácie</p>
                </div>
            </div>
        ) : null
    }


    useEffect(() => {
        async function loadDatabase() {
            let names = []
            const querySnapshot = await getDocs(collection(firestore, page))
            for (const name of querySnapshot.docs) {
                names.push(name.id)
            }
            setExistingNames(names)
        }

        loadDatabase().catch()
    }, [firestore, page])

    return (
        <div className={classes.uploadTab}>
            {checkbox()}
            <p>{`Zvoľte meno a popis v ${page.toUpperCase()} (*povinné)`}</p>
            {existingNames.includes(name) ? <p>{`Dané meno sa už v ${page.toUpperCase()} nachádza, prosím vyberte iné`}</p> : null}
            <input autoFocus value={name} placeholder="meno" onChange={(e) => setName(e.target.value)} aria-invalid={existingNames.includes(name)}/>
            <textarea value={description} placeholder="popis" onChange={(e) => setDescription(e.target.value)}/>
            {page === "katalog" ?
                <p>
                    Použi funkciu Drag and Drop a potiahni excelovú databázu do tohto poľa.
                    Systém automaticky doplní všetky texty, PDF pôdorys, ako aj obrázky.
                    Pozor, excel musí byť v správnom tvare!
                </p> : <p>
                    Potiahni obrázky do tohto poľa.
                </p>
            }

            <Dropzone
                multiple
                onDropAccepted={onDrop}
                onDropRejected={(e) => console.log(e)}
                accept={
                    page === "katalog" ?
                        "text/csv, application/vnd.ms-excel, image/png, image/jpg, image/jpeg, application/png, application/jpg, application/jpeg, application/pdf" :
                        "image/png, image/jpg, image/jpeg, application/png, application/jpg, application/jpeg"
                }
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <img src={dragAndDrop} alt="dragAndDrop" className={classes.dragAndDrop}/>
                    </div>
                )}
            </Dropzone>

            {loading ? "Uploading..." :
                <div className={classes.upload}>
                    <p>Položky na uploadnutie</p>
                    <ul className={classes.confirm}>
                        <li style={{display: excel.json.length > 0 ? "flex" : "none"}}>{excel.name} (xls)</li>
                        <li style={{display: pdf.buffer ? "flex" : "none"}}>{pdf.name} (PDF)</li>
                        <li style={{display: images.length > 0 ? "flex" : "none"}}>Fotogaléria ({images.length} ks)</li>
                    </ul>
                    <button onClick={submitWithToast}>Uploadnúť</button>

                    <div className={classes.upperPart}>
                        {pdfDocument()}
                        {excelDocument()}
                    </div>

                    <ul className={classes.images}>
                        {images.map((image, index) => {
                            return (
                                <li key={index}>
                                    <div>
                                        <input type="checkbox" checked={mainImage === index} onChange={() => setMainImage(index)}/>
                                        <p>Nastaviť ako ilustračný</p>
                                    </div>
                                    <img src={image.buffer ? URL.createObjectURL(image.buffer) : null} alt={image.name}/>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </div>
    )
}

export default UploadTab
