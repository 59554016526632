import classes from "./FreeProject.module.scss";
import img from "../../../assets/free-project-img.jpg"
import Header from "../../../components/admin/header/Header";
import Navbar from "../../../components/navbar/Navbar";


function FreeProject() {
    return (
        <span>
            <Header/>
            <Navbar/>

            <article className={classes.wrapper}>
                <img src={img} alt="projekt zadarmo" className={classes.img}/>

                <h6>Andrej Holubčík</h6>

                <p>obchodný riaditeľ</p>
                <p>+421 917 427 709</p>
                <p>info@drevostavbybruno.sk</p>
            </article>
        </span>
    )
}

export default FreeProject
