import * as xlsx from "xlsx";
import classes from "./AdminDragAndDrop.module.scss";
import Dropzone from "react-dropzone";
import dragAndDrop from "../../../../assets/dragAndDrop.png";
import firebaseApp from "../../../../firebase";
import fileToArrayBuffer from "file-to-array-buffer";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {useState, useEffect} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {getDownloadURL, getStorage, listAll, ref, updateMetadata, uploadBytes} from "firebase/storage";


function AdminDragAndDrop({databaseCatalog, databaseRealizacie, urlBase, item, admin}) {
    const [excel, setExcel] = useState({json: [], name: ""})
    const [pdf, setPdf] = useState({buffer: undefined, name: ""})
    const [images, setImages] = useState([])
    const [existingFiles, setExistingFiles] = useState([])
    const [loading, setLoading] = useState(false)


    const firestore = getFirestore(firebaseApp)
    const storage = getStorage()

    useEffect(() => {
        let files = []
        listAll(ref(storage, "/")).then((res) => {
            res.items.forEach((itemRef) => {
                files.push(itemRef.fullPath)
            });
            setExistingFiles(files)
        })
    }, [storage])

    function galleryItem() {
        if (urlBase.replace("/", "") === "katalog" && databaseCatalog?.catalog[item]) {
            return databaseCatalog?.catalog[item]
        } else if (urlBase.replace("/", "") === "realizacie" && databaseRealizacie?.realizacie[item]) {
            return databaseRealizacie?.realizacie[item]
        }
    }

    async function submitPDF() {
        let pdfRefs = []
        if (pdf.name) {
            if (pdf.buffer && !existingFiles.includes(pdf.name)) {
                const storagePdfRef = ref(storage, pdf.name)
                await uploadBytes(storagePdfRef, pdf.buffer).then(async e => {
                    const test = await getDownloadURL(e.ref)
                    await updateMetadata(storagePdfRef, {cacheControl: "public,max-age=9999999999999999999"})
                    pdfRefs.push(test)
                })
            } else {
                const pdfRef = ref(storage, pdf.name);
                const test3 = pdfRef.fullPath ? await getDownloadURL(pdfRef) : ""
                pdfRefs.push(test3)
                if (pdf.buffer) toast.info("Vybrali ste pdf s rovnakým menom ako už existuje v databáze. Aby sa predišlo pokazeniu niektorej existujúcej položky v katalógu alebo v realizáciách, pre toto pdf bolo použité aktuálne pdf v databáze. Prosím skontrolujte novonahratú položku - v prípade, že pdf nie je správne, nahrajte ho znova, ale najprv ho PREMENUJTE.")
            }
            await setDoc(doc(firestore, urlBase.replace("/", ""), item), {...galleryItem(), pdf: pdf.name ? pdfRefs[0] : ""}, {merge: true})
            setPdf({buffer: undefined, name: ""})
        }
    }

    async function submitIMAGES() {
        const imageRefs = []

        for (const image of images) {
            const storageImagesRef = ref(storage, image.name)
            await uploadBytes(storageImagesRef, image.buffer).then(async e => {
                const test = await getDownloadURL(e.ref)
                await updateMetadata(storageImagesRef, {cacheControl: "public,max-age=9999999999999999999"})
                imageRefs.push(test)
            })
        }
        await setDoc(doc(firestore, urlBase.replace("/", ""), item), {...galleryItem(), images: galleryItem()?.images.concat(imageRefs)}, {merge: true})
        setImages([])
    }

    function handleExcel(file) {
        fileToArrayBuffer(file).then((data) => {
            const workbook = xlsx.read(data, {type: "array"})
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)
            setExcel({json: json, name: file.name})
        })
    }

    async function submitEXCEL() {
        if (excel.json[0]) {
            const firestore = getFirestore(firebaseApp)
            await setDoc(doc(firestore, urlBase.replace("/", ""), galleryItem().name), {...galleryItem(), info: excel.json[0]}, {merge: true})
            setExcel({json: [], name: ""})
        }
    }

    async function submit() {
        setLoading(true)
        if (excel.json.length > 0) await submitEXCEL()
        if (pdf.buffer) await submitPDF()
        if (images.length > 0) await submitIMAGES()
        setLoading(false)
    }

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    function onDrop(files) {
        let image = []
        for (const file of files) {
            if (file.type === "text/csv") {
                handleExcel(file)
            } else if (file.type === "application/pdf") {
                setPdf({buffer: file, name: file.name})
            } else if (file.type === "application/jpg" || file.type === "application/jpeg" || file.type === "application/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                image.push({buffer: file, name: file.name})
            }
        }
        setImages([...images, ...image])
    }

    return admin ? (
        <div className={classes.admin}>
            <h2>Upraviť aktuálnu položku</h2>
            {urlBase === "/katalog" ?
                <span>
                    <p><strong>Aktuálny PDF súbor</strong> bude <strong>nahradený</strong> novým PDF</p>
                    <p><strong>Excel</strong> bude <strong>nahradený</strong> novým Excel súborom</p>
                    <p><strong>Obrázky</strong> budú <strong>pridané</strong> k existujúcim obrázkom</p>
                </span> :
                <span>
                    <p><strong>Obrázky</strong> budú <strong>pridané</strong> k existujúcim obrázkom</p>
                </span>
            }
            <Dropzone
                multiple
                onDropAccepted={onDrop}
                accept={
                    urlBase === "/katalog" ?
                        "text/csv, image/png, image/jpg, image/jpeg, application/png, application/jpg, application/jpeg, application/pdf" :
                        "image/png, image/jpg, image/jpeg, application/png, application/jpg, application/jpeg"
                }
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <img src={dragAndDrop} alt="dragAndDrop" className={classes.dragAndDrop}/>
                    </div>
                )}
            </Dropzone>

            {loading ? "Uploading..." :
                <div className={classes.upload}>
                    <p>Položky na uploadnutie</p>
                    <ul className={classes.confirm}>
                        <li style={{display: excel.json.length > 0 ? "flex" : "none"}}>{excel.name} (xls)</li>
                        <li style={{display: pdf.buffer ? "flex" : "none"}}>{pdf.name} (PDF)</li>
                        <li style={{display: images.length > 0 ? "flex" : "none"}}>Fotogaléria ({images.length} ks)</li>
                    </ul>
                    <button onClick={submitWithToast}>Uploadnúť</button>
                </div>
            }
        </div>
    ) : null
}

const mapStateToProps = state => {
    return {
        admin: state.admin,
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie
    }
}

export default connect(mapStateToProps)(AdminDragAndDrop)