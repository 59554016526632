function Info({itemInfo}) {
    return window.location.host.split(".").pop() !== "cz" ? (
        <ul>
            {typeof itemInfo["hrubá stavba"] !== "undefined" ? <li><strong>hrubá stavba</strong>: {itemInfo["hrubá stavba"]}</li> : null}
            {typeof itemInfo["holodom"] !== "undefined" ? <li><strong>holodom</strong>: {itemInfo["holodom"]}</li> : null}
            {typeof itemInfo["dom na kľúč"] !== "undefined" ? <li><strong>dom na kľúč</strong>: {itemInfo["dom na kľúč"]}</li> : null}
            {typeof itemInfo["základová doska"] !== "undefined" ? <li><strong>základová doska</strong>: {itemInfo["základová doska"]}</li> : null}
            {typeof itemInfo["zastavaná plocha"] !== "undefined" ? <li><strong>zastavaná plocha</strong>: {itemInfo["zastavaná plocha"]}</li> : null}
            {typeof itemInfo["úžitková plocha"] !== "undefined" ? <li><strong>úžitková plocha</strong>: {itemInfo["úžitková plocha"]}</li> : null}
            {typeof itemInfo["obytná plocha"] !== "undefined" ? <li><strong>obytná plocha</strong>: {itemInfo["obytná plocha"]}</li> : null}
            {typeof itemInfo["rozmer domu"] !== "undefined" ? <li><strong>rozmer domu</strong>: {itemInfo["rozmer domu"]}</li> : null}
            {typeof itemInfo["počet izieb"] !== "undefined" ? <li><strong>počet izieb</strong>: {itemInfo["počet izieb"]}</li> : null}
        </ul>
    ) : <ul>
        {typeof itemInfo["hrubá stavba.CZ"] !== "undefined" ? <li><strong>hrubá konstrukce</strong>: {itemInfo["hrubá stavba.CZ"]}</li> : null}
        {typeof itemInfo["holodom.CZ"] !== "undefined" ? <li><strong>holodům</strong>: {itemInfo["holodom.CZ"]}</li> : null}
        {typeof itemInfo["dom na kľúč.CZ"] !== "undefined" ? <li><strong>dům na klíč </strong>: {itemInfo["dom na kľúč.CZ"]}</li> : null}
        {typeof itemInfo["základová doska.CZ"] !== "undefined" ? <li><strong>základová deska</strong>: {itemInfo["základová doska.CZ"]}</li> : null}
        {typeof itemInfo["zastavaná plocha"] !== "undefined" ? <li><strong>zastavěná plocha</strong>: {itemInfo["zastavaná plocha"]}</li> : null}
        {typeof itemInfo["úžitková plocha"] !== "undefined" ? <li><strong>využitelná plocha</strong>: {itemInfo["úžitková plocha"]}</li> : null}
        {typeof itemInfo["obytná plocha"] !== "undefined" ? <li><strong>obývací prostor</strong>: {itemInfo["obytná plocha"]}</li> : null}
        {typeof itemInfo["rozmer domu"] !== "undefined" ? <li><strong>rozměry domu</strong>: {itemInfo["rozmer domu"]}</li> : null}
        {typeof itemInfo["počet izieb"] !== "undefined" ? <li><strong>počet pokojů</strong>: {itemInfo["počet izieb"]}</li> : null}
    </ul>
}

export default Info