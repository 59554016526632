import Header from "../../components/admin/header/Header";
import Navbar from "../../components/navbar/Navbar";
import GalleryPage from "../../components/galleryRealizujeme/GalleryPage";
import PriceOfferCTA from "../../components/priceOfferCTA/PriceOfferCTA";


function Realizujeme() {
    return (
        <span>
            <Header/>
            <Navbar/>
            <PriceOfferCTA/>
            <GalleryPage/>
        </span>
    )
}


export default Realizujeme
