import classes from "./Login.module.scss";
import logo from "../../../assets/logoFull.png";
import firebaseApp from "../../../firebase";
import {connect} from "react-redux";
import {useState} from "react";
import {setAdmin} from "../../../redux/actions/admin";
import {useHistory} from "react-router-dom";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";


function Login({setAdmin}) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    let history = useHistory()

    async function submit() {
        if (email && password) {
            try {
                await signInWithEmailAndPassword(getAuth(firebaseApp), email, password)
                    .then(() => {
                            setAdmin(true)
                            history.push("/")
                        }
                    )
            } catch (e) {
            }
        }
    }

    async function handleEnter(e) {
        if (e.key === "Enter") {
            await submit()
        }
    }

    return (
        <div className={classes.login} onKeyDown={handleEnter}>
            <img src={logo} alt="logo"/>

            <div className={classes.form}>
                <input placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} autoFocus/>
                <input type="password" placeholder="heslo" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <button type="submit" onClick={submit}>Prihlásiť</button>
            </div>

        </div>
    )
}

export default connect(null, {setAdmin})(Login)