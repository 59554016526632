import classes from "./ErrorPage.module.scss";
import PulseButton from "../../../components/pulseButton/PulseButton";


function Articles404() {
    return (
        <div className={classes.page}>
            <p>Tento článok neexistuje</p>
            <PulseButton text="Prezrieť všetky články" redirectUrl="/clanky"/>
        </div>
    )
}

export default Articles404