import classes from "./Articles.module.scss";
import img1 from "../../assets/asd-1024x640.jpg";
import img2 from "../../assets/dom.jpg";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

function SedemOtazok() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    let history = useHistory()

    async function scrollToContacts() {
        await history.push("/")
        setTimeout(() => {
            const element = document.getElementById("Kontakt")
            const elementPosition = element.getBoundingClientRect().top
            window.scrollTo({top: elementPosition, behavior: "smooth"})
        }, 1000)
    }

    return (
        <article className={classes.article}>
            <title>7 najčastejších otázok a odpovedí týkajúcich sa výstavby drevodomu</title>
            <img src={img1} alt="7 najčastejších otázok a odpovedí týkajúcich sa výstavby drevodomu"/>
            <p>
                <strong>
                    Výstavbu rodinného domu sprevádza množstvo otázok, na ktoré je dobré poznať pravdivé odpovede. Inak tomu nie je ani v prípade montovaných domov. No a keďže na internete nenájdete vždy objektívne informácie, rozhodli sme sa vám v nasledujúcich riadkoch priniesť niekoľko odpovedí
                    na najčastejšie vyhľadávané otázky.
                </strong>
            </p>
            <h1>
                1. Aká je životnosť montovaného domu?
            </h1>
            <p>
                V prípade moderných montovaných domov netreba mať o ich životnosť žiadne obavy. Samozrejme za predpokladu, že
                <strong>
                    {"\u00A0"}počas výstavby boli použité kvalitné stavebné materiály a stavebná firma si dala skutočne záležať na všetkých technologických postupoch i prácach.{"\u00A0"}
                </strong>
                Od týchto podmienok sa napokon odvíja nielen životnosť montovaného, ale aj murovaného domu.
            </p>
            <p>
                V praxi to znamená, že lajdácky postavený murovaný dom môže mať nižšiu životnosť ako, montovaný. No a samozrejme to platí aj naopak. Takže bez obáv, montovaný dom môže v pohode slúžiť vám i vašim deťom.
            </p>
            <h1>
                2. A čo cena montovaného domu v porovnaní s murovaným?
            </h1>
            <p>
                Budete možno prekvapení, ale cena obidvoch typov rodinných domov nie je nejako dramaticky odlišná. Misky váh sa síce nakláňajú v prospech montovaných domov a
                <strong>
                    {"\u00A0"}ich výstavba môže priniesť úsporu finančných prostriedkov o približne 5 až 10 %.{"\u00A0"}
                </strong>
                Všetko však záleží aj od vnútorného vybavenia, takže v konečnom dôsledku môže byť cena montovaného domu napokon rovnaká alebo aj mierne vyššia.
            </p>
            <h1>
                3. Je v cene domu základová doska?
            </h1>
            <p>
                S našimi zákazníkmi jednáme vždy otvorene a korektne. Naše katalógové projekty sú nacenené
                <strong>
                    {"\u00A0"}bez základovej dosky, na čo ste vždy upozornení v rámci popisu montovaného domu.{"\u00A0"}
                </strong>
                V prípade, záujmu zákazníka o zhotovenie základovej dosky však samozrejme urobíme cenovú ponuku. Ponuka bude prehľadná a bude obsahovať všetky realizačné kroky potrebné na prípravu základovej dosky.
            </p>
            <h1>
                4. Základovú dosku si zhotovím svojpomocne. Za ako dlho sa budem môcť sťahovať?
            </h1>
            <p>
                V prípade predpripravenej základovej dosky udalosti naberú rýchly spád. Výstavba montovaného domu na rozdiel od murovaného zahŕňa iba minimum mokrých stavebných procesov, takže žiadne predpísané technologické prestávky potrebné na vyzretie nezdržiavajú priebeh prác, ktoré tak v
                podaní skúsenej partie profesionálov napredujú skutočne svižným tempom.
            </p>
            <p>
                Od zahájenia výstavby montovaného domu po odovzdanie kľúčov
                <strong>
                    {"\u00A0"}ubehnú 3 mesiace a vy sa môžete začať sťahovať do vlastného.{"\u00A0"}
                </strong>
                Perfektná alternatíva najmä vtedy, keď potrebujete rýchlo vyriešiť otázku bývania alebo keď končíte podnájom.
            </p>
            <h1>
                5. Nechceme katalógový projekt, dá sa s vami stavať napriek tomu?
            </h1>
            <img src={img2} alt="Katalógový projekt"/>
            <p>
                V prípade, že vám z nejakého dôvodu nevyhovuje náš <a href="/katalog">katalógový projekt</a>, neváhajte sa na nás obrátiť. Ponúkneme vám služby nášho projektanta, ktorý zapracuje všetky vaše požiadavky a predstavy o vlastnom bývaní do individuálneho projektu. My vám potom samozrejme
                takýto na mieru ušitý individuálny projekt po <a href="/clanky/ako-postupovat-pri-vybavovani-stavebneho povolenia">vydaní stavebného povolenia</a> postavíme na vašom pozemku.
            </p>
            <h1>
                6. Bude v montovanom dome nevyhnutná rekuperácia tepla? A ak sa pre ňu rozhodnem budem môcť vôbec otvárať okná?
            </h1>
            <p>
                Moderné technológie už akosi patria k montovaným domom, ale napriek tomu je naša odpoveď na prvú otázku, nie.
                <strong>
                    {"\u00A0"}Rekuperácia tepla nie je podmienkou{"\u00A0"}
                </strong>
                a táto technológia nemusí byť nevyhnutne inštalovaná vo vašom dome. Na druhej strane však platí, že
                <strong>
                    {"\u00A0"}ak sa pre <a href="/clanky/rekuperacia-vzduchu">rekuperáciu tepla</a> rozhodnete, určite neurobíte zlý krok.
                </strong>
            </p>
            <p>
                Technológia dokáže zabezpečiť stály prísun čerstvého vzduchu do interiéru domu bez pachov či alergénov a bez toho, aby bola akokoľvek ovplyvnená vnútorná teplota. A ako je to možné? Pri rekuperácii tepla nedochádza k ochladenie priestoru, pretože vzduch, ktorý je odsávaný z
                miestnosti, vo výmenníku odovzdá svoje teplo čerstvému, privádzanému vzduchu z exteriéru.
            </p>
            <p>
                Nuž a čo sa týka druhej otázky, odpoveď jej jednoduchá.
                <strong>
                    {"\u00A0"}Okná na montovanom dome samozrejme otvárať môžete.{"\u00A0"}
                </strong>
                Jedno je však isté. Nebudete ich otvárať preto, aby ste vyvetrali.
            </p>
            <h1>
                7. Viete nám pomôcť s financovaním stavby montovaného domu cez hypotekárny úver?
            </h1>
            <p>
                Snov o vlastnom bývaní sa nemusíte vzdať ani pri nedostatku vlastných finančných prostriedkov. Podobne ako pri riešení individuálneho projektu, aj v tomto prípade vám dokážeme byť nápomocní a tentoraz vám
                <strong>
                    {"\u00A0"}ponúkneme služby hypotekárneho špecialistu.{"\u00A0"}
                </strong>
                Náš odborník na financie objektívne posúdi vašu finančnú situáciu a navrhne vám najvýhodnejší bankový produkt, ktorý vám umožní v budúcnosti splácať svoje záväzky bez nejakých problémov.
            </p>
            <h1>
                Pár slov na záver
            </h1>
            <p>
                Výstavba montovaných domov je zaujímavá a sme si vedomí, že môže prinášať oveľa viac otázok. Samozrejme v článku sme nemohli vyčerpať všetky témy a preto ak ste nedostali odpoveď ktorú ste možno hľadali, neváhajte nás
                <span className={classes.contacts} onClick={() => scrollToContacts()}> kontaktovať</span>
                . Potešíme sa však aj osobnej návšteve, počas ktorej vám môžeme dať konkrétne odpovede na všetky vaše nejasnosti.
            </p>
        </article>
    )
}

export default SedemOtazok
