import classes from "./AdminNavbar.module.scss";
import home from "../../../../assets/navbar domcek b.png";
import home2 from "../../../../assets/navbar domcek c.png";
import plus from "../../../../assets/navbarPlus no colour.png";
import plus2 from "../../../../assets/plus.png";
import currency from "../../../../assets/navbar ruka b.png";
import currency2 from "../../../../assets/navbar ruka c.png";
import {Link} from "react-router-dom";
import {useState} from "react";


function AdminNavbar({page}) {
    const [tab, setTab] = useState(0)

    return (
        <nav className={classes.navbar}>
            <ul>
                <li onClick={() => setTab(0)}>
                    <Link to={`/admin/${page}/pridat`}>
                        <p>Pridať dom</p>
                        <img src={tab === 0 ? plus2 : plus} alt="pridať dom" className={classes.img}/>
                    </Link>
                </li>
                <li onClick={() => setTab(1)}>
                    <Link to={`/admin/${page}/upravit`}>
                        <p>Upravit položku</p>
                        <img src={tab === 1 ? home : home2} alt="upraviť položku" className={classes.img}/>
                    </Link>
                </li>
                <li onClick={() => setTab(2)} style={{display: page === "realizacie" ? "none" : "flex"}}>
                    <Link to={`/admin/${page}/prepocet-ceny`}>
                        <p>Prepočet ceny</p>
                        <img src={tab === 2 ? currency2 : currency} alt="prepočet ceny" className={classes.img}/>
                    </Link>
                </li>
            </ul>
            <h2>{page}</h2>
        </nav>
    )
}

export default AdminNavbar