import {DATABASE_CATALOG} from "../types";


const DatabaseCatalogReducer = (state = [], action) => {
    switch (action.type) {
        case DATABASE_CATALOG:
            return action.payload
        default:
            return state
    }
}

export default DatabaseCatalogReducer





