import classes from "./Footer.module.scss";
import logo from "../../../assets/logoFull.png";
import {useHistory} from "react-router-dom";


function Footer() {
    let history = useHistory()

    const items = ["Home", "O nás", "Katalóg", <span className={classes.logo}><img src={logo} alt="logo"/></span>, "Realizácie", "Stupne výstavby", "Články"]
    const itemsCZ = ["Home", "O nás", "Katalog", <span className={classes.logo}><img src={logo} alt="logo"/></span>, "Realizace", "Stupně konstrukce", "Články"]
    const urls = ["/", "/o-nas", "/katalog", undefined, "/realizacie", "/stupne-vystavby", "/clanky"]

    function handleRedirect(url) {
        history.push(url)
    }

    return (
        <div className={classes.footer}>
            <nav className={classes.nav}>
                <ul>
                    {(window.location.host.split(".").pop() !== "cz" ? items : itemsCZ).map((item, index) => {
                        return (
                            <li key={typeof item === "string" ? item : index} onClick={() => handleRedirect(urls[index])}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </nav>

            <p>
                Ⓒ 2022 Drevostavby Bruno Slovakia, s.r.o. All rights reserved
            </p>
        </div>
    )
}

export default Footer


