import classes from "./Info2.module.scss";


function Info2({itemInfo}) {
    return window.location.host.split(".").pop() !== "cz" ?
        <div className={classes.wrapper}>
            <ul>
                {typeof itemInfo["počet izieb"] !== "undefined" ? <li><strong>{itemInfo["počet izieb"]}</strong><span>počet izieb</span></li> : null}
                {typeof itemInfo["úžitková plocha"] !== "undefined" ? <li><strong>{itemInfo["úžitková plocha"]}</strong><span>úžitková plocha</span></li> : null}
                {typeof itemInfo["obytná plocha"] !== "undefined" ? <li><strong>{itemInfo["obytná plocha"]}</strong><span>obytná plocha</span></li> : null}
            </ul>
            {typeof itemInfo["holodom"] !== "undefined" ? <div><span>od </span><strong>{itemInfo["holodom"]}</strong></div> : null}
        </div> :
        <div className={classes.wrapper}>
            <ul>
                {typeof itemInfo["počet izieb"] !== "undefined" ? <li><strong>{itemInfo["počet izieb"]}</strong> <span>počet pokojů</span></li> : null}
                {typeof itemInfo["úžitková plocha"] !== "undefined" ? <li><strong>{itemInfo["úžitková plocha"]}</strong><span>využitelná plocha</span></li> : null}
                {typeof itemInfo["obytná plocha"] !== "undefined" ? <li><strong>{itemInfo["obytná plocha"]}</strong><span>obývací prostor</span></li> : null}
            </ul>
            {typeof itemInfo["holodom.CZ"] !== "undefined" ? <div><span>od </span><strong>{itemInfo["holodom.CZ"]}</strong></div> : null}
        </div>
}

export default Info2
