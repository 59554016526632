import {COOKIES} from "../types";

const CookiesReducer = (state = [], action) => {
    switch (action.type) {
        case COOKIES:
            return action.payload
        default:
            return state
    }
}

export default CookiesReducer



