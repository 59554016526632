import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import classes from "./InteractiveMap.module.scss";
import map from "../../../assets/mapa drevostavby.png";
import InnerImageZoom from "react-inner-image-zoom";


function InteractiveMap() {
    return (
        <div className={classes.interactiveMap}>
            <InnerImageZoom src={map} fullscreenOnMobile/>
        </div>
    )
}

export default InteractiveMap


