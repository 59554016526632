import classes from "./EditTab.module.scss";
import PulseButton from "../../../pulseButton/PulseButton";


function EditTab({page}) {
    return (
        <div className={classes.edit}>
            <PulseButton text={window.location.host.split(".").pop() !== "cz" ? `Prejsť do ${page}` : `Přejít do ${page}`} redirectUrl={`/${page}`}/>
        </div>
    )
}

export default EditTab