import classes from "./Loading.module.scss";
import logo from "../../../assets/logoFull.png";
import Spinner from "./spinner/Spinner";


function Loading({fullHeight}) {
    return (
        <div style={{height: fullHeight ? "100vh" : "fit-content"}} className={classes.loading}>
            <img src={logo} alt="logo"/>
            <Spinner color="var(--red)"/>
        </div>
    )
}

export default Loading