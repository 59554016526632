import classes from "./Contact.module.scss";
import phone from "../../../assets/call.png";
import email from "../../../assets/download.png";
import facebook from "../../../assets/facebook.png";
import EditWrapper from "../../editWrapper/EditWrapper";
import ContactAdminPanel from "./panel/ContactAdminPanel";
import {connect} from "react-redux";


function Contact({database, admin}) {
    const icons = [
        {src: phone, title: window.location.host.split(".").pop() !== "cz" ? "Telefón" : "Telefon", text: "+421 917 427 709"},
        {src: email, title: "Email", text: "info@drevostavbybruno.sk"},
        {src: facebook, title: "Facebook", text: ""}]

    return (
        <div className={classes.contact}>
            <h2>
                {window.location.host.split(".").pop() !== "cz" ? "Kontakt" : "Kontakty"}
            </h2>
            {window.location.host.split(".").pop() !== "cz" ? null :
                <h3>
                    Zaujala vás naše nabídka? Náš obchodní zástupce rád za vámi přijde a domluvíte si detaily.
                </h3>
            }
            <article>
                <section className={classes.section1}>
                    {icons.map((icon, index) => {
                        return (
                            <div className={classes.icon} key={index} onClick={icon?.title === "Facebook" ? () => window.open("https://www.facebook.com/people/Drevostavby-Bruno-Slovakia-montovan%C3%A9-domy/100055616241084/") : undefined} style={{cursor: icon?.title === "Facebook" ? "pointer" : "auto"}}>
                                <img src={icon?.src} alt={icon?.text}/>
                                <span>
                                        <h2>{icon?.title}</h2>
                                        <p>{icon?.text}</p>
                                    </span>
                            </div>
                        )
                    })}
                </section>

                <ContactAdminPanel/>
                <section className={classes.section2}>
                    {database?.contacts?.people && Object.keys(database?.contacts?.people).sort(function (a, b) {
                        return database?.contacts?.people[a].timestamp < database?.contacts?.people[b].timestamp ? -1 : 1
                    })?.map((index) => {
                        return (admin || database?.contacts?.people[index]?.location === "all" || (database?.contacts?.people[index]?.location === "sk" && window.location.host.split(".").pop() !== "cz") || (database?.contacts?.people[index]?.location === "cz" && window.location.host.split(".").pop() === "cz")) ? (
                            <EditWrapper smallIcon value={index} dialogCase="contacts" key={index}>
                                <div>
                                    <h2>{database?.contacts?.people[index]?.name}</h2>
                                    <p>{window.location.host.split(".").pop() !== "cz" ? database?.contacts?.people[index]?.job : database?.contacts?.people[index]?.jobCZ}</p>
                                    <p>{database?.contacts?.people[index]?.phone}</p>
                                    <p>{database?.contacts?.people[index]?.email}</p>
                                </div>
                            </EditWrapper>
                        ) : null
                    })}
                </section>
                <section className={classes.place}>
                    <h1>KDE NÁS NÁJDETE?</h1>
                    <p>ADRESA SÍDLA</p>
                    <p>Lehotská areál PD, 02957 Oravská Lesná</p>
                    <iframe
                        title="Kde nás nájdete?"
                        height="500"
                        width="100%"
                        allowFullScreen
                        src="https://www.google.com/maps/embed?origin=mfe&pb=!1m4!2m1!1s49.369457,+19.155606!5e0!6i14"
                    />
                </section>
                {/*original section*/}
                {/*<section className={classes.section3}>*/}
                {/*    <h2>{window.location.host.split(".").pop() !== "cz" ? database?.billingInformation?.title : database?.billingInformation?.titleCZ}</h2>*/}
                {/*    <p>{database?.billingInformation?.subtitle}</p>*/}
                {/*    <p>{database?.billingInformation?.text0}</p>*/}
                {/*    <p>{database?.billingInformation?.text1}</p>*/}
                {/*    <p>{database?.billingInformation?.text2}</p>*/}
                {/*    <p>{database?.billingInformation?.text3}</p>*/}
                {/*</section>*/}

                {window.location.host.split(".").pop() !== "cz" ?
                    <section className={classes.section3}>
                        <h2>FAKTURAČNÉ ÚDAJE</h2>
                        <p>DREVOSTAVBY BRUNO SLOVAKIA, S.R.O.</p>
                        <p>PLAVISKO 7</p>
                        <p>034 01 RUŽOMBEROK</p>
                        <p>IČO: 50 988 956</p>
                        <p>IČ DPH: SK 212 055 6757</p>
                    </section>
                    :
                    <section className={classes.section3}>
                        <h2>Fakturační údaje</h2>
                        <p>BRUNO CZ buildings & constructions, s.r.o.</p>
                        <p>Záhřebská 562/41, Vinohrady (Praha 2)</p>
                        <p>120 00 Praha</p>
                        <p>IČO: 17 379 351</p>
                        {/*<p>{database?.billingInformation?.text3}</p>*/}
                    </section>
                }
            </article>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        admin: state.admin,
        database: state.databaseTexts?.texts
    }
}

export default connect(mapStateToProps)(Contact)

