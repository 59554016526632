import classes from "./PriceOfferBanner.module.scss";
import logo from "../../assets/logo.png";
import {useHistory} from "react-router-dom";


function PriceOfferBanner() {
    let history = useHistory()

    function handleClick() {
        history.push("/cenova-ponuka")
    }

    return (
        <div className={classes.wrapper}>
            <img src={logo} alt="logo"/>

            <button onClick={handleClick}>
                {window.location.host.split(".").pop() !== "cz" ?
                    "Cenová ponuka na mieru " :
                    "Cenová nabídka na míru"
                }
            </button>

        </div>
    )
}


export default PriceOfferBanner
