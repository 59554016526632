import {DATABASE_TEXTS} from "../types";


const DatabaseTextsReducer = (state = [], action) => {
    switch (action.type) {
        case DATABASE_TEXTS:
            return action.payload
        default:
            return state
    }
}

export default DatabaseTextsReducer





