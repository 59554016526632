import CookiesMain from "./cookiesMain/CookiesMain";
import CustomizeCookies from "./customizeCookies/CustomizeCookies";
import {connect} from "react-redux";
import {useCookies} from "react-cookie";
import {useEffect, useState} from "react";
import {Dialog, DialogContent} from "@mui/material";


function Cookies({getCookies}) {
    const [cookies] = useCookies([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (getCookies === "closed") {
                setOpen(true)
            } else {
                cookies["google_analytics"] === undefined ? setOpen(true) : setOpen(false)
            }
        }, 3000)
        return function cleanup() {
            clearTimeout(timeoutId)
        }
    }, [cookies, getCookies])

    return (
        <Dialog open={getCookies === "closed" ? false : open} maxWidth="100">
            <DialogContent>
                {getCookies === "customize" ? <CustomizeCookies/> : <CookiesMain/>}
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        getCookies: state.getCookies
    }
}

export default connect(mapStateToProps)(Cookies)

