import classes from "./FullGallery.module.scss";
import DeleteItem from "../gallery/deleteItem/DeleteItem";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

function FullGallery({data}) {
    let history = useHistory()

    function handleRedirect(item) {
        if (typeof item === "string") {
            history.push(`/realizujeme/${item}`)
        } else {
            history.push("/realizujeme")
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.fullGallery}>
                {Object.keys(data).sort(function (a, b) {
                    return data[a]?.timestamp > data[b]?.timestamp ? -1 : 1;
                }).map((item, index) => {
                    return (
                        <div key={index} className={classes.item} id={data[item].name}>
                            <DeleteItem itemName={data[item]?.name} page="realizujeme"/>
                            <span onClick={() => handleRedirect(item)}>
                                    <div className={classes.titleAndImg}>
                                        <h1>{data[item]?.name}</h1>
                                        <img src={data[item]?.mainImage} alt={data[item]?.name} loading="lazy"/>
                                    </div>
                                </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps,)(FullGallery)
