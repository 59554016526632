import {PRICE_OFFER_SELECTED} from "../types";


const PriceOfferSelectedReducer = (state = null, action) => {
    switch (action.type) {
        case PRICE_OFFER_SELECTED:
            return action.payload
        default:
            return state
    }
}

export default PriceOfferSelectedReducer





