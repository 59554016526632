import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {useState} from "react";
import {setDatabaseTexts} from "../../../redux/actions/databaseTexts";
import {doc, getFirestore, setDoc} from "firebase/firestore";


function RemoveContact({databaseContacts, databaseTexts, setDatabaseTexts, handleClose}) {
    const [checked, setChecked] = useState({})

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        });
    }

    async function submit() {
        const people = databaseContacts.people
        Object.keys(checked).map(item => {
            delete people[item]
            return null
        })

        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "texts", "contacts"), {people}, {merge: false})
            .then(() => {
                setDatabaseTexts({...databaseTexts, texts: {...databaseTexts.texts, contacts: {...databaseTexts.texts.contacts, people: people}}})
            })
        setChecked({})
        handleClose()
    }

    function changePerson(e, person) {
        setChecked({...checked, [person]: e.target.checked})
    }

    return (
        <div>
            <h2>Ktoré kontakty chcete vymazať?</h2>
            {databaseContacts.people ? Object.keys(databaseContacts.people).map((person, index) => {
                return (
                    <div key={index} className={classes.withCheckbox}>
                        <input type="checkbox" onChange={(e) => changePerson(e, person)}/>
                        <p>{databaseContacts.people[person].name}</p>
                    </div>
                )
            }) : null}

            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts,
        databaseContacts: state.databaseTexts?.texts?.contacts
    }
}

export default connect(mapStateToProps, {setDatabaseTexts})(RemoveContact)