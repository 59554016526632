import "swiper/css/swiper.css";
import classes from "./SelectedGallery.module.scss";
import Info from "../info/Info";
import Swiper from "react-id-swiper";
import EditWrapper from "../../editWrapper/EditWrapper";
import AdminDragAndDrop from "./adminDragAndDrop/AdminDragAndDrop";
import RemoveImageGalleryItem from "../../dialogWrapper/cases/RemoveImageFromGalleryItem";
import {connect} from "react-redux";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {setPriceOfferSelected} from "../../../redux/actions/priceOfferSelected";
import {Navigation, Pagination} from "swiper/js/swiper.esm";


function SelectedGallery({databaseCatalog, databaseRealizacie, item, urlBase, thisItem, setPriceOfferSelected}) {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    const path = urlBase.replace("/", "")

    const params = {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 1000,
            modifier: 1,
            slideShadows: false
        },
        pagination: {
            el: ".swiper-pagination"
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        renderPrevButton: () => <button className={`swiper-button-prev ${classes.arrow}`}/>,
        renderNextButton: () => <button className={`swiper-button-next ${classes.arrow}`}/>,
        modules: [Navigation, Pagination],
    }

    function galleryItem() {
        if (urlBase.replace("/", "") === "katalog" && databaseCatalog?.catalog[item]) {
            return databaseCatalog?.catalog[item]
        } else if (urlBase.replace("/", "") === "realizacie" && databaseRealizacie?.realizacie[item]) {
            return databaseRealizacie?.realizacie[item]
        }
    }

    let history = useHistory()

    function redirectToPriceOffer() {
        setPriceOfferSelected(item)
        history.push("/cenova-ponuka")
    }

    return (
        <article className={classes.selectedGallery}>

            <section className={classes.text}>
                <EditWrapper dialogCase="changeThisItemOnly" path={item} value="name" page={path}>
                    <h1 className={classes.title}>{thisItem ? thisItem[item]?.name : galleryItem()?.name}</h1>
                </EditWrapper>
                <EditWrapper dialogCase="changeThisItemOnly" centered path={item} page={path} value="description">
                    <p className={classes.description}>{thisItem ? thisItem[item]?.description : galleryItem()?.description}</p>
                </EditWrapper>
            </section>

            <AdminDragAndDrop item={item} urlBase={urlBase}/>

            <section className={urlBase.replace("/", "") === "katalog" ? classes.images : classes.imagesCZ}>
                {(thisItem ? thisItem[item]?.images?.length > 1 : galleryItem().images?.length > 1) ?
                    <Swiper {...params} activeSlideKey={`${Math.round(thisItem ? thisItem[item]?.images?.length / 2 - 1 : galleryItem().images?.length / 2 - 1)}`}>
                        {(thisItem ? thisItem[item]?.images?.sort() : galleryItem()?.images?.sort()).map((image, index) => {
                            return (
                                <span className={urlBase.replace("/", "") === "katalog" ? classes.img : classes.imgCZ} key={index}>
                                    <RemoveImageGalleryItem itemName={thisItem ? thisItem[item]?.name : galleryItem().name} imageUrl={image} page={urlBase.replace("/", "")}/>
                                    <img
                                        src={image}
                                        loading="lazy"
                                        alt={thisItem ? thisItem[item]?.name : galleryItem().name}
                                        className={urlBase.replace("/", "") === "katalog" ? classes.imageMainCatalog : classes.imageMainRealizacie}
                                    />
                                </span>
                            )
                        })}
                    </Swiper> :
                    (thisItem ? thisItem[item]?.images : galleryItem()?.images)?.map((image, index) => {
                        return (
                            <span className={urlBase.replace("/", "") === "katalog" ? classes.img : classes.imgCZ} key={index}>
                                <RemoveImageGalleryItem itemName={thisItem ? thisItem[item]?.name : galleryItem().name} imageUrl={image} page={urlBase.replace("/", "")}/>
                                <img
                                    src={image}
                                    loading="lazy"
                                    alt={thisItem ? thisItem[item]?.name : galleryItem().name}
                                    className={urlBase.replace("/", "") === "katalog" ? classes.imageMainCatalog : classes.imageMainRealizacie}
                                />
                            </span>
                        )
                    })
                }
            </section>


            {urlBase.replace("/", "") === "katalog" ?
                <section className={classes.info}>
                    <span style={{display: thisItem ? thisItem[item]?.info : galleryItem()?.info ? "inherit" : "none"}}>
                        <EditWrapper dialogCase="galleryItemInfo" page={path} centered value={thisItem ? thisItem : galleryItem}>
                            <Info itemInfo={thisItem ? thisItem[item]?.info : galleryItem()?.info}/>
                        </EditWrapper>
                    </span>
                </section> : null}

            {urlBase.replace("/", "") === "katalog" ?
                <button className={classes.priceButton} onClick={redirectToPriceOffer}>
                    Prejsť na cenovú ponuku pre tento dom
                </button> : null
            }

            {urlBase.replace("/", "") === "katalog" ?
                <section className={classes.pdf}>
                    {(thisItem ? thisItem[item]?.pdf : galleryItem()?.pdf) ?
                        <span className={classes.pdfSection}>
                        <h2>{window.location.host.split(".").pop() !== "cz" ? "Pôdorys" : "Půdorys"}</h2>
                        <object data={thisItem ? thisItem[item]?.pdf : galleryItem()?.pdf} type="application/pdf" className={classes.iframe} wmode="fit">
                            <iframe title={thisItem ? thisItem[item]?.pdf : galleryItem()?.pdf} src={`https://docs.google.com/viewer?url=${thisItem ? thisItem[item]?.pdf : galleryItem()?.pdf}&embedded=true`} className={classes.iframe}/>
                        </object>
                    </span> : null}
                </section> : null}
        </article>
    )
}

const mapStateToProps = state => {
    return {
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie
    }
}

export default connect(mapStateToProps, {setPriceOfferSelected})(SelectedGallery)
