export const GOOGLE_ANALYTICS = "google_analytics"
export const ADFORM = "adform"
export const FACEBOOK = "facebook"
export const HOTJAR = "hotjar"

export const CORE_COOKIES = "core_cookies"
export const FUNCTIONAL_COOKIES = "functional_cookies"
export const AD_COOKIES = "ad_cookies"







