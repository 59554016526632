import React from "react";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";


function Root({children, initialState = {}}) {
    return (
        <Provider store={createStore(rootReducer, initialState, compose(applyMiddleware(thunk)))}>
            {children}
        </Provider>
    )
}

export default Root


