import classes from "./Unauthorized.module.scss";


function Unauthorized() {
    return (
        <div className={classes.unauthorized}>
            <h1>403 Unauthorized</h1>
        </div>
    )
}

export default Unauthorized