import classes from "./AboutUsText.module.scss";
import logo from "../../../assets/logo.png";
import EditWrapper from "../../editWrapper/EditWrapper";
import PulseButton from "../../pulseButton/PulseButton";
import {connect} from "react-redux";


function AboutUsText({database}) {
    return (
        <EditWrapper dialogCase="simpleText" name="aboutUs" fullHeight>
            <article className={classes.article}>
                <img src={logo} alt="logo"/>
                <p>{window.location.host.split(".").pop() !== "cz" ? database?.text : database?.textCZ}</p>
                <PulseButton text={window.location.host.split(".").pop() !== "cz" ? database?.button : database?.buttonCZ} redirectUrl="/o-nas"/>
            </article>
        </EditWrapper>
    )
}

const mapStateToProps = state => {
    return {
        database: state.databaseTexts?.texts?.aboutUs
    }
}

export default connect(mapStateToProps)(AboutUsText)