import classes from "./CookiesMain.module.scss";
import cookiesImage from "../../assets/cookiesImage.png";
import {useCookies} from "react-cookie";
import {connect} from "react-redux";
import {setCookiesScreen} from "../../redux/actions/cookies/cookies";
import {AD_COOKIES, ADFORM, CORE_COOKIES, FACEBOOK, FUNCTIONAL_COOKIES, GOOGLE_ANALYTICS, HOTJAR} from "../dependencies/Dependencies";


function CookiesMain({setCookiesScreen}) {
    const [cookies, setCookie] = useCookies([])
    const options = {path: "/", sameSite: "lax"}

    function setAllCookies(value) {
        setCookie(GOOGLE_ANALYTICS, value, options)
        setCookie(ADFORM, value, options)
        setCookie(FACEBOOK, value, options)
        setCookie(HOTJAR, value, options)

        setCookie(CORE_COOKIES, value, options)
        setCookie(FUNCTIONAL_COOKIES, value, options)
        setCookie(AD_COOKIES, value, options)
        setCookiesScreen("closed")
    }

    return (
        <article className={classes.cookies}>
            <section>
                <h1>
                    Cookies
                    <img src={cookiesImage} alt="cookies"/>
                </h1>
                <p>
                    {window.location.host.split(".").pop() !== "cz" ?
                        "Ak navštívite stránku, ktorá zapisuje cookies, vo vašom počítači sa vytvorí malý textový súbor, ktorý sa uloží vo vašom prehliadači. Ak tú istú stránku navštívite nabudúce, pripojíte sa vďaka nemu na web rýchlejšie a náš web vám ponúkne relevantné informácie alebo vám umožnípočas objednávky jednoduchšie vyplnenie údajov." :
                        "Pokud navštívíte stránku, která zapisuje cookies, ve vašem počítači se vytvoří malý textový soubor, který se uloží ve vašem prohlížeči. Pokud stejnou stránku navštívíte příště, připojíte se díky němu na web rychleji a náš web vám nabídne relevantní informace nebo vám umožní během objednávky jednodušší vyplnění údajů."
                    }
                </p>
                <p>
                    {window.location.host.split(".").pop() !== "cz" ?
                        "Súbory cookies používame najmä na anonymnú analýzu návštevnosti a vylepšovanie našich web stránok. V prípade, že si nastavíte blokovanie zápisu cookies do vášho prehliadača, je možné, že niektoré časti našej stránky nebudú fungovať alebo sa web spomalí." :
                        "Soubory cookies používáme zejména pro anonymní analýzu návštěvnosti a vylepšování našich webových stránek. V případě, že si nastavíte blokování zápisu cookies do vašeho prohlížeče, je možné, že některé části naší stránky nebudou fungovat nebo web zpomalí."
                    }
                </p>
            </section>
            <section>
                <button onClick={() => setAllCookies(true)}>
                    {window.location.host.split(".").pop() !== "cz" ?
                        "Akceptovať všetky cookies" : "Akceptovat všechny cookies"
                    }
                </button>
                <button onClick={() => setCookiesScreen("customize")}>
                    {window.location.host.split(".").pop() !== "cz" ?
                        "Nastaviť cookies" : "Nastavit cookies"
                    }
                </button>
                <button onClick={() => setAllCookies(false)}>
                    {window.location.host.split(".").pop() !== "cz" ?
                        "Odmietnuť všetky cookies" : "Odmítnout všechny cookies"
                    }
                </button>
            </section>
        </article>
    )
}

export default connect(null, {setCookiesScreen})(CookiesMain)

