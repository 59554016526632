import classes from "./PriceOffer.module.scss";
import img1 from "../../../assets/1.png";
import img2 from "../../../assets/2.png";
import img3 from "../../../assets/rozkliknuta sekcia obrazok.jpg";
import logo from "../../../assets/logoFull.png";
import JSZip from "jszip";
import Navbar from "../../../components/navbar/Navbar";
import Header from "../../../components/admin/header/Header";
import YouTube from "react-youtube-embed";
import Dropzone from "react-dropzone";
import firebaseApp from "../../../firebase";
import {Trash} from "tabler-icons-react";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";


function PriceOffer({priceOfferSelected}) {
    const fields = ["Vaše meno a priezvisko", "Váš email", "Vaše tel. číslo", "Názov obce", "Ďalšie doplňujúce info"]
    const fieldsCZ = ["Vaše jméno a příjmení", "Váš email", "Vaše tel. číslo", "Název obce", "Další doplňující info"]

    const [data, setData] = useState({})
    const [files, setFiles] = useState([])
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (priceOfferSelected) {
            const element = document.getElementById("form-title")
            const elementPosition = element.getBoundingClientRect().top
            window.scrollTo({top: elementPosition, behavior: "smooth"})
            const field = window.location.host.split(".").pop() !== "cz" ? fields : fieldsCZ

            setData({...data, [field[fields.length - 1]]: `Cenova ponuka pre: ${priceOfferSelected}`})
        } else {
            window.scrollTo({top: 0, behavior: "smooth"})
        }
    }, [])

    async function handleSubmit() {
        const jszip = new JSZip()
        const firestore = getFirestore(firebaseApp)
        const storage = getStorage()

        const today = new Date()
        const now = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()} ${today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}:${today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()}`

        const name = data[fields[0]] || data[fieldsCZ[0]]
        const id = `${now} - ${name}`
        const idZip = `${name} - ${now}.zip`

        let zipFiles = jszip.folder("cenova ponuka")

        for (const file of files) {
            zipFiles.file(file.name, file.buffer)
        }

        let zipRef
        if (files.length > 0) {
            await jszip.generateAsync({type: "blob"}).then(async function (blob) {
                const storageRef = ref(storage, idZip)
                await uploadBytes(storageRef, blob, {cacheControl: "public,max-age=9999999999999999999"}).then(async (snapshot) => {
                    zipRef = await getDownloadURL(snapshot.ref)
                })
            })
        }

        const newData = {...data, link: zipRef ? zipRef : ""}

        await toast.promise(
            setDoc(doc(firestore, "priceOffers", id), newData, {merge: true}).then(async () => {
                    setData({})
                    window.scrollTo({top: 0, behavior: "smooth"})
                    setFiles([])
                    setChecked(false)
                }
            ), {
                pending: "Odosielam dopyt",
                success: "Ďakujeme Vašu správu sme prijali. Náš obchodný zástupca sa jej bude venovať v najbližšom možnom termíne.",
                error: "Niečo sa pokazilo. Skúste prosím ešte raz."
            }
        )
    }

    function disabled() {
        let flag = false
        for (const test of (window.location.host.split(".").pop() !== "cz" ? fields : fieldsCZ)) {
            if (!["Ďalšie doplňujúce info", "Další doplňující info"].includes(test)) {
                if (data[test] === "" || data[test] === undefined || data[test] === null) {
                    flag = true
                }
            }
        }
        return flag || !checked
    }

    async function handleRejected(rejectedFiles) {
        if (rejectedFiles[0].errors[0].code === "file-too-large") {
            toast.error("Maximálna povolená veľkosť súboru je 30MB")
        } else {
            toast.error("Nastala chyba, prosím skúste znova")
        }
    }

    function removeItem(index) {
        let copy = [...files]
        copy.splice(index, 1)
        setFiles(copy)
    }

    function onDrop(newFiles) {
        let acceptedFiles = []

        let names = []
        for (const item of files) {
            names.push(item.name)
        }

        for (const newFile of newFiles) {
            if (names.includes(newFile.name)) {
                toast.error("Súbor s rovnakým menom už existuje")
            } else {
                acceptedFiles.push({buffer: newFile, name: newFile.name})
            }
        }

        setFiles([...files, ...acceptedFiles])
    }

    return (
        <span>
            <Header/>
            <Navbar/>

            <article className={classes.wrapper}>
                <div className={classes.title}>
                <img src={logo} alt="logo"/>
                    <section>
                        <h3>
                            {window.location.host.split(".").pop() !== "cz" ? "Montovaný dom alebo dom na kľúč?" : "Montovaný dům nebo dům na klíč?"}
                        </h3>
                        <p>
                             {window.location.host.split(".").pop() !== "cz" ? "Zistite, koľko vás bude stáť jeho realizácia." : "Zjistěte, kolik vás bude stát jeho realizace."}
                        </p>
                    </section>
                </div>
                 <div className={classes.body}>
                     <section>
                        <h3>
                            {window.location.host.split(".").pop() !== "cz" ? "VYPRACUJEME VÁM CENOVÚ PONUKU NA MIERU" : "VYPRACUJEME VÁM CENOVOU NABÍDKU NA MÍRU"}
                        </h3>
                        <p>
                             {window.location.host.split(".").pop() !== "cz" ? "...a budeme sa pri tom riadiť týmito zásadami." : "...a budeme se při tom řídit těmito zásadami."}
                        </p>

                        <div className={classes.section2}>
                            <img src={img3} alt="Kontaktný formulár obrázok"/>
                            <ul>
                                <li>
                                    <img src={img1} alt="1"/>
                                    <title>
                                        {window.location.host.split(".").pop() !== "cz" ? "NEPODLIEZAME" : "NEPODLÍZÁME"}
                                    </title>
                                    <p>
                                        {window.location.host.split(".").pop() !== "cz" ? "Hráme fér voči vám aj našej konkurencii. A preto nás nezaujímajú konkurenčné cenové ponuky, len\n" + "aby sme ich mohli podliezť o pár EUR. Toto jednoducho nerobíme." : "Hrajeme fér vůči vám i naší konkurenci. A proto nás nezajímají konkurenční cenové nabídky, jen\n" + "abychom je mohli podlézt o pár EUR. Toto prostě neděláme."}
                                    </p>
                                </li>
                                 <li>
                                     <img src={img2} alt="2"/>
                                     <title>
                                        {window.location.host.split(".").pop() !== "cz" ? "POZNÁME NAŠE KVALITY" : "ZNÁME NAŠE KVALITY"}
                                    </title>
                                    <p>
                                        {window.location.host.split(".").pop() !== "cz" ? "Na skúsenosti a odbornosť našich zamestnancov sme hrdí. Naše ceny sú adekvátne ich práci a\n" + "potrebám Vašej realizácie. Ceny v našom katalógu odrážajú realitu a preto ani na faktúre nenájdete\n" + "žiadne skryté poplatky." : "Na zkušenosti a odbornost našich zaměstnanců jsme hrdí. Naše ceny jsou adekvátní jejich práci a\n" + "potřebám Vaší realizace. Ceny v našem katalogu odrážejí realitu a proto ani na faktuře nenajdete\n" + "žádné skryté poplatky."}
                                    </p>
                                </li>
                            </ul>
                        </div>

                    </section>
                </div>

                <section className={classes.form}>

                    <div className={classes.video}>
                        <YouTube id="8Hc8y3sv-hg" appendSrc="?rel=0"/>
                    </div>

                     <p id="form-title">
                          {window.location.host.split(".").pop() !== "cz" ?
                              "Na vypracovanie podrobnej cenovej ponuky od Vás potrebujeme tieto údaje." :
                              "K vypracování podrobné cenové nabídky od Vás potřebujeme tyto údaje."
                          }
                     </p>

                    {(window.location.host.split(".").pop() !== "cz" ? fields : fieldsCZ).map((field) => {
                        return field !== "Ďalšie doplňujúce info" && field !== "Další doplňující info" ? (
                                <div key={field}>
                                    <label>
                                        {field}
                                    </label>
                                    <input
                                        value={data[field] || ""}
                                        onChange={(e) => setData({...data, [field]: e.target.value})}
                                    />
                                </div>
                            ) :
                            <div key={field}>
                                <label>
                                    {field}
                                </label>
                                <textarea
                                    value={data[field] || ""}
                                    onChange={(e) => setData({...data, [field]: e.target.value})}
                                />
                            </div>

                    })}

                    <div className={classes.upload}>
                        <p>Uploadnite Vašu vlastnú predstavu (vo formáte pdf alebo jpg)</p>

                        <Dropzone
                            multiple={false}
                            maxSize={30000001}
                            onDropAccepted={onDrop}
                            disabled={files.length === 3}
                            onDropRejected={handleRejected}
                            accept="application/pdf, image/jpg, image/jpeg"
                        >
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()} className={classes.dropzone}>

                                    {files && files.length < 3 ?
                                        <button className={classes.dragAndDrop}>
                                            {files.length === 0 ? "Vybrať súbor" : "Vybrať ďalší súbor"}
                                        </button> :
                                        <p>Dosiahnutý maximálny počet súborov</p>
                                    }

                                    <input {...getInputProps()}/>
                                </div>
                            )}
                        </Dropzone>

                         <ul className={classes.list}>
                            {files.map((file, index) => {
                                return (
                                    <li key={file.name}>
                                        <Trash onClick={() => removeItem(index)} className={classes.removeIcon}/>
                                        {file.name}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>


                    <span className={classes.checkbox}>
                        <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
                        <p>Odoslaním súhlasím so spracovaním osobných údajov GDPR</p>
                    </span>

                    <button onClick={handleSubmit} className={classes.submit} disabled={disabled()}>
                              {window.location.host.split(".").pop() !== "cz" ? "Odoslať dopyt" : "Odeslat poptávku"}
                    </button>
                </section>
            </article>
        </span>
    )
}


const mapStateToProps = state => {
    return {
        priceOfferSelected: state.priceOfferSelected
    }
}

export default connect(mapStateToProps)(PriceOffer)
