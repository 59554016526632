import classes from "./Articles.module.scss";
import img1 from "../../assets/ako-vybrat-projekt-bruno-768x336.jpg";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";


function Template() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    let history = useHistory()

    async function scrollToContacts() {
        await history.push("/")
        setTimeout(() => {
            const element = document.getElementById("Kontakt")
            const elementPosition = element.getBoundingClientRect().top
            window.scrollTo({top: elementPosition, behavior: "smooth"})
        }, 1000)
    }

    return (
        <article className={classes.article}>
            <title>Ako si vybrať správny projekt</title>
            <img src={img1} alt="Ako si vybrať správny projekt"/>
            <p>
                <strong>
                    V súčasnosti sú na trhu stovky najrozličnejších katalógových projektov rodinných domov. Výber správneho projektu, ktorý sa bude najviac približovať predstavám a požiadavkám vašej rodiny, tak nemusí byť vždy tou najjednoduchšou úlohou. Optimálny projekt rodinného domu musí okrem
                    iných faktorov zohľadňovať parametre pozemku, ale to samozrejme nie je ani zďaleka všetko. Otázok, ktoré sa spájajú s výstavbou nového domu je oveľa viac a na všetky je potrebné nájsť adekvátne odpovede.
                </strong>
            </p>
            <p>
                Popri otázkach týkajúcich sa financovania výstavby nového rodinného domu si tak musíte urobiť jasno v tom, či očakávania vašej rodiny naplní jednopodlažný alebo pochodový dom, aká veľkosť domu vám bude vyhovovať a koľko izieb budete potrebovať na komfortné a plnohodnotné bývanie.
            </p>
            <p>
                Vysporiadať sa budete musieť tiež s pôdorysom rodinného domu, s typom strechy a so všetkými súčasnými alebo budúcimi individuálnymi požiadavkami ostatných rodinných príslušníkov. Už teraz sa preto oplatí myslieť tiež na primerané súkromie jednotlivých členov domácnosti, na prípadné
                prepojenie interiéru domu s vonkajšími terasami a na veľkosť presklených plôch.
            </p>
            <h1>
                Veľa napovie samotný pozemok
            </h1>
            <p>
                Rovinatosť alebo svahovitosť pozemku a jeho orientácia na svetové strany môže do veľkej miery ovplyvniť <a href="/katalog">výber správneho projektu</a>. Už v tejto fáze bude preto vhodné prípadné nejasnosti konzultovať s architektom alebo projektantom, ktorý dokážu doladiť nielen
                individuálny, ale aj katalógový projekt podľa konkrétnych daností pozemku. Architekt alebo aj skúsený projektant vám zároveň ponúkne neskreslený pohľad na kritické i silné miesta pozemku, ktoré môžete napokon využiť vo váš prospech.
            </p>
            <p>
                Pri individuálnom projekte rodinného domu, teda pri navrhovaní domu podľa vašich predstáv vám navyše pomôžu dostať minimálnu plošnú výmeru jednotlivých miestností do súladu s normou STN 734301 Budovy na bývanie a usporiadať dispozičné riešenie domu. Rozloženie a usporiadanie
                jednotlivých miestnosti domu totiž musí mať svoju logiku a systémovú nadväznosť. Orientácia miestností rodinného domu podľa svetových strán platí ako pre individuálne, tak aj pre katalógové projekty. Samozrejme za predpokladu, že takúto orientáciu stavby dovolí pozemok. Pozrime sa
                teda, ako by to v ideálnom prípade mohlo vyzerať v praxi.
            </p>
            <h1>
                Vstup na pozemok a do domu
            </h1>
            <p>
                Pre vchádzanie na pozemok a pre vstup do domu sa ako ideálna orientácia ukázala byť severná svetová strana. V súčasnosti je často preferovaný priamy vstup do priestorov rodinného domu. V našich klimatických podmienkach je však takýto bezprostredný vstup do obývačky spravidla
                nepraktický a odporúča sa siahnuť skôr po klasickom vstupnom komplexe pozostávajúcom zo závetria, zádveria a menšej vstupnej miestnosti.
            </p>
            <h1>
                Obývacia izba je centrom diania
            </h1>
            <p>
                Aj preto by toto srdce domu malo byť umiestnené v tesnej blízkosti vstupu do domu a v nadväznosti na jedáleň alebo kuchyňu. Jej veľkosť je individuálnou záležitosťou a rozhodnúť sa môžete pre rôzne rozmery. Jej výmera by však rozhodne nemala byť menšia ako 20 – 25 m2. Obývaciu izbu
                sa odporúča orientovať na južnú alebo juhozápadnú svetovú stranu.
            </p>
            <h1>
                Keď dostane prednosť jedáleň alebo kuchyňa
            </h1>
            <p>
                V mnohých domácnostiach je centrom spoločenského diania kuchyňa alebo jedáleň. Ak vaša rodina obľubuje spoločné debaty pri príprave rôznych dobrôt alebo si vychutnávate slávnostné stolovanie v priestrannej jedálni, môžete pri výbere rodinného domu prihliadať na projekty ponúkajúce
                otvorenú dispozíciu spájajúcu obývaciu miestnosť, jedáleň a kuchyňu do jedného celku.
            </p>
            <p>
                Pekná, priestranná a dobre vybavená kuchyňa môže byť skutočnou dominantou každého rodinného domu. Stredobodom domácnosti sa stane aj v prípade, keď dispozičné riešenie spojenej kuchyne a obývačky umožňuje prístup na terasu. Uzavretá kuchyňa by mala byť orientovaná na sever alebo
                severovýchod. Nadväzovať na ňu by mala špajza.
            </p>
            <h1>
                Detská izba odráža vek detí
            </h1>
            <p>
                Detská izba je vo všeobecnosti umiestňovaná v nočnej (súkromnej) časti domu. Pri menších deťoch môže byť umiestnená v blízkosti spálne rodičov, pri starších alebo dospievajúcich deťoch zasa v opačnom krídle domu. Pre detskú izbu zvoľte južnú, juhovýchodnú alebo východnú stranu.
            </p>
            <h1>
                Spálňa rodičov si zaslúži osobitnú pozornosť
            </h1>
            <p>
                Spálňa rodičov je bez akýchkoľvek pochybností najsúkromnejšou miestnosťou rodinného domu. Situovaná by preto mala byť na poschodí alebo v prípade jednopodlažného domu čo najďalej od rušných častí domu. Najvhodnejšia orientácia pre spálňu je východná, poprípade severovýchodná či
                juhovýchodná strana.
            </p>
            <p>
                Takýmto umiestnením spálne si zabezpečíte príjemné vstávanie za asistencie ranných slnečných lúčov, ktoré vyčarujú príjemnú atmosféru a zároveň vás nabijú pozitívnou energiou. Naopak, večer je v takto orientovanej spálni príjemné prítmie a chládok. Spomedzi viacerých výhod
                individuálneho projektu rodinného domu treba spomenúť možnosť umiestnenia šatníka a rôznych odkladacích priestorov do blízkosti spálne rodičov.
            </p>
            <h1>
                Bez povšimnutia nenecháme ani technické miestnosti
            </h1>
            <p>
                Pri projektoch rodinných domov s podpivničením nie je čo riešiť a kotolňu, sklad alebo akúkoľvek inú technickú miestnosť umiestnime do suterénu. V prípade jednopodlažného, ale aj poschodového domu sa technické miestnosti osvedčili v blízkosti hlavného vstupu alebo schodiska, či vedľa
                miestností určených na hygienu. Orientácia v tomto prípade závisí od umiestnenia spomínaných priestorov. Výber projektu môžu ovplyvniť aj ďalšie faktory. V prípade, že si nie ste istý správnym výberom projektu, tak nám
                <span className={classes.contacts} onClick={() => scrollToContacts()}> zavolajte </span>
                alebo napíšte. Pomôžeme vám nájsť optimálne riešenie!
            </p>
        </article>
    )
}

export default Template
