import classes from "./Customers.module.scss";
import Loading from "../../../../router/screens/loading/Loading";
import SearchBar from "./searchBar/SearchBar";
import firebaseApp from "../../../../firebase";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {toast} from "react-toastify";
import {setCms} from "../../../../redux/actions/cms";
import {connect} from "react-redux";
import {ChevronDown, Trash} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {Dialog, DialogContent} from "@mui/material";
import {collection, deleteDoc, doc, getDoc, getDocs, getFirestore} from "firebase/firestore";


function Customers({cms, setCms}) {
    const [opened, setOpened] = useState("")
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("")
    const [filteredClients, setFilteredClients] = useState(cms)

    useEffect(() => {
        async function loadCustomerData() {
            if (cms && Object.keys(cms).length > 0) {
                setLoading(false)
            } else {
                setLoading(true)
                const firestore = getFirestore(firebaseApp)
                const querySnapshot = await getDocs(collection(firestore, "cms"))

                let test = []
                for (const item of querySnapshot.docs) {
                    const snapshot = await getDoc(doc(firestore, `cms/${item.id}`))
                    test[item.id] = snapshot.data()
                }
                setCms({...test})
                setFilteredClients({...test})
                setLoading(false)
            }
        }

        loadCustomerData().catch()
    }, [cms, setCms])

    function searchByName() {
        setFilteredClients(Object.fromEntries(Object.entries(cms).filter(([key]) => cms[key]["meno zákazníka"]?.toLowerCase().includes(value?.toLowerCase()))))
    }

    function type(e) {
        setValue(e.target.value)
    }

    function clear() {
        setValue("")
        setFilteredClients({})
    }

    function handleEsc(e) {
        if (e.key === "Escape") {
            setOpened("")
        }
    }

    async function remove(clientName) {
        await toast.promise(async () => {
            const firestore = getFirestore(firebaseApp)
            await deleteDoc(doc(firestore, "cms", clientName))
                .then(() => {
                    delete cms[clientName]
                    delete filteredClients[clientName]
                    setCms(filteredClients)
                    setOpened("")
                })
        }, {
            pending: "Prebieha upload do databázy",
            success: "Klient úspešne vymazaný",
            error: "Upload neúspešný"
        })
    }

    return (
        <div className={classes.clientList}>
            <SearchBar
                onSubmit={searchByName}
                onType={type}
                onClear={clear}
                value={value}
                placeholder="Vyhľadávaj podľa mena"
            />
            {!loading ?
                Object.entries(filteredClients || cms).map(([index, key]) => {
                    return (
                        <li key={key["meno zákazníka"] + index}>
                            <MuiAccordion className={classes.accordion} disableGutters elevation={0}>
                                <MuiAccordionSummary
                                    className={classes.summary}
                                    expandIcon={<ChevronDown/>}
                                >
                                    {key["meno zákazníka"]}
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <table className={classes.jsonToTable}>
                                        {Object.keys(key).map((title, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{title} </td>
                                                    <td>{key[title]}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                    <Trash onClick={() => setOpened(key["meno zákazníka"])} className={classes.removeIcon}/>

                                    <Dialog
                                        open={opened === key["meno zákazníka"]}
                                        fullWidth
                                        className={classes.dialogWrapper}
                                        onKeyUp={handleEsc}
                                        transitionDuration={1000}
                                    >
                                        <DialogContent className={classes.dialogWrapper}>
                                            <h1>{`Ste si istý, že chcete vymazať klienta ${key["meno zákazníka"]}`}</h1>

                                            <div className={classes.buttons}>
                                                <button onClick={() => remove(key["meno zákazníka"])}>ÁNO</button>
                                                <button onClick={() => setOpened(false)}>NIE</button>
                                            </div>

                                        </DialogContent>
                                    </Dialog>

                                </MuiAccordionDetails>
                            </MuiAccordion>
                        </li>
                    )
                }) : <Loading/>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        cms: state.cms
    }
}

export default connect(mapStateToProps, {setCms})(Customers)