import {CATALOG_REFS} from "../types";


const CatalogRefsReducer = (state = null, action) => {
    switch (action.type) {
        case CATALOG_REFS:
            return action.payload
        default:
            return state
    }
}

export default CatalogRefsReducer





