import classes from "./Spinner.module.scss";
import CircleLoader from "react-spinners/CircleLoader";


function Spinner({color}) {
    return (
        <div className={classes.spinner}>
            <CircleLoader loading={true} color={color} size={50} margin={5} speedMultiplier={0.5}/>
        </div>
    )
}

export default Spinner