import "./index.css";
import App from "./app/App";
import Root from "./Root";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";


ReactDOM.render(
    <Root>
        <App/>
    </Root>,
    document.getElementById("root")
)

reportWebVitals()
