import classes from "./ContactAdminPanel.module.scss";
import DialogWrapper from "../../../dialogWrapper/DialogWrapper";
import {connect} from "react-redux";
import {useState} from "react";
import {setAdmin} from "../../../../redux/actions/admin";
import {Trash, UserPlus} from "tabler-icons-react";


function ContactAdminPanel({admin}) {
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    return admin ? (
        <div className={classes.panel}>
            <UserPlus className={classes.icon1} onClick={() => setOpen(true)}/>
            <Trash className={classes.icon2} onClick={() => setOpen2(true)}/>
            <DialogWrapper dialogCase="addContact" open={open} handleClose={() => setOpen(false)}/>
            <DialogWrapper dialogCase="removeContact" open={open2} handleClose={() => setOpen2(false)}/>
        </div>
    ) : null
}


const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps, {setAdmin})(ContactAdminPanel)