import classes from "./TransferData.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {doc, getFirestore, setDoc} from "firebase/firestore";


function TransferData({admin, item}) {
    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Položka presunutá",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "realizacie", item.name),
            {
                name: item?.name,
                description: item?.description,
                type: item?.type,
                info: item?.info,
                pdf: item?.pdf,
                images: item?.images,
                mainImage: item?.mainImage,
                timestamp: item?.timestamp
            }, {merge: true})
    }

    return admin ? (
        <section className={classes.transfer}>
            <p>Chcete presunúť túto položku do realizácií?</p>
            <p>Pred presunutím sa prosím uistite, že sa v realizáciách nenachádza položka s rovnakým názvom</p>
            <p>Táto položka nebude odstránená z realizujeme, avšak môžete ju následne manuálne odstrániť</p>
            <p>(odporúčame predtým skontrolovať v realizáciách)</p>

            <button onClick={submitWithToast}>
                Presunúť
            </button>
        </section>
    ) : null
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(TransferData)
