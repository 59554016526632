import classes from "./RealizacieHomePageImg.module.scss";
import img from "../../../assets/3_xxx.jpg";
import {Parallax, useParallaxController} from "react-scroll-parallax";
import title from "../../../assets/realizacieTitle.png";
import titleCZ from "../../../assets/realizacieTitle.png";


function RealizacieHomePageImg() {
    const parallaxController = useParallaxController()

    return (
        <div className={classes.whyUs}>
            <span className={classes.desktop}>
                {/*<Parallax className={classes.parallax1} translateY={["50%", "300%"]} >*/}
                {/*    <img src={window.location.host.split(".").pop() !== "cz" ? title : titleCZ} onLoad={() => parallaxController.update()} alt="Prečo s nami názov"/>*/}
                {/*</Parallax>*/}
                <img className={classes.parallax3} src={img} alt="Katalóg img"/>
            </span>
            <span className={classes.mobile}><img src={img} alt="Katalóg img"/></span>
        </div>
    )
}

export default RealizacieHomePageImg


