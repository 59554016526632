import classes from "./AdminCMSWrapper.module.scss";
import logo from "../../../assets/logoFull.png";
import Header from "../header/Header";
import Customers from "./customers/Customers";
import Unauthorized from "../unauthorized/Unauthorized";
import AddCustomer from "./addCustomers/AddCustomer";
import AdminCMSNavbar from "./adminCMSNavbar/AdminCMSNavbar"
import ExportCustomers from "./export/ExportCustomers";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";


function AdminCMSWrapper({page, admin}) {
    function displayLogo() {
        return (
            <div className={classes.images}>
                <img src={logo} alt="logo"/>
            </div>
        )
    }

    if (admin) return (
        <div className={classes.wrapper}>
            <Header/>
            <AdminCMSNavbar page={page}/>
            <Switch>
                <Route path={`/admin/${page}/pridat`} component={() => <AddCustomer/>}/>
                <Route path={`/admin/${page}/vsetci`} component={() => <Customers/>}/>
                <Route path={`/admin/${page}/export`} component={() => <ExportCustomers/>}/>
                <Route path={`/admin/${page}`} component={() => displayLogo()}/>
            </Switch>
        </div>
    )
    else return <Unauthorized/>
}


const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(AdminCMSWrapper)