import classes from "./Home.module.scss";
import logoFull from "../../../assets/logoFull.png";
import EditWrapper from "../../editWrapper/EditWrapper";
import {connect} from "react-redux";


function Home({database}) {
    return (
        <EditWrapper dialogCase="simpleText" name="home">
            <article className={classes.homeText}>
                <h3>{window.location.host.split(".").pop() !== "cz" ? database?.title : database?.titleCZ}</h3>
                <img src={logoFull} alt="logo"/>
                <p>{window.location.host.split(".").pop() !== "cz" ? database?.text : database?.textCZ}</p>
            </article>
        </EditWrapper>
    )
}

const mapStateToProps = state => {
    return {
        database: state.databaseTexts?.texts?.home
    }
}

export default connect(mapStateToProps)(Home)


