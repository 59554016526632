import classes from "./Articles.module.scss";
import img1 from "../../assets/hluk_rusi.jpg";
import img2 from "../../assets/zena_uvazuje.jpg";
import img3 from "../../assets/setrenie_na_dom.jpg";
import img4 from "../../assets/poziar_v_dome.jpg";
import {useEffect} from "react";


function Myty() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Mýty o montovaných domoch – veríte im aj vy?</title>
            <img src={img1} alt="Mýty o montovaných domoch – veríte im aj vy?"/>
            <p>
                <strong>
                    Záujem o montované domy u nás silnie a podiel drevostavieb na celkovom počte novopostavených domov z roka na rok stúpa. Napriek tomu, že komfort a benefity bývania v drevostavbách si užíva čoraz viac Slovákov, mýty o montovaných domoch nekončia.
                </strong>
            </p>
            <img src={img2} alt="Mýty o montovaných domoch – veríte im aj vy?"/>
            <p>
                A to ani napriek jasnému postoju odbornej verejnosti, ktorá stavebníkom odkazuje. Zabudnite na mýty, drevostavby sú a naďalej ostanú v kurze. Skeptikov, však ako sa zdá nedokáže presvedčiť nič a nikto. Naďalej tak hudú svoju pesničku a pri porovnaní s murovanými domami
                <strong>
                    {"\u00A0"}sú schopní uznať možno iba ak väčšiu podlahovú plochu montovaného domu pri rovnakej zastavanej ploche.{"\u00A0"}
                </strong>
                Niektorí idú ešte ďalej a pochvaľujú si krátky reakčný čas po zakúrení v montovanom dome na dosiahnutie príjemnej teploty. Tam to však pre nich celé končí a ďalej už nasledujú iba mýty.
            </p>
            <p>
                <strong>
                    Montovaný dom má nižšiu životnosť
                </strong>
            </p>
            <p>
                Tento mýtus je rozoberaný pri každej diskusii o murovaných a montovaných domoch. Chýbať teda nemôže ani v našom príspevku. Veľa stavebníkov má o životnosti montovaného domu ešte stále skreslené informácie, no pravdou je aj to, že neraz ide skôr o pretrvávajúce predsudky. Nech je to
                však už akokoľvek nič, to nemení na situácii, že montované domy disponujú porovnateľnou životnosťou ako murované.
            </p>
            <p>
                Všetko samozrejme závisí od miery starostlivosti o montovaný dom.
                <strong>
                    {"\u00A0"}Treba si však uvedomiť, že bez pravidelnej údržby sa nezaobídete ani pri murovanej stavbe a ak ju zanedbáte, môžete ju v priebehu niekoľkých rokov premeniť na chátrajúcu ruinu.{"\u00A0"}
                </strong>
                Pri zmienke o životnosti montovaných domov spomenieme, toľkokrát „omieľané“ drevokazné huby a červotoče. Áno môžu pre drevostavbu predstavovať nebezpečenstvo, ale iba pri neodbornej výstavbe realizovanej svojpomocou. Profesionálna výstavba zvládnutá do posledných detailov a kvalitné
                izolácie a konštrukčná ochrana dreva nepripúšťajú o životnosti montovaných domov žiadne pochybnosti.
            </p>
            <p>
                <strong>
                    Montované domy sú na Slovensku drahšie ako murované
                </strong>
            </p>
            <img src={img3} alt="Mýty o montovaných domoch – šetrenie"/>
            <p>
                Ani tento mýtus samozrejme nestojí na racionálnych základoch. V ponuke projektov nájdete montované domy s porovnateľnými rozmermi a dispozičným riešením, ktoré sa v ničom zásadnom nebudú odlišovať od murovaného domu. Vrátane ceny. Platí to samozrejme aj o projektoch domov, ktoré si
                dáte postaviť na kľúč.
                <strong>
                    {"\u00A0"}Skôr sa môže stať, že pri prechádzaní katalógov stavebných firiem budú ceny murovaných domov vyššie o 5 až 10 %,{"\u00A0"}
                </strong>
                takže reči o vyššej cene montovaných domov sú úplne mimo reality. Okrem toho netreba zabúdať na fakt, že o výške investície rozhodujete iba vy a vyberáte si takú ponuku, ktorá vyhovuje vám.
            </p>
            <p>
                Tip: Viete <a href="/clanky/ako-si-vybrat-spravny-projekt">ako si vybrať správny projekt ?</a>
            </p>
            <p>
                <strong>
                    V montovanom dome je všetko počuť
                </strong>
            </p>
            <p>
                Odmietavý postoj k drevostavbám živí aj mýtus o zlých zvukovo-izolačných vlastnostiach montovaného domu. Tu treba na rovinu povedať, že takýto problém by mohol síce reálne nastať, ale iba pri zlom technologickom postupe.
            </p>
            <img src={img1} alt="Mýty o montovaných domoch – veríte im aj vy?"/>
            <p>
                <strong>
                    Ak však bude konštrukcia stropu pri poschodových montovaných domoch dostatočne pevná, odpružená a budú v nej zakomponované akustické materiály pohlcujúce zvuk,{"\u00A0"}
                </strong>
                tak nemôže byť o rôznych divných zvukoch pri chôdzi ani reči. To isté platí aj o <a href="/clanky/skladby-stien">priečkach</a> montovaného domu. Ak sú v nich použité kvalitné akustické materiály pohlcujúce alebo odrážajúcu zvuk (na báze minerálnych alebo sklenených vlákien),
                zvukovo-izolačné
                vlastnosti priečok budú
                porovnateľné s murovanými.
            </p>
            <p>
                <strong>
                    Montovaný dom je pri požiari menej bezpečný
                </strong>
            </p>
            <p>
                Ide o pravdivé tvrdenie alebo iba o jeden z ďalších mýtov? Samozrejme, že druhá možnosť je správna. Hneď vám povieme aj prečo. Na Slovensku máme jedny z najprísnejších požiarnych noriem v Európe vôbec. No a týmto
                <strong>
                    {"\u00A0"}požiarnym normám musia vyhovovať montované domy, rovnako ako murované stavby.{"\u00A0"}
                </strong>
                Žiadne ústupky, žiadne rozdiely. Riziko požiaru v drevostavbe je navyše rovnaké ako pri inej stavbe.
            </p>
            <img src={img4} alt="Mýty o montovaných domoch – riziko požiaru"/>
            <p>
                O patričnej požiarnej odolnosti drevostavieb a ich bezpečnosti však najlepšie vypovedá zmena legislatívy, podľa ktorej
                <strong>
                    {"\u00A0"}sa od júla 2017 môžu na Slovensku stavať 5-podlažné drevostavby.{"\u00A0"}
                </strong>
                V praxi to znamená 12 metrovú výškovú úroveň podlahy posledného nadzemného podlažia. Samozrejme za predpokladu, že budú postavené z konštrukčných prvkov s chráneným drevom a nehorľavou, proti vysokým teplotám odolnou výplňou všetkých dutín (minerálnou vlnou).
            </p>
            <p>
                Kým nedošlo k úprave normy STN 92 0201-2, ktorá pojednáva o požiarnej bezpečnosti stavieb, bolo u nás možné stavať iba drevostavby s dvomi alebo maximálne tromi nadzemnými podlažiami. Čo teda z toho všetkého vyplýva? Nuž predovšetkým to, že ak by bezpečnosť majiteľov montovaných
                drevostavieb nebola pri požiari na požadovanej úrovni, nikdy by nedošlo k zmene legislatívy. Mýtus o minimálnej bezpečnosti drevostavieb pri požiari je teda mimo hry.
            </p>
        </article>
    )
}

export default Myty
