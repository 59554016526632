import classes from "./ExportCustomers.module.scss";
import Loading from "../../../../router/screens/loading/Loading";
import firebaseApp from "../../../../firebase";
import {setCms} from "../../../../redux/actions/cms";
import {CSVLink} from "react-csv";
import {connect} from "react-redux";
import {useState} from "react";
import {getFirestore, collection, getDocs, getDoc, doc} from "firebase/firestore";


function ExportCustomers({cms, setCms}) {
    const [loading, setLoading] = useState(false)

    function getSeparatorByOS(window) {
        return window.navigator.appVersion.indexOf("Mac") !== -1 ? ";" : ","
    }

    async function loadDatabase() {
        setLoading(true)
        const firestore = getFirestore(firebaseApp)
        const querySnapshot = await getDocs(collection(firestore, "cms"))

        let test = []
        for (const item of querySnapshot.docs) {
            const snapshot = await getDoc(doc(firestore, `cms/${item.id}`))
            test[item.id] = snapshot.data()
        }
        setCms({...test})
        setLoading(false)
    }

    return !loading ? (
        <div className={classes.export}>
            <button
                style={{display: Object.keys(cms).length > 0 ? "none" : "block"}}
                className={classes.reloadDatabase}
                onClick={loadDatabase}
            >
                Načítať všetkých klientov
            </button>

            <div className={classes.wrapper} style={{display: Object.keys(cms).length > 0 ? "block" : "none"}}>
                <CSVLink
                    data={Object.values(cms)}
                    filename="Drevostavby Bruno klienti"
                    separator={getSeparatorByOS(window)}
                >
                    <div className={classes.buttonSelected}>
                        <span>Stiahnuť klientov</span>
                    </div>
                </CSVLink>
            </div>
        </div>
    ) : <Loading/>
}

const mapStateToProps = state => {
    return {
        cms: state.cms
    }
}

export default connect(mapStateToProps, {setCms})(ExportCustomers)