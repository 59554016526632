import ReactGA from "react-ga";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "../cookies/Cookies";
import Main500 from "../router/screens/errorPages/500Main";
import Loading from "../router/screens/loading/Loading";
import firebaseApp from "../firebase";
import RouterNavigation from "../router/RouterNavigation";
import {connect} from "react-redux";
import {getAuth} from "firebase/auth";
import {setAdmin} from "../redux/actions/admin";
import {ToastContainer} from "react-toastify";
import {setDatabaseTexts} from "../redux/actions/databaseTexts";
import {ParallaxProvider} from "react-scroll-parallax";
import {useEffect, useState} from "react";
import {doc, collection, getDoc, getDocs, getFirestore, enableMultiTabIndexedDbPersistence} from "firebase/firestore";

const TRACKING_ID = "UA-116077545-1";
ReactGA.initialize(TRACKING_ID);

function App({databaseTexts, setDatabaseTexts, setAdmin}) {
    const [loading, setLoading] = useState(true)
    const [databaseDown, setDatabaseDown] = useState(false)
    const firestore = getFirestore(firebaseApp)

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [window]);

    useEffect(() => {
        getAuth(firebaseApp).onAuthStateChanged(async user => {
            if (user && user.uid) {
                setAdmin(true)
            } else {
                setAdmin(false)
            }
        })
    }, [])

    // useEffect(() => {
    //     try {
    //         enableMultiTabIndexedDbPersistence(firestore)
    //             .catch((err) => {
    //                 console.log(err)
    //             })
    //     } catch {
    //     }
    // }, [firestore])

    useEffect(() => {
        async function loadDatabase() {
            if (databaseTexts.texts) {
                setLoading(false)
            } else {
                await loadTexts()
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false))
            }
        }

        async function loadTexts() {
            let texts = {}
            const querySnapshot = await getDocs(collection(firestore, "texts"))

            for (const item of querySnapshot.docs) {
                const snapshot = await getDoc(doc(firestore, `texts/${item.id}`))
                texts[item.id] = snapshot.data()
            }
            setDatabaseTexts({...databaseTexts, texts})
        }

        async function healthCheck() {
            const firestore = getFirestore(firebaseApp)
            const querySnapshot = await getDocs(collection(firestore, "katalog"))
            if (querySnapshot.empty) setDatabaseDown(true)
        }

        healthCheck().catch()
        loadDatabase().catch()
    }, [databaseTexts, setDatabaseTexts, firestore])


    return (
        <ParallaxProvider>
            {/*<GoogleAds/>*/}
            {loading ? <Loading fullHeight/> : databaseDown ? <Main500/> : <RouterNavigation/>}
            <ToastContainer/>
            <Cookies/>
            {/*<GoUpButton/>*/}
        </ParallaxProvider>
    )
}

const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts
    }
}

export default connect(mapStateToProps, {setDatabaseTexts, setAdmin})(App)
