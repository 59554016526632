import classes from "./DialogWrapper.module.scss";
import Dialog from "@mui/material/Dialog";
import AddContact from "./cases/AddContact";
import SimpleText from "./cases/SimpleText";
import EditContact from "./cases/EditContact";
import RemoveContact from "./cases/RemoveContact";
import DialogContent from "@mui/material/DialogContent";
import LevelsTabText from "./cases/LevelsTabText";
import EditGalleryItemInfo from "./cases/EditGalleryItemInfo";
import ChangeThisFieldInGalleryItem from "./cases/ChangeThisFieldInGalleryItem";
import {connect} from "react-redux";


function DialogWrapper({open, handleClose, path, name, value, dialogCase, page}) {
    function handleEsc(e) {
        if (e.key === "Escape") {
            handleClose()
        }
    }

    function handleContent() {
        switch (dialogCase) {
            case "simpleText":
                return <SimpleText name={name} handleClose={handleClose}/>
            case "levelsTab":
                return <LevelsTabText value={value} handleClose={handleClose}/>
            case "galleryItemInfo":
                return <EditGalleryItemInfo page={page} value={value} handleClose={handleClose}/>
            case "changeThisItemOnly":
                return <ChangeThisFieldInGalleryItem path={path} value={value} page={page} handleClose={handleClose}/>
            case "contacts":
                return <EditContact value={value} handleClose={handleClose}/>
            case "addContact":
                return <AddContact handleClose={handleClose}/>
            case "removeContact":
                return <RemoveContact handleClose={handleClose}/>
            default:
                return null
        }
    }

    return open ?
        <Dialog
            open={open}
            fullWidth
            className={classes.dialogWrapper}
            onKeyUp={handleEsc}
            transitionDuration={1000}
        >
            <DialogContent className={classes.dialogContent}>
                {handleContent()}
            </DialogContent>
        </Dialog> : null

}


const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts
    }
}

export default connect(mapStateToProps)(DialogWrapper)