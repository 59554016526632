import classes from "./FullGallery.module.scss";
import Info2 from "../info2/Info2";
import DeleteItem from "../deleteItem/DeleteItem";
import firebaseApp from "../../../firebase";
import PriceOfferCTA from "../../priceOfferCTA/PriceOfferCTA";
import {Edit} from "tabler-icons-react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {setDatabaseRealizacie} from "../../../redux/actions/databaseRealizacie";
import {doc, getDoc, getFirestore} from "firebase/firestore";


function FullGalleryRealizacie({databaseRealizacie, setDatabaseRealizacie, realizacieRefs, admin,}) {
    let history = useHistory()
    const firestore = getFirestore(firebaseApp)
    const [count, setCount] = useState(0)

    const urlBase = "/realizacie"

    useEffect(() => {
        let itemToBeIntersected
        if (databaseRealizacie && databaseRealizacie.realizacie && Object.keys(databaseRealizacie.realizacie).length > 0) {
            itemToBeIntersected = Object.keys(databaseRealizacie.realizacie).reduce((a, b) => {
                return databaseRealizacie[a]?.timestamp > databaseRealizacie[b]?.timestamp ? a : b
            })

            const observer = new IntersectionObserver(onIntersect)
            document.querySelectorAll("[id]").forEach(function (item) {
                if (item.id === itemToBeIntersected) {
                    observer.observe(item)
                }
            })
        }

        function onIntersect(entries) {
            const toAdd = 6
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    const max = realizacieRefs && realizacieRefs.length
                    setCount(Math.min(Object.keys(databaseRealizacie.realizacie).length + toAdd, max))
                }
            })
        }
    }, [setCount, count, databaseRealizacie])

    useEffect(() => {
        async function loadDatabase() {
            if ((!databaseRealizacie.realizacie || Object.keys(databaseRealizacie?.realizacie).length < count)) {
                await loadRealizacie()
            }
        }

        async function loadRealizacie() {
            if ((count === 0 && !databaseRealizacie?.realizacie) || (count > 0 && databaseRealizacie && databaseRealizacie.realizacie && Object.keys(databaseRealizacie.realizacie).length > 0)) {

                let newCount
                if (databaseRealizacie && databaseRealizacie.realizacie) {
                    newCount = Object.keys(databaseRealizacie.realizacie).length
                } else {
                    newCount = 0
                }

                if (realizacieRefs && realizacieRefs.length > newCount) {
                    let realizacie = {}
                    for (const item of realizacieRefs.slice(newCount, newCount + 1)) {
                        const snapshot = await getDoc(doc(firestore, `realizacie/${item.id}`))
                        realizacie[item.id] = snapshot.data()
                    }

                    if (databaseRealizacie && databaseRealizacie.realizacie) {
                        setDatabaseRealizacie({...databaseRealizacie, realizacie: {...databaseRealizacie.realizacie, ...realizacie}})
                    } else {
                        setDatabaseRealizacie({realizacie: realizacie})
                    }
                }
            }
        }

        loadDatabase().catch()
    }, [firestore, count, databaseRealizacie, setDatabaseRealizacie, realizacieRefs])

    function handleRedirect(item) {
        if (typeof item === "string") {
            history.push(`${urlBase}/${item}`)
        } else {
            history.push(urlBase)
        }
    }

    return (
        <div className={classes.wrapper}>
            <PriceOfferCTA/>

            <div className={classes.fullGallery}>
                {databaseRealizacie?.realizacie && Object.keys(databaseRealizacie?.realizacie).sort(function (a, b) {
                    return databaseRealizacie?.realizacie[a]?.timestamp > databaseRealizacie?.realizacie[b]?.timestamp ? -1 : 1;
                }).map((item, index) => {
                    return (
                        <div key={index} className={classes.item} id={databaseRealizacie?.realizacie[item]?.name}>
                            <Edit style={{display: admin ? "block" : "none"}} onClick={() => history.push(`${urlBase}/${databaseRealizacie?.realizacie[item]?.name}`)} className={classes.editIcon}/>
                            <DeleteItem itemName={databaseRealizacie?.realizacie[item]?.name} page={urlBase.replace("/", "")}/>
                            <span onClick={() => handleRedirect(item)}>
                                <div className={classes.titleAndImg}>
                                     <h1>{databaseRealizacie?.realizacie[item]?.name}</h1>
                                     <div className={classes.imageWrapper} onClick={() => handleRedirect(item)}>
                                         <img src={databaseRealizacie?.realizacie[item]?.mainImage} alt={databaseRealizacie?.realizacie[item]?.name} loading="lazy" className={classes.oneImage}/>
                                         <div className={classes.test}>
                                            <div className={`${classes.title} ${classes.text}`}>
                                                {databaseRealizacie?.realizacie[item]?.info ?
                                                    <div className={classes.info}>
                                                        <Info2 itemInfo={databaseRealizacie?.realizacie[item]?.info}/>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        admin: state.admin,
        databaseRealizacie: state.databaseRealizacie,
        realizacieRefs: state.realizacieRefs,
    }
}

export default connect(mapStateToProps, {setDatabaseRealizacie})(FullGalleryRealizacie)
