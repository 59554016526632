import Footer from "../../components/main/footer/Footer";
import Header from "../../components/admin/header/Header";
import Components from "../../components/Components";
import PriceOfferCTA from "../../components/priceOfferCTA/PriceOfferCTA";
import FreeProjectOfferCTA from "../../components/freeProjectOfferCTA/FreeProjectOfferCTA";


function Home() {
    return (
        <span>
            <Header/>
            {/*<FreeProjectOfferCTA/>*/}
            <PriceOfferCTA/>
            <Components/>
            <Footer/>
        </span>
    )
}

export default Home
