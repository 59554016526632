export const CMS = "CMS"
export const ADMIN = "ADMIN"
export const COOKIES = "COOKIES"
export const CATALOG_REFS = "CATALOG_REFS"
export const DATABASE_TEXTS = "DATABASE_TEXTS"
export const REALIZACIE_REFS = "REALIZACIE_REFS"
export const DATABASE_CATALOG = "DATABASE_CATALOG"
export const DATABASE_REALIZACIE = "DATABASE_REALIZACIE"
export const DATABASE_REALIZUJEME = "DATABASE_REALIZUJEME"
export const PRICE_OFFER_SELECTED = "PRICE_OFFER_SELECTED"










