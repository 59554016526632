import classes from "./Articles.module.scss";
import img1 from "../../assets/tepelné-cerpadlo-1024x564.jpg";
import img2 from "../../assets/tepelné-cerpadlo-400x440.jpg";
import img3 from "../../assets/x-300x186.jpg";
import img4 from "../../assets/vodorovné-uloženie.jpg";
import img5 from "../../assets/Vzduch-Voda-1.jpg";
import {useEffect} from "react";


function TepelneCerpadlo() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Tepelné čerpadlo</title>
            <img src={img1} alt="Tepelné čerpadlo"/>
            <ul>
                <li>Tepelné čerpadlo je efektívne zariadenie pre získavanie tepelnej energie z obnoviteľných prírodných zdrojov – vody, zeme, vzduchu.</li>
                <li>Tepelné čerpadlo využíva nízkopotencialnu energiu zdroja a prevádza ju na využiteľnú tepelnú energiu.</li>
                <li>Získané teplo môže byť využité na ohrev TÚV, vykurovanie.</li>
                <li>Medzi hlavné výhody patri Ekológia tepelného čerpadla a Ekonomika prevádzky.</li>
            </ul>
            <img className={classes.img2} src={img2} alt="Tepelné čerpadlo - schéma"/>
            <h1>
                Princíp funkcie tepelného čerpadla
            </h1>
            <ul>
                <li>Tepelné čerpadlo využíva tepelnú energiu, ktorá je obsiahnutá v zemi, vo vode alebo vo vzduchu. Tepelná energia je v danej látke natoľko rozptýlená, že sa nedá použiť a v nijakom prípade nestačí na vykúrenie domu.</li>
                <li>Zjednodušene možno povedať, že tepelné čerpadlo odoberie teplo z veľkého množstva takejto látky, kompresorom ju „zahustí“ a zvýši jej teplotu na výšku potrebnú na kúrenie alebo ohrev vody</li>
                <li>Tepelné čerpadlo energiu nevyrába, ale „prečerpáva” (premieňa) na vyššiu teplotnú úroveň zväčša pomocou kompresoru poháňaného elektrickou energiou.</li>
            </ul>
            <h1>
                Schéma kompresorového systému tepelného čerpadla
            </h1>
            <p>
                <strong>TČ = TO + EL</strong>
            </p>
            <p>
                Energia potrebná pre vykurovanie, je súčtom energie z čerpacej studne a elektrickej energie
            </p>
            <ul className={classes.listStyleNone}>
                <li>EL – elektrická energia</li>
                <li>TO – Teplo odoberané z okolia</li>
                <li>TČ – tepelný výkon čerpadla;</li>
                <li>PL – pracovná látka v plyn. stave</li>
                <li>PLK – pracovná látka stlačená</li>
                <li>PK – pracovná látka v kvap. stave</li>
                <li>TV – voda na vykurovanie</li>
                <li>TH – zohriata voda</li>
                <li>KP – kompresor</li>
                <li>TEV – expanzný ventil</li>
                <li>VP – výparník</li>
                <li>KD – kondenzátor</li>
            </ul>
            <img className={classes.img2} src={img3} alt="Tepelné čerpadlo - schéma"/>
            <h1>
                Druhy tepelných čerpadiel
            </h1>
            <p>
                Tepelné čerpadlá delíme podľa zdroja tepla, ktoré využívajú a podľa vykurovacieho média. V praxi poznáme hlavne nasledovné kombinácie:
            </p>
            <p>
                <strong>Systém Zem a voda:</strong>
            </p>
            <ul>
                <li>Zdrojom nízkopotenciálnej energie je zem, z ktorej sa energia odoberá pomocou kolektoru z plastových hadíc</li>
                <li>Tento systém dosahuje vysokú účinnosť, vďaka relatívne vysokej primárnej teplote zdroja aj cez zimu</li>
                <li>Medzi nevýhody patria vysoké náklady na vrty alebo uloženie vodorovných trubiek</li>
                <li>Energiu zo zeme môžeme získavať z hĺbkových vrtov alebo vodorovne uložených trubiek</li>
            </ul>
            <img className={classes.img2} src={img4} alt="Systém Zem a voda"/>
            <p>
                <strong>Systém Vzduch a voda:</strong>
            </p>
            <ul>
                <li>Využívame teplo z okolitého vzduchu</li>
                <li>Výhodou je to že nieje potrebne robiť finančne nákladné vrty a studne</li>
                <li>Nevýhodou je nízka teplota vzduchu v zimnom období</li>
            </ul>
            <img className={classes.img2} src={img5} alt="Systém Vzduch a vod"/>
            <p>
                <strong>Systém Vzduch – Vzduch</strong>
            </p>
            <ul>
                <li>Klasické klimatizačné jednotky využívané bežne na chladenie priestoru. Doplnením systému je možné tieto zariadenia prevádzkovať v opačnom režime – na vykurovanie v zimnom období.</li>
                <li>Výhodou je možnosť s jedným systémom aj kúriť aj ochladzovať objekt.</li>
                <li>Sú väčšinou využívané v objektoch komerčného zamerania s veľkým objemom vzduchu.</li>
            </ul>
            <p>
                <strong>Systém Voda – Voda</strong>
            </p>
            <p>
                Voda vo všeobecnosti patri k najlepším zdrojom nízkoteplotnej energie
                Podľa druhu vody delíme :
            </p>
            <ul>
                <li>Použitie povrchovej vody – voda z jazier, nadrzi a potokou.</li>
                <li>Použitie podzemnej vody – najcastejdsie využívame princíp 2 studní – zdrojovej a vsakovacej</li>
            </ul>
            <h1>
                Systém dvoch studní
            </h1>
            <p>
                V blízkosti vykurovaného objektu sa vyhĺbia dve studne
            </p>
            <p>
                Jedna slúži na odber vody z ktorej získavame teplo (zdrojová studňa)
            </p>
            <p>
                Do druhej (vsakovacej studne) odvádzame ochladenú vodu z ktorej sme tepelným čerpadlom odobrali tepelnú energiu.
            </p>
            <p>
                Pre vykurovanie potrebujeme vhodnú výdatnosť, ta závisí od, zvodnenej hĺbky a druhu podložia.
            </p>
            <p>
                Odber zo studne sa určuje pomocou čerpacej skúšky
            </p>
            <ul className={classes.listStyleNone}>
                <li>Q – čerpané množstvo</li>
                <li>r0 – polomer studne [m]</li>
                <li>Y– pôvodná hrúbka zvodnenej vrstvy [m]</li>
                <li>z0 – zníženie na plášti studne [m]</li>
                <li>y0 – hĺbka v studni [m]</li>
                <li>y0 = Y – z0</li>
                <li>k – súčiniteľ filtrácie [m.s-1]</li>
                <li>R – dosah účinnosti [m]</li>
            </ul>
            <h1>
                Výpočty a vzťahy
            </h1>
            <strong>Výpočet čerpaného množstva Q</strong>
            <p>
                po dosadení som zistil výdatnosť 5,18 l/s čo je postačujúca hodnota pre vykúrenie
            </p>
            <p>
                Navrhujem jedno tepelne čerpadlo WAMAK TC WW 18 s tepelným výkonom 18,3 kW e=5,68
            </p>
            <p>
                Teplota vody v studni je 12 ˚C a vodu na vykurenie navrhujem 30 ˚C.
            </p>
            <p>
                <strong>Výpočet výkonu</strong>
            </p>
            <ul className={classes.listStyleNone}>
                <li>P – výkon [kW]</li>
                <li>m – hmotnosť [kg] alebo hmotný prietok [kg.s-1]</li>
                <li>c – merná tepelná kapacita [J.kg-1.K-1]</li>
                <li>T – teplota [K]</li>
            </ul>

            <p>
                <strong>Skutočný vykurovací faktor</strong>
            </p>

            <ul className={classes.listStyleNone}>
                <li>eSK – skut. vykurovací faktor</li>
                <li>POUT – celkový výkon</li>
                <li>PELČ – el. príkon čerpadla [W]</li>
                <li>PEL – elektrický príkon [W]</li>

                Po dosadený vychádza účinnosť tepelného čerpadla e = 4,2

                podľa vzťahu :
            </ul>

            <p>
                <strong>Voči použitiu elektrického vykurovania je úspora energie 76%</strong>
            </p>

            <p>
                <strong>Deväť výhod tepelného čerpadla</strong>
            </p>

            <p>
                Nezávislosť na cenách energií
            </p>
            <p>
                S tepelným čerpadlom budete nezávislí na cenách energií. Náklady na energie a palivo budú v budúcnosti neustále vzrastať podobným tempom ako v posledných rokov. Akékoľvek zvyšovanie cien sa prevádzky tepelného čerpadla dotkne iba okrajovo. Väčšinu tepelnej energie bude tepelné
                čerpadlo zdarma čerpať z okolia – zo zeme, z vody alebo vzduchu.
            </p>
            <p>
                Ekonomické vykurovanie domu
            </p>
            <p>
                Tepelné čerpadlo dokáže v mnohých prípadoch usporiť až neuveriteľných 80% nákladov za energie.
                Svojimi nízkymi prevádzkovými nákladmi prináša tepelné čerpadlo užívateľovi veľké úspory a tým zabezpečujú rýchlu návratnosť vynaloženej investície.
            </p>
            <p>
                Nízka sadzba za elektrinu pre celú domácnosť
            </p>
            <p>
                Každému, kto sa rozhodne pre tepelné čerpadlo, pridelia energetické spoločnosti veľmi výhodnú dvojtarifnú sadzbu dodávky elektrickej energie nielen pre vlastné vykurovanie, ale i pre celú domácnosť. Vďaka tomu výrazne znížite náklady nielen na vykurovanie, ale i na prevádzku
                všetkých
                elektrických spotrebičov.
            </p>
            <p>
                Krátka doba návratnosti investície
            </p>
            <p>
                Návratnosť investície do tepelného čerpadla je i bez akýchkoľvek dotácií a finančných podpôr od 3 do 8 rokov v porovnaní s najbežnejšími systémami vykurovania. Viac informácií – koľko konkrétne tepelné čerpadlo ušetrí, nájdete v kapitole Príklady úspor s tepelným čerpadlom.
            </p>
            <p>
                Dotácie
            </p>
            <p>
                Vďaka podpore investícií do tepelného čerpadla formou štátnych dotácií je v súčasnosti návratnosť prostriedkov na jeho nákup a inštaláciu ešte kratší.
            </p>
            <p>
                Ekologická prevádzka
            </p>
            <p>
                Nízka energetická náročnosť minimalizuje záťaž na životné prostredie . Väčšinu tepla na vykurovanie, chladenie alebo ohrev teplej vody získame prostredníctvom tepelného čerpadla priamo z nášho okolia a bez akýchkoľvek negatívnych dopadov na prírodu. Oproti konvenčnému spôsobu
                vykurovania podstatne znižuje exhalácie pevných častíc (popolčeka, prachu) a plynov s obsahom uhlíka (najmä CO2) do nášho ovzdušia. Využitie tepelných čerpadiel, v spojení s ekologicky šetrnou výrobou elektrickej energie z obnoviteľných zdrojov, predstavuje jednu z najúčinnejších
                metód boja proti globálnym zmenám klímy.
            </p>
            <p>
                Komfort
            </p>
            <p>
                Moderná technológia a regulácia poskytuje všetkým zákazníkom komfortnú prevádzku bez obsluhy, ktorá zabezpečí tepelnú pohodu v danom objekte. Tepelné čerpadlo môžete napr. ovládať prostredníctvom internetu. Pri prevádzke tepelného čerpadla nehrozí nebezpečenstvo výbuchu,
                vznietenia,
                ani otrava oxidom uhoľnatým. A čo by vás ďalej mohlo zaujímať? Napríklad – aký je princíp tepelného čerpadla, alebo aké sú úspory tepelného čerpadla.
            </p>
            <p>
                Lacná klimatizácia
            </p>
            <p>
                Všetky tepelné čerpadla značky MasterTherm môžu v letných mesiacoch plnohodnotne chladiť (klimatizovať) . Nákupom takéhoto zariadenia súčasne nadobúdate dokonalý zdroj chladu pre letné obdobie, pretože vďaka konštrukcii má v porovnaní s klasickou klimatizáciou približne polovičné
                prevádzkové náklady. Navyše i v režime chladenia môže súčasne ohrievať teplú vodu pre potreby domácnosti.
            </p>
            <p>
                Celoročné využitie
            </p>
            <p>
                Či už využijete alebo nevyužijete tepelné čerpadlo v letnom horúcom počasí pre chladenie, každopádne vám poskytne i mimo obdobia vykurovacej sezóny veľmi efektívny ohrev teplej úžitkovej vody alebo lacný, bezúdržbový ohrev vody vo vašom bazéne. Tepelné čerpadlá MasterTherm
                ovládajú a
                riadia vykurovací okruh napojený na solárne panely. V takomto systéme potom regulácia prednostne využíva slnečnú energiu a spína kompresor tepelného čerpadla len v prípade nedostatku slnečného osvitu.
            </p>
        </article>
    )
}

export default TepelneCerpadlo
