import classes from "./CustomizeCookies.module.scss";
import {connect} from "react-redux";
import {useState} from "react";
import {useCookies} from "react-cookie";
import {setCookiesScreen} from "../../redux/actions/cookies/cookies";
import {AD_COOKIES, ADFORM, CORE_COOKIES, FACEBOOK, FUNCTIONAL_COOKIES, GOOGLE_ANALYTICS, HOTJAR} from "../dependencies/Dependencies";


function CustomizeCookies({setCookiesScreen}) {
    const [tab, setTab] = useState(1)
    const [cookie, setCookie] = useCookies([])
    const [cookieValue, setCookieValue] = useState({
        google_analytics: true,
        adform: true,
        facebook: true,
        hotjar: true,

        core_cookies: true,
        functional_cookies: true,
        ad_cookies: true
    })

    const options = {path: "/", sameSite: "lax"}

    const recipients = [GOOGLE_ANALYTICS, ADFORM, FACEBOOK, HOTJAR]
    const recipientsTitle = ["Google analytics a Google ads", "Adform", "Facebook", "Hotjar"]
    const recipientsDescription = [
        "Ide o analytický nástroj na sledovanie správania používateľov na Webe a v aplikáciách. Zákazník je v Google Analytics vyhodnocovaný iba na základe Cookies. Drevostavby Bruno neposiela žiadne osobné údaje používateľov. Definícia ochrany dát a bezpečnosti sa nachádza na www.google.com",
        "Adform nástroj je určený pre zaznamenávanie a vyhodnocovanie správania zákazníkov na Webe. Adform vyhodnocuje iba na základe Cookies, Drevostavby Bruno neposiela žiadne ďalšie údaje zákazníkov. Definícia ochrany dát a bezpečnosti sa nachádza na www.adform.com",
        "Facebook na našich stránkach využívame nástroje Facebook Customer Audience na účely remarketingových kampaní Drevostavieb Bruno na Facebooku výhradne na základe Vášho súhlasu na spracúvanie osobných údajov na marketingové účely, ktorý ste Drevostavbám Bruno poskytli a ktorý môžete kedykoľvek odvolať podľa pokynov nižšie. Ďalej využívame Facebook pixel na optimalizáciu marketingových ponúk Drevostavieb Bruno na Facebooku, v prípade, že ste poskytli Facebooku relevantné súhlasy so spracúvaním cookies. Definícia ochrany dát a bezpečnosti sa nachádza na www.facebook.com",
        "HotJar nástroj pre sledovanie a vyhodnocovanie správania zákazníkov na Webe formou heatmap. HotJar vyhodnocuje iba na základe Cookies, Drevostavby Bruno neposiela žiadne ďalšie údaje zákazníkov. Definícia ochrany dát a bezpečnosti sa nachádza na www.hotjar.com"
    ]
    const recipientsDescriptionCZ = [
        "Jedná se o analyticky nástroj pro sledování chování uživatelů na Webu a v aplikacích. Zákazník je v Google Analytics vyhodnocován pouze na základě Cookies. Drevostavby Bruno neposílá žádné osobní údaje uživatelů. Definice ochrany dat a bezpečnosti se nachází na www.google.com",
        "Adform nástroj je určen pro zaznamenávání a vyhodnocování chování zákazníků na Webu. Adform vyhodnocuje pouze na základě Cookies, Drevostavyb Bruno neposílá žádné další údaje zákazníků. Definice ochrany dat a bezpečnosti se nachází na www.adform.com",
        "Na našich stránkách využíváme nástroje Facebook Customer Audience pro účely remarketingových kampaní na Facebooku výhradně na základě Vašeho souhlasu ke zpracování osobních údajů pro marketingové účely, který jste Drevostavbám Bruno poskytli a který můžete kdykoli odvolat podle pokynů níže. Dále využíváme Facebook pixel k optimalizaci marketingových nabídek Drevostavieb Bruno na Facebooku, v případě, že jste poskytli Facebooku relevantní souhlasy se zpracováním cookies. Definice ochrany dat a bezpečnosti se nachází na www.facebook.com",
        "HotJar nástroj pro sledování a vyhodnocování chování zákazníků na Webu formou heatmap. HotJar vyhodnocuje pouze na základě Cookies, Drevostavby Bruno neposílá žádné další údaje zákazníků. Definice ochrany dat a bezpečnosti se nachází na www.hotjar.com"
    ]

    const purposes = [CORE_COOKIES, FUNCTIONAL_COOKIES, AD_COOKIES]
    const purposesTitle = ["zakladné súbory cookies", "prevádzkové súbory cookies", "reklamné súbory cookies"]
    const purposesTitleCZ = ["Základní soubory cookies", "Provozní soubory cookies", "Reklamní soubory cookies"]

    const purposesDescription = [
        "Tieto súbory cookies umožňujú používanie základných funkcií ako napríklad prihlásenie registrovaného používateľa alebo predvypĺňanie formulárov a zapamätanie si vašich preferencií. Ak tieto cookies zakážete, nemôžeme garantovať plnú funkčnosť našich stránok.Tieto súbory cookies umožňujú používanie základných funkcií ako napríklad prihlásenie registrovaného používateľa alebo predvypĺňanie formulárov a zapamätanie si vašich preferencií. Ak tieto cookies zakážete, nemôžeme garantovať plnú funkčnosť našich stránok.",
        "Tieto cookies slúžia na zaznamenávanie a analýzu správania návštevníka na webovej stránke a následne na zlepšovanie jej funkčnosti a vzhľadu. Ak tieto cookies zakážete, nemôžeme garantovať plnú funkčnosť našich stránok.",
        "Slúžia na optimalizáciu zobrazovaného obsahu a reklamy vzhľadom na zvyklosti návštevníka a efektívnosť marketingovej komunikácie zadávateľov. Vďaka nim sa vám napríklad nebude zbytočne často zobrazovať reklama z oblasti, o ktorú nemáte záujem."
    ]
    const purposesDescriptionCZ = [
        "Tyto soubory cookies umožňují používání základních funkcí jako například přihlášení registrovaného uživatele nebo předvyplňování formulářů a zapamatování si vašich preferencí. Pokud tyto cookies zakážete, nemůžeme garantovat plnou funkčnost našich stránek. Tyto soubory cookies umožňují používání základních funkcí jako například přihlášení registrovaného uživatele nebo předvyplňování formulářů a zapamatování si vašich preferencí. Pokud tyto cookies zakážete, nemůžeme garantovat plnou funkčnost našich stránek.",
        "Tyto cookies slouží k zaznamenávání a analýze chování návštěvníka na webové stránce a následně ke zlepšování její funkčnosti a vzhledu. Pokud tyto cookies zakážete, nemůžeme garantovat plnou funkčnost našich stránek.",
        "Slouží k optimalizaci zobrazovaného obsahu a reklamy s ohledem na zvyklosti návštěvníka a efektivnost marketingové komunikace zadavatelů. Díky nim se vám například nebude zbytečně často zobrazovat reklama z oblasti, o kterou nemáte zájem."
    ]

    function submit() {
        setCookiesScreen("closed")
        purposes.forEach(purpose => {
            setCookie(purpose, cookieValue[purpose], options)
        })
        recipients.forEach(recipient => {
            setCookie(recipient, cookieValue[recipient], options)
        })
    }

    function handleCheckbox(e) {
        const value = e.target.name
        setCookieValue({...cookieValue, [value]: !cookieValue[value]})
    }

    function acceptAll() {
        setCookie(GOOGLE_ANALYTICS, true, options)
        setCookie(ADFORM, true, options)
        setCookie(FACEBOOK, true, options)
        setCookie(HOTJAR, true, options)

        setCookie(CORE_COOKIES, true, options)
        setCookie(FUNCTIONAL_COOKIES, true, options)
        setCookie(AD_COOKIES, true, options)
        setCookiesScreen("closed")
    }

    return (
        <article className={classes.customizeCookies}>
            <h1>Nastavenie cookies</h1>
            <h2>Aké cookies vyhodnocujeme?</h2>
            <div className={classes.buttons}>
                <button onClick={acceptAll}>
                    Akceptovať všetky cookies
                </button>
                <button onClick={() => setTab(1)} className={tab === 1 ? classes.selected : classes.notSelected}>
                    {window.location.host.split(".").pop() !== "cz" ? "Zoznam účelov" : "Seznam účelů"}
                </button>
                <button onClick={() => setTab(2)} className={tab === 2 ? classes.selected : classes.notSelected}>
                    {window.location.host.split(".").pop() !== "cz" ? "Zoznam príjemcov" : "Seznam příjemců"}
                </button>
            </div>


            {tab === 1 ?
                <ul>
                    {purposes.map((purpose, index) => {
                        return (
                            <li className={classes.item} key={purpose}>
                                <title>{(window.location.host.split(".").pop() !== "cz" ? purposesTitle : purposesTitleCZ)[index]}</title>
                                <section>
                                    <p>{(window.location.host.split(".").pop() !== "cz" ? purposesDescription : purposesDescriptionCZ)[index]}</p>
                                    <label className={classes.container}>
                                        <input
                                            type="checkbox"
                                            name={purpose}
                                            checked={cookieValue[purpose]}
                                            value={cookieValue[purpose]}
                                            onChange={handleCheckbox}/>
                                        <span className={classes.checkmark}/>
                                    </label>
                                </section>
                            </li>
                        )
                    })}
                </ul> :

                <ul>
                    {recipients.map((recipient, index) => {
                        return (
                            <li className={classes.item} key={recipient}>
                                <title>{recipientsTitle[index]}</title>
                                <section>
                                    <p>{(window.location.host.split(".").pop() !== "cz" ? recipientsDescription : recipientsDescriptionCZ)[index]}</p>
                                    <label className={classes.container}>
                                        <input
                                            type="checkbox"
                                            name={recipient}
                                            checked={cookieValue[recipient]}
                                            value={cookieValue[recipient]}
                                            onChange={handleCheckbox}/>
                                        <span className={classes.checkmark}/>
                                    </label>
                                </section>
                            </li>
                        )
                    })}
                </ul>
            }

            <div className={classes.submit}>
                <button onClick={submit}>uložiť nastavenia cookies</button>
            </div>
        </article>
    )
}

export default connect(null, {setCookiesScreen})(CustomizeCookies)