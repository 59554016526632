import classes from "./Articles.module.scss";
import img1 from "../../assets/stavebne-povolenie-drevostavby-bruno-768x336.jpg";
import {useEffect} from "react";


function Kolaudacia() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Čo všetko je potrebné ku kolaudácii</title>
            <img src={img1} alt="Čo všetko je potrebné ku kolaudácii"/>
            <p>
                <strong>
                    Sotva stihol uschnúť atrament na vydanom stavebnom povolení a práce na výstavbe vášho montovaného domu pokročili natoľko, že je najvyšší čas pomýšľať na jeho kolaudáciu. Ak vám tento schvaľovací proces príliš veľa nehovorí, možno vám prídu vhod niektoré základné informácie, ktoré
                    vám napovedia, čo všetko môžete od kolaudácie očakávať.
                </strong>
            </p>
            <p>
                Kým sa však dostaneme k podstate veci nemôžeme obísť niekoľko, pre niekoho možno suchých a nezáživných faktov, ktoré sú ale dôležité a je vhodné spomenúť ich hneď v úvode nášho článku.
            </p>
            <h1>
                Čo je kolaudácia
            </h1>
            <p>
                Kolaudácia je vo všeobecnosti
                <strong>
                    {"\u00A0"}konanie stavebného úradu pri ktorom sa rozhoduje, či je možné stavbu užívať.{"\u00A0"}
                </strong>
                Kolaudačným konaním sa zároveň zabezpečuje ochrana verejných záujmov,či práv účastníkov konania a to predovšetkým z pohľadu starostlivosti o životné prostredie, bezpečnosti i ochrany zdravia budúcich užívateľov stavby a v neposlednom rade aj z hľadiska požiarnej ochrany.
            </p>
            <p>
                <strong>
                    Bez kolaudácie, resp. kolaudačného rozhodnutia nie je možné stavbu na ktorú bolo vydané stavebné povolenie užívať.{"\u00A0"}
                </strong>
                Jedinú výnimku tvoria niektoré drobné stavby, nepodstatné stavebné úpravy a udržiavacie práce pri ktorých môže stavebný úrad od vykonania kolaudácie upustiť.
            </p>
            <p>
                No a keďže
                <strong>
                    {"\u00A0"}pri stavbách určených na bývanie alebo rekreačné účely sa kolaudácia vyžaduje vždy{"\u00A0"}
                </strong>
                , nevyhnete sa jej pri uvádzaní montovaného domu do života ani vy. Kolaudačné rozhodnutie vydáva príslušný stavebný úrad, ktorý vydal stavebné povolenie. Nepredbiehajme však a poďme pekne poporiadku.
            </p>
            <h1>
                Kedy požiadať o kolaudáciu montovaného domu
            </h1>
            <p>
                O vydanie kolaudačného rozhodnutia samozrejme nemá zmysel žiadať v štádiu rozostavaného alebo nedokončeného domu. Aby ste mohli <a href="/katalog">montovaný dom</a> úspešne uviesť do užívania bude potrebné aby boli na ňom dotiahnuté aj také detaily, ktoré síce zdanlivo neovplyvňujú
                prevádzku domu, no z pohľadu stavebného úradu môžu byť prekážkou skolaudovania domu.
            </p>
            <p>
                Popri funkčných prípojkách inžinierskych sietí týkajúcich sa vody, kanalizácie, elektrickej energie plynu, či kompletnom namontovaní sanity, nezabudnite ani na inštaláciu odkvapového systému strechy alebo na dokončenie schodísk a zábradlí.
                <strong>
                    {"\u00A0"}V princípe tak platí, že o kolaudáciu bude najlepšie požiadať až po prevzatí montovaného domu od stavebnej firmy.
                </strong>
            </p>
            <h1>
                Priebeh kolaudačného konania
            </h1>
            <p>
                Konanie začína prijatím žiadosti alebo návrhu stavebníka na vydanie kolaudačného rozhodnutia. Súčasťou žiadosti sú zvyčajne tieto prílohy:
            </p>
            <ul>
                <li>Zakreslené zmeny uskutočnených odchýlok od územného rozhodnutia a stavebného povolenia.</li>
                <li>Predpísané skúšky a revízie od elektroinštalácie, plynoinštalácie, hromozvodov, komínov, vodoinštalácie, kanalizácie a doklad o nepriepustnosti žumpy.</li>
                <li>Geodetické zameranie skutočného uskutočnenia stavieb inžinierskych sietí.</li>
                <li>Geometrický plán podľa predpisov o katastri na vyznačenie zmien v katastri nehnuteľností ( tieto doklady sa neprikladajú v prípadoch, kedy nedochádza k zmene vonkajšieho pôdorysného ohraničenia stavby a u drobných stavieb).</li>
                <li>Stavebný denník.</li>
                <li>Podľa § 140b ods. 1 stavebného zákona záväzné stanoviská dotknutých orgánov, napr. súhlas formou záväzného stanoviska na uvedenie malých zdrojov znečisťovania do užívania od obce.</li>
                <li>Doklad o zakúpení nádoby na domový odpad a doklad o likvidácii stavebného odpadu (odpad vznikajúci počas výstavby).</li>
                <li>Certifikát o energetickej hospodárnosti stavby.</li>
                <li>Záručný list od vykurovacieho kotla.</li>
                <li>Zmluvy o pripojení inžinierskych sietí (plyn, voda, elektrika).</li>
                <li>Kópia stavebného povolenia.</li>
                <li>List vlastníctva.</li>
            </ul>
            <p>
                <strong>
                    Na priložení všetkých požadovaných dokladov si dajte skutočne záležať.{"\u00A0"}
                </strong>
                Predídete tým zbytočným výzvam na doplnenie a v konečnom dôsledku urýchlite vydanie kolaudačného rozhodnutia.
            </p>
            <p>
                Po predložení všetkých požadovaných dokladov bude na ťahu stavebný úrad. Ten oboznámi všetkých účastníkov konania (stavebníka, obec, poprípade orgány, ktorých sa tento proces týka) o začatí kolaudačného konania.
                <strong>
                    {"\u00A0"}Stavebný úrad tak musí urobiť v lehote najmenej 10 dní pred ústnym pojednávaním spojeným s miestnym zisťovaním.
                </strong>
            </p>
            <p>
                <strong>
                    Miestne zisťovanie sa vykonáva priamo na stavbe.{"\u00A0"}
                </strong>
                Kolaudačná komisia preveruje nielen to, či bola pri výstavbe domu dodržaná projektová dokumentácia, ale zameriava sa aj na kontrolu dodržiavania podmienok uvedených v územnom alebo stavebnom povolení. V rámci ústneho pojednávania dostanú všetci účastníci konania možnosť prípadného
                pripomienkovania.
            </p>
            <h1>
                Vydanie rozhodnutia
            </h1>
            <p>
                Ak kolaudačná komisia počas miestneho zisťovania nezistila žiadne závažnejšie nedostatky týkajúce sa dodržania vyššie uvedených podmienok a nemá ani iné podstatné výhrady, tak v zákonom stanovenej lehote vydá kolaudačné rozhodnutie. V rozhodnutí bude uvedený účel na ktorý je možné
                stavbu využívať a podmienky bezpečného užívania. Súčasťou rozhodnutia môžu byť aj zistené drobnejšie nedostatky, ktoré je potrebné odstrániť.
            </p>
            <h1>
                Kam budú smerovať vaše kroky po kolaudácii
            </h1>
            <p>
                Vydanie kolaudačného rozhodnutia pre vás neznamená koniec, nazvime to „papierovania“ a budete si to musieť ešte zamieriť na obecný úrad.
                <strong>
                    {"\u00A0"}Obecný úrad váš dom zaeviduje a pridelí mu súpisné číslo.
                </strong>
            </p>
            <p>
                Okrem toho bude potrebné zájsť aj na
                <strong>
                    {"\u00A0"}kataster nehnuteľností, kde vám novostavbu montovaného domu zapíšu na základe geometrického plánu, súpisného čísla a kolaudačného rozhodnutia.{"\u00A0"}
                </strong>
                Pre zápis montovaného domu na list vlastníctva si pripravte originály alebo overené kópie spomínaných dokladov. Na liste vlastníctva bude ako vlastník domu zapísaná osoba (alebo aj osoby, napr. v prípade manželov), ktorá je uvedená v kolaudačnom rozhodnutí ako stavebník.
            </p>
        </article>
    )
}

export default Kolaudacia
