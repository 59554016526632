import Header from "../../components/admin/header/Header";
import Navbar from "../../components/navbar/Navbar";
import GalleryPage from "../../components/gallery/GalleryPage";


function Catalog() {
    return (
        <span>
            <Header/>
            <Navbar/>
            <GalleryPage url="/katalog" path="katalog"/>
        </span>
    )
}

export default Catalog
