import {DATABASE_REALIZACIE} from "../types";


const DatabaseRealizacieReducer = (state = [], action) => {
    switch (action.type) {
        case DATABASE_REALIZACIE:
            return action.payload
        default:
            return state
    }
}

export default DatabaseRealizacieReducer





