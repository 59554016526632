import classes from "../gallery/GalleryPage.module.scss";
import Loading from "../../router/screens/loading/Loading";
import firebaseApp from "../../firebase";
import FullGallery from "./FullGallery";
import SelectedGallery from "./SelectedGallery";
import {connect} from "react-redux";
import {useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import {setRealizujeme} from "../../redux/actions/realizujeme";
import {collection, doc, getDoc, getDocs, getFirestore, orderBy, query} from "firebase/firestore";


function GalleryPage({realizujeme, setRealizujeme}) {
    const firestore = getFirestore(firebaseApp)

    useEffect(() => {
        async function loadRealizacie() {
            let collectionRealizacie
            const querySnapshot = await getDocs(query(collection(firestore, "realizujeme"), orderBy("timestamp", "desc")))
            collectionRealizacie = querySnapshot.docs
            let result = {}
            for (const item of collectionRealizacie) {
                const snapshot = await getDoc(doc(firestore, `realizujeme/${item.id}`))
                result[item.id] = snapshot.data()
            }
            setRealizujeme(result)
        }

        if (realizujeme && Object.keys(realizujeme).length === 0) {
            loadRealizacie().catch()
        }
    }, [])

    if (realizujeme && Object.keys(realizujeme).length > 0) {
        return (
            <div className={classes.gallery}>
                <article className={classes.galleryItem}>
                    <Switch>
                        <Route exact path="/realizujeme" component={() => <FullGallery data={realizujeme}/>}/>
                        {(realizujeme ? Object.keys(realizujeme) : []).map((item) => {
                                return <Route key={item} exact path={`/realizujeme/${item}`} component={() => <SelectedGallery allData={realizujeme} item={item}/>}/>
                            }
                        )}
                    </Switch>
                </article>
            </div>
        )
    } else return <Loading/>
}


const mapStateToProps = state => {
    return {
        realizujeme: state.realizujeme
    }
}

export default connect(mapStateToProps, {setRealizujeme})(GalleryPage)
