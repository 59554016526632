import classes from "./GalleryPage.module.scss";
import Loading from "../../router/screens/loading/Loading";
import Gallery404 from "../../router/screens/errorPages/404Gallery";
import firebaseApp from "../../firebase";
import SelectedGallery from "./selectedGallery/SelectedGallery";
import FullGalleryCatalog from "./fullGallery/FullGalleryCatalog";
import FullGalleryRealizacie from "./fullGallery/FullGalleryRealizacie";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {setCatalogRefs, setRealizacieRefs} from "../../redux/actions/refs";
import {collection, doc, getDoc, getDocs, getFirestore, orderBy, query} from "firebase/firestore";


function GalleryPage({databaseCatalog, databaseRealizacie, path, url, catalogRefs, realizacieRefs, setCatalogRefs, setRealizacieRefs}) {
    let history = useHistory()
    const firestore = getFirestore(firebaseApp)

    const [thisItem, setThisItem] = useState({})

    useEffect(() => {
        async function loadDatabase() {
            if (path === "realizacie" && history.location.pathname.split("/").pop() !== "realizacie" && !databaseRealizacie.realizacie) {
                await loadRealizacie()
            } else if (path === "katalog" && history.location.pathname.split("/").pop() !== "katalog" && !databaseCatalog.catalog) {
                await loadCatalog()
            }
        }

        async function loadCatalog() {
            let catalog
            const snapshot = await getDoc(doc(firestore, `katalog/${history.location.pathname.split("/").pop()}`))
            catalog = snapshot.data()
            setThisItem({[catalog?.name]: catalog})
        }

        async function loadRealizacie() {
            let realizacie
            const snapshot = await getDoc(doc(firestore, `realizacie/${history.location.pathname.split("/").pop()}`))
            realizacie = snapshot.data()
            setThisItem({[realizacie?.name]: realizacie})
        }

        loadDatabase().catch()
    }, [databaseRealizacie, databaseCatalog, firestore, history?.location?.pathname, path])


    useEffect(() => {
        async function loadDatabase() {
            if (path === "realizacie" && history.location.pathname.split("/").pop() === "realizacie") {
                if (!realizacieRefs || (realizacieRefs && Object.keys(realizacieRefs).length === 0)) {
                    await loadRealizacie()
                }
            } else if (path === "katalog" && history.location.pathname.split("/").pop() === "katalog") {
                if (!catalogRefs || (catalogRefs && Object.keys(catalogRefs).length === 0)) {
                    await loadCatalog()
                }
            }
        }

        async function loadCatalog() {
            let collectionCatalog

            const querySnapshot = await getDocs(query(collection(firestore, "katalog"), orderBy("timestamp", "desc")))
            collectionCatalog = querySnapshot.docs

            setCatalogRefs(collectionCatalog)
        }

        async function loadRealizacie() {
            let collectionRealizacie

            const querySnapshot = await getDocs(query(collection(firestore, "realizacie"), orderBy("timestamp", "desc")))
            collectionRealizacie = querySnapshot.docs

            setRealizacieRefs(collectionRealizacie)
        }

        loadDatabase().catch()
    }, [])

    function katalog111() {
        if (path === "katalog") {
            return (
                <div className={classes.gallery}>
                    <article className={classes.galleryItem}>
                        <Switch>
                            <Route exact path={`${url}`} component={() => <FullGalleryCatalog urlBase={url}/>}/>
                            {(databaseCatalog?.catalog ? Object.keys(databaseCatalog?.catalog) : []).map((item) => {
                                    return <Route key={item} exact path={`${url}/${item}`} component={() => <SelectedGallery item={item} urlBase={url}/>}/>
                                }
                            )}
                            {(thisItem ? (Object.keys(thisItem)) : []).map((item) => {
                                    return <Route key={item} exact path={`${url}/${item}`} component={() => <SelectedGallery thisItem={thisItem} item={item} urlBase={url}/>}/>
                                }
                            )}
                            <Route component={() => <Gallery404 page={url.replace("/", "")}/>}/>
                        </Switch>
                    </article>
                </div>
            )
        } else return <Loading/>
    }

    function realizacie111() {
        if (path === "realizacie") {
            return (
                <div className={classes.gallery}>
                    <article className={classes.galleryItem}>
                        <Switch>
                            <Route exact path={`${url}`} component={() => <FullGalleryRealizacie urlBase={url}/>}/>
                            {(databaseRealizacie?.realizacie ? Object.keys(databaseRealizacie?.realizacie) : []).map((item) => {
                                    return <Route key={item} exact path={`${url}/${item}`} component={() => <SelectedGallery item={item} urlBase={url}/>}/>
                                }
                            )}
                            {(thisItem ? (Object.keys(thisItem)) : []).map((item) => {
                                    return <Route key={item} exact path={`${url}/${item}`} component={() => <SelectedGallery thisItem={thisItem} item={item} urlBase={url}/>}/>
                                }
                            )}
                            <Route component={() => <Gallery404 page={url.replace("/", "")}/>}/>
                        </Switch>
                    </article>
                </div>
            )
        } else return <Loading/>
    }

    return path === "katalog" ? katalog111() : realizacie111()
}

const mapStateToProps = state => {
    return {
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie,
        catalogRefs: state.catalogRefs,
        realizacieRefs: state.realizacieRefs,
    }
}

export default connect(mapStateToProps, {setCatalogRefs, setRealizacieRefs})(GalleryPage)
