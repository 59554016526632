import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {useState} from "react";
import {setDatabaseTexts} from "../../../redux/actions/databaseTexts";
import {doc, getFirestore, setDoc} from "firebase/firestore";


function EditContact({databaseContactsTexts, databaseTexts, setDatabaseTexts, value, handleClose}) {
    const [state, setState] = useState(databaseContactsTexts.people[value] ? {...databaseContactsTexts.people[value]} : null)

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "texts", "contacts"), {...databaseContactsTexts, people: {...databaseContactsTexts.people, [value]: state}}, {merge: true})
            .then(() => {
                setDatabaseTexts({...databaseTexts, texts: {...databaseTexts.texts, contacts: {...databaseTexts.texts.contacts, people: {...databaseContactsTexts.people, [value]: state}}}})
            })
        setState({...databaseContactsTexts.people[value]})
        handleClose()
    }

    function handleObjectChange(newValue, key) {
        setState({...state, [key]: newValue})
    }

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    return (
        <div>
            <h3>{databaseContactsTexts.people[value].name}</h3>
            {Object.keys(databaseContactsTexts.people[value]).map((item, index) => {
                return (
                    <div key={index} className={classes.contacts}>
                        <h3>{item}</h3>
                        {item === "location" ?<p>{"(sk, cz, all)"}</p> : null}
                        <input
                            className={classes.textareaContacts}
                            placeholder={`Zadaj ${item}`}
                            defaultValue={databaseContactsTexts.people[value][item]}
                            onChange={(e) => handleObjectChange(e.target.value, item)}
                        />
                    </div>
                )
            })}
            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts,
        databaseContactsTexts: state.databaseTexts?.texts?.contacts
    }
}

export default connect(mapStateToProps, {setDatabaseTexts})(EditContact)