import classes from "./Articles.module.scss";
import img1 from "../../assets/skladby-stien-1024x564.jpg";
import img2 from "../../assets/polystyren-obvodova-stena.jpg";
import img3 from "../../assets/priecky.jpg";
import img4 from "../../assets/podlahatext-120-1024x308.jpg";
import img5 from "../../assets/stropN-1-1024x345.png";
import {useEffect} from "react";


function SkladbyStien() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>
                {window.location.host.split(".").pop() !== "cz" ? "Skladby stien" : "Skladby stěn"}
            </title>
            <img src={img1} alt="Skladby stien"/>
            <h1>
                {window.location.host.split(".").pop() !== "cz" ? "OBVODOVÁ STENA" : "OBVODOVÁ STĚNA"}
            </h1>
            <img src={img2} alt="OBVODOVÁ STENA"/>
            <h1>
                {window.location.host.split(".").pop() !== "cz" ? "PRIEČKA" : "PŘÍČKA"}
            </h1>
            <img src={img3} alt="PRIEČKA"/>
            <h1>
                {window.location.host.split(".").pop() !== "cz" ? "PODLAHA" : "PODLAHA"}
            </h1>
            <img src={img4} alt="PODLAHA"/>
            <h1>
                {window.location.host.split(".").pop() !== "cz" ? "STROP" : "STROP"}
            </h1>
            <img src={img5} alt="STROP"/>
        </article>
    )
}

export default SkladbyStien
