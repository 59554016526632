import {CMS} from "../types";


const CmsReducer = (state = {}, action) => {
    switch (action.type) {
        case CMS:
            return action.payload
        default:
            return state
    }
}

export default CmsReducer





