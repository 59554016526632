import classes from "./HomeImg.module.scss";
import img from "../../../assets/1_x.jpg";


function Home() {
    return (
        <div className={classes.wrapper}>
            <img src={img} alt="home-1"/>
        </div>

    )
}

export default Home


