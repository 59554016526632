import classes from "./ErrorPage.module.scss";
import logo from "../../../assets/logoFull.png";


function Main500() {
    return (
        <div className={classes.page}>
            <img src={logo} alt="logo"/>
            <p>V záujme poskytovania čo najlepších služieb práve pracujeme na vylepšení našej stránky.</p>
            <p>Lepšia verzia našej stránky bude dostupná každú chvíľu.</p>
            <p>Ďakujeme za pochopenie.</p>
        </div>
    )
}

export default Main500