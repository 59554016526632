import classes from "./CurrencyTab.module.scss";
import * as xlsx from "xlsx";
import Loading from "../../../../router/screens/loading/Loading";
import Dropzone from "react-dropzone";
import firebaseApp from "../../../../firebase";
import dragAndDrop from "../../../../assets/dragAndDrop.png";
import fileToArrayBuffer from "file-to-array-buffer";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {setDatabaseCatalog} from "../../../../redux/actions/databaseCatalog";
import {useEffect, useState} from "react";
import {collection, doc, getDoc, getDocs, getFirestore, setDoc} from "firebase/firestore";


function CurrencyTab({databaseCatalog, setDatabaseCatalog}) {
    const [excel, setExcel] = useState({json: [], name: ""})
    const [loading, setLoading] = useState(true)
    const firestore = getFirestore(firebaseApp)


    useEffect(() => {
        async function loadDatabase() {
            if (databaseCatalog.catalog && Object.keys(databaseCatalog?.catalog).length > 0) {
                setLoading(false)
            } else {
                if (!databaseCatalog.catalog) {
                    await loadCatalog()
                        .then(() => setLoading(false))
                        .catch(() => setLoading(false))
                }
            }
        }

        async function loadCatalog() {
            let catalog = {}

            const querySnapshot = await getDocs(collection(firestore, "katalog"))
            for (const item of querySnapshot.docs) {
                const snapshot = await getDoc(doc(firestore, `katalog/${item.id}`))
                catalog[item.id] = snapshot.data()
            }
            setDatabaseCatalog({...databaseCatalog, catalog})
        }

        loadDatabase().catch()
    }, [databaseCatalog, firestore, setDatabaseCatalog])

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        for (const item of excel.json) {
            await setDoc(doc(firestore, "katalog", item["Dom"]),
                {
                    ...databaseCatalog?.catalog[item["Dom"]], info: {
                        ...databaseCatalog?.catalog[item["Dom"]]?.info,
                        "holodom": item["Holodom"],
                        "hrubá stavba": item["Hrubá Stavba"],
                        "dom na kľúč": item["Na Kľúč"],
                        "základová doska": item["Základová doska"],
                        "zastavaná plocha": item["Zastavaná plocha"],
                        "úžitková plocha": item["Úžitková plocha"],
                        "obytná plocha": item["Obytná plocha"],
                        "rozmer domu": item["Rozmer domu"],
                        "počet izieb": item["Počet izieb"],
                        "holodom.CZ": item["Holodom.CZ"],
                        "hrubá stavba.CZ": item["Hrubá Stavba.CZ"],
                        "dom na kľúč.CZ": item["Na Kľúč.CZ"],
                        "základová doska.CZ": item["Základová doska.CZ"]
                    }
                }, {merge: true})

        }
        setExcel({json: [], name: ""})
    }

    function handleExcel(file) {
        fileToArrayBuffer(file[0]).then((data) => {
            const workbook = xlsx.read(data, {type: "array"})
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)
            setExcel({json: json, name: file[0].name})
        })
    }

    return !loading ? (
        <div className={classes.currency}>
            <h3>Potiahni excel súbor na prepočet cien v celom katalógu</h3>
            <p>Excel súbor <strong>musí</strong> byť v správnom tvare</p>
            <Dropzone
                multiple={false}
                onDropAccepted={handleExcel}
                accept="text/csv"
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        {excel.json.length > 0 ?
                            <h1>Pocet riadkov: {excel.json.length}</h1> :
                            <img src={dragAndDrop} alt="dragAndDrop" className={classes.dragAndDrop}/>
                        }
                    </div>
                )}
            </Dropzone>

            <button onClick={submitWithToast}>Uploadnúť</button>
        </div>
    ) : <Loading/>
}

const mapStateToProps = state => {
    return {
        databaseCatalog: state.databaseCatalog
    }
}

export default connect(mapStateToProps, {setDatabaseCatalog})(CurrencyTab)