import classes from "./Articles.module.scss";
import img1 from "../../assets/ventsip.jpg";
import img2 from "../../assets/rekuperácia-vzduchu-drevostavby-bruno.jpg";
import {useEffect} from "react";


function RekuperaciaVzduchu() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Rekuperácia vzduchu</title>
            <img src={img1} alt="Rekuperácia vzduchu"/>
            <p>
                K zdravému bývaniu taktiež prispieva automatická výmena vzduchu (rekuperácia). Rekuperácia odvádza skazený vzduch z interiéru a privádza čerstvý z exteriéru pričom sa ohreje.
                Rekuperácia naviac pracuje aj v letnom období rovnako účinne a zabezpečuje prívod chladného vzduchu.
                Systém je vybavený špeciálnou účinnou filtráciou, ktorá dokáže zachytiť prach, nečistoty a peľ, ktoré by do interiéru mohli vniknúť oknami, preto je vhodný pre alergikov.
            </p>
            <h1>
                VÝHODY REKUPERÁCIE:
            </h1>
            <ul>
                <li>Zabezpečuje prívod čerstvého vzduchu optimálnej teploty a vlhkosti</li>
                <li>Pri pravidelnom vetraní šetrí náklady na energiu</li>
                <li>Predchádza kondenzácii vodných pár a eliminuje plesne</li>
                <li>Neumožňuje neželané vniknutie osôb či živočíchov</li>
                <li>Chráni pred hlukom, prachom a peľmi</li>
                <li>Odvádza pachy, CO2 a nadmernú vlhkosť</li>
                <li>Udržuje vetraný objekt vo výbornom stave</li>
                <li>Spĺňa budúce požiadavky na bývanie podľa noriem EU</li>
            </ul>
            <img src={img2} alt="VÝHODY REKUPERÁCIE"/>
            <p>
                <strong>
                    ČO ZÍSKATE REKUPERÁCIOU?
                </strong>
            </p>
            <p>
                <strong>
                    Trvale čerstvý vzduch{"\u00A0"}
                </strong>
                vo všetkých miestnostiach, bez ohľadu na ročné obdobie
            </p>
            <p>
                <strong>
                    Zníženie nákladov{"\u00A0"}
                </strong>
                na kúrenie a chladenie až o 40%
            </p>
            <p>
                <strong>
                    Zdravý spánok{"\u00A0"}
                </strong>
                – koniec bolestiam hlavy a upchatému nosu
            </p>
            <p>
                <strong>
                    Lepšie bývanie,{"\u00A0"}
                </strong>
                či je vonku zima a či teplo
            </p>
        </article>
    )
}

export default RekuperaciaVzduchu
