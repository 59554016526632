import classes from "./Articles.module.scss";
import img1 from "../../assets/ako_si_zariadit_dom.png";
import img2 from "../../assets/nabytok_na_mieru.png";
import img3 from "../../assets/apparel-assortment-boutique-1336873-e1557245064856.jpg";
import {useEffect} from "react";


function PraktickeRady() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Praktické rady ako zariadiť drevodom</title>
            <img src={img1} alt="Praktické rady ako zariadiť drevodom"/>
            <p>
                <strong>
                    Výstavba drevodomu je expresne rýchla. Na vydanom stavebnom povolení sotva stihne uschnúť atrament na pečiatkach a vy sa už môžete v priebehu 3 či 4 mesiacov sťahovať do vlastného. Na premýšľanie, ako zariadiť drevodom teda skutočne neostáva veľa času. Ak ste sa na
                    zariaďovanie vášho nového bývania nepripravovali vopred, zopár dobre mienených rád vám príde určite vhod.
                </strong>
            </p>
            <p>
                Materiál a farebnosť podláh alebo stien tentoraz vynecháme. Niežeby boli podlahy a steny nepodstatné, ale pri realizácii výstavby <a href="/katalog">drevodomu šitého na mieru</a> ste už o nich s najväčšou pravdepodobnosťou po konzultácii so stavebnou firmou dávnejšie rozhodli. V
                nasledujúcich
                riadkoch sa
                preto sústredíme skôr na zariaďovanie drevodomu nábytkom.
            </p>
            <p>
                <strong>
                    Štýl zariadenia si prispôsobte vlastnému vkusu
                </strong>
            </p>
            <p>
                Každý máme svoje sny a predstavy o vlastnom bývaní. Pred zariaďovaním interiéru drevodomu by bolo možno super inšpirovať sa na medzinárodnom veľtrhu nábytku a bytových doplnkov, ktorý sa mimochodom zvykne konať spolu s veľtrhom modernej a tradičnej architektúry z dreva na Národnom
                výstavisku v Nitre. Ak ste to však tento rok nestihli, nič sa nedeje. Pokojne sa môžete narýchlo inšpirovať televíznymi reláciami, časopismi zaoberajúcimi sa bývaním, katalógmi výrobcov nábytkov, obrázkami na sociálnych sieťach alebo pri návštevách u vašich známych.
                Pri zariaďovaní interiéru sa nesnažte silou mocou vyberať iba ultramoderný nábytok, ktorý je práve v kurze.
                <strong>
                    {"\u00A0"}Vo všeobecnosti totiž platí, že dôraz by sa mal klásť nielen na dizajn, ale aj na praktickosť nábytku.{"\u00A0"}
                </strong>
                Pravdou však je aj to, že jedno tvrdenie nevylučuje druhé, teda dizajnovo vydarený nábytok môže byť zároveň plne funkčný, pohodlný a praktický.
                Interiér montovaného drevodomu je na nerozoznanie od murovanej verzie. Pri výbere nábytku sa preto môžete pokojne inšpirovať viac ako dvomi desiatkami rôznych štýlov. Ak nie ste milovníkmi preplnených priestorov, minimalistický alebo severský štýl bude pre vás ako stvorený. Dopriať
                si môžete objatie exotiky alebo možno dáte prednosť industriálnemu štýlu, či fenoménu Hygge pochádzajúcemu z Dánska, ktorý vyžaruje pohodu a spokojnosť bývania. Možností je naozaj veľa.</p>
            <p>
                <strong>
                    Štandardné zostavy alebo nábytok robený na mieru?
                </strong>
            </p>
            <img src={img2} alt=" Štandardné zostavy alebo nábytok robený na mieru?"/>
            <p>
                Pri zariaďovaní interiéru drevodomu máte k dispozícii viacero alternatív. Vyberať môžete z klasického sektorového nábytku a univerzálnych zostáv, no do úvahy prichádza aj na mieru vyrobený nábytok.
                <strong>
                    {"\u00A0"}Kvalita materiálu alebo originálnosť dizajnu sektorového a univerzálneho nábytku je síce na primeranej úrovni, no o nejakej výnimočnej exkluzívnosti nemôže byť ani reči.{"\u00A0"}
                </strong> Pri nábytku na mieru je to samozrejme o niečom inom.
                Na rozdiel od sériovo vyrábaného sektorového nábytku máte pri nábytku na mieru vo vlastných rukách jeho dizajnové a materiálové prevedenie. Záleží tak prakticky iba na vašich finančných možnostiach, či dáte prednosť kvalite v podobe masívneho nábytku, alebo stavíte na kombináciu
                masívu a dyhy.
                <strong>
                    {"\u00A0"}Cenovo prijateľnejšou alternatívou nábytku na mieru, je zostava z drevenej dyhy, takže ak hľadáte optimálny pomer ceny a kvality, odporúčame vám práve túto možnosť.{"\u00A0"}
                </strong>
                Čo sa týka vyššie spomínaných štýlov, každý si príde na svoje a pri zariaďovaní drevodomu nábytkom na mieru sa môžete rozhodnúť pre moderné až minimalisticky poňaté zostavy, no dopriať si môžete aj originálne alebo až extravagantné kúsky. Problémom však nebude ani nábytok na mieru v
                rustikálnom, retro alebo vintage štýle, ktorý vzbudzujú nostalgiu za starými dobrými časmi.
            </p>
            <p>
                <strong>
                    Bokom nenechajte ani vstavané skrine
                </strong>
            </p>
            <p>
                Snaha o čo najefektívnejšie zužitkovanie priestoru každej miestnosti je logická. Úložného priestoru nie je nikdy dosť a týka sa to nielen menších panelákových bytov, ale aj rodinných domov.
                <strong>
                    {"\u00A0"}Vstavané skrine dokážu využiť každý voľný centimeter priestoru a prekážkou nie sú ani izby s atypickým pôdorysom, takže by ste o nich mali určite popremýšľať.{"\u00A0"}
                </strong>
                Vďaka vstavaným skriniam môžete získať nové kapacity na uloženie vecí takmer v každej miestnosti. Ich pridanou hodnotou je atraktívny dizajn, za ktorý sa nemusí hanbiť žiadna miestnosť. Vstavané skrine tak môžu byť súčasťou zariadenia obývacej izby, spálne, či predsiene, no rovnako
                tak dobre vám poslúžia umiestnené pod schodiskami alebo v podkroví.
            </p>
            <p>
                <strong>
                    Bez šatníka to nie je ono
                </strong>
            </p>
            <img src={img3} alt="Bez šatníka to nie je ono"/>
            <p>
                Vstavané skrine sú bez najmenších pochybností veľmi praktickou odpoveďou na uloženie vecí, odevov a sezónnych odevných doplnkov.
                <strong>
                    {"\u00A0"}Pre mnohé ženy je však oveľa lákavejšia predstava vlastného šatníka, ktorý im umožní ešte efektívnejšie a prehľadnejšie uloženie odevov{"\u00A0"}
                </strong>
                bez komplikovaného prehrabovania sa vo veciach.
                Ak ste pri výstavbe drevodomu siahli po projekte, ktorý disponuje vlastným šatníkom máte to jednoduchšie, no smútiť nemusíte ani v prípade, že ste pri výbere projektu rodinného domu so šatníkom nepočítali. Všetko môžete napraviť vstavaným šatníkom na mieru, ktorý sa zaobíde bez
                nejakých komplikovanejších stavebných zásahov. Postačí ak máte <a href="/katalog/Bungalov%209">priestrannú spálňu </a> alebo inú vhodnú miestnosť. Výhodou vstavaného šatníka na mieru je originálny vzhľad a do posledných detailov premyslené, komfortné usporiadanie vecí.</p>
            <p>
                <strong>Tip: </strong> Viete, <a href="/clanky/ako-si-vybrat-spravny-projekt">ako si vybrať správny projekt?</a>
            </p>
        </article>
    )
}

export default PraktickeRady
