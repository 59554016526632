import classes from "./Articles.module.scss";
import img1 from "../../assets/ako-ziskat-hypoteku-768x336.jpg";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";


function Hypoteka() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])
    let history = useHistory()

    async function scrollToContacts() {
        await history.push("/")
        setTimeout(() => {
            const element = document.getElementById("Kontakt")
            const elementPosition = element.getBoundingClientRect().top
            window.scrollTo({top: elementPosition, behavior: "smooth"})
        }, 1000)
    }

    return (
        <article className={classes.article}>
            <title>Ako postupovať pri vybavení hypotéky</title>
            <img src={img1} alt="Ako postupovať pri vybavení hypotéky"/>
            <p>
                <strong>
                    Ak sa možno práve teraz rozhodujete pre financovanie výstavby vlastného bývania prostredníctvom hypotéky, určite vám príde vhod niekoľko základných informácií týkajúcich sa tohto bankového produktu. V reklamách síce vyzerá všetko úplne jednoducho a lákavo, no realita nemusí byť
                    vždy takáto ružová. Vybavenie a schválenie hypotéky je proces, ktorý má presne zadefinované pravidlá a financovanie výstavby montovaného domu má navyše svoje vlastné špecifiká. Aké máte teda možnosti?
                </strong>
            </p>
            <h1>
                Úroky klesajú, legislatíva s úpravami, záložné právo bezo zmien
            </h1>
            <p>
                V posledných rokoch a mesiacoch sme svedkami neustáleho znižovania úrokových sadzieb pri hypotékach s fixáciou na stredne dlhé obdobie. Vidieť za tým treba nielen konkurenčný boj bánk o získanie nových klientov, ale aj rekordne nízke úrokové sadzby Európskej centrálnej banky.
            </p>
            <p>
                Národná banka Slovenska síce upravuje nariadenia týkajúce sa poskytovania hypoték,
                <strong>
                    {"\u00A0"}no legislatíva týkajúca sa záložného práva sa stále nemení{"\u00A0"}
                </strong>
                a preto celý proces ohľadom poskytnutia hypotéky na montovaný dom naďalej ostáva v princípe komplikovaný.
            </p>
            <h1>
                Základný rozdiel vo financovaní montovaného domu
            </h1>
            <p>
                <strong>
                    Záložné právo, ktoré je základnou podmienkou získania hypotéky vzniká zápisom rozostavanej stavby do katastra nehnuteľností.{"\u00A0"}
                </strong>
                No a práve tu nájdeme pri montovaných domoch kameň úrazu. Pri dome z tehál totiž nemusí ísť o výraznejší problém, pretože murované stavby môžu byť geodetom
                zakreslené a v katastri zapísané už po vytiahnutí obvodových múrov do výšky 1 metra.
            </p>
            <p>
                Iná situácia je však pri montovaných drevostavbách. Odlišný technologický postup pri výstavbe drevostavieb umožňuje
                <strong>
                    {"\u00A0"}zapísanie rozostavaného montovaného domu do katastra nehnuteľností prakticky až v štádiu hrubej stavby.8{"\u00A0"}
                </strong>
                Skutočný problém vtedy, keď na financovanie výstavby montovaného domu do tohto štádia nemáte dostatok vlastných finančných prostriedkov.
            </p>
            <h1>
                Banky sú ústretové, napriek chýbajúcim riešeniam
            </h1>
            <p>
                Napriek tomu, že banky vo všeobecnosti stále
                <strong>
                    {"\u00A0"}nemajú na špecifické financovanie takýchto stavieb optimálne riešenie,{"\u00A0"}
                </strong>
                niektoré z nich vychádzajú budúcim majiteľom montovaných domov v ústrety financovaním výstavby na tranže. Finančné prostriedky sú tak uvoľňované postupne po častiach. Priekopníkom financovania výstavby montovaných domov prostredníctvom hypotéky na tranže bola Slovenská Sporiteľňa.
                Neskôr sa ku nej pridali niektoré ďalšie banky. Nie každému však takýto spôsob financovania musí vyhovovať a preto vám ponúkneme pohľad na niektoré ďalšie alternatívy.
            </p>
            <h1>
                Hypotéka na inú nehnuteľnosť
            </h1>
            <p>
                <strong>
                    Cestu za vlastným bývaním vám môže výrazne zjednodušiť vlastníctvo inej nehnuteľnosti,{"\u00A0"}
                </strong>
                ktorú použijete na zabezpečenie hypotéky. V princípe môže ísť o akúkoľvek nehnuteľnosť určenú na bývanie. Výnimkou sú skladové priestory a výrobné haly, ktoré banka ako predmet záložného práva
                zvyčajne neakceptuje. Na nehnuteľnosť zaťaženú exekúciou alebo právom doživotného užívania však takisto hypotéku nezískate. Problémom je tiež nevysporiadaná prístupová cesta k nehnuteľnosti,
                <strong>
                    {"\u00A0"}preto si pred návštevou banky preverte všetky vlastnícke vzťahy.
                </strong>
            </p>
            <p>
                Hodnota zakladanej nehnuteľnosti sa určí znaleckým posudkom, ktorý si dá vyhotoviť banka. Od tejto hodnoty sa napokon bude odvíjať úroková sadzba i výška hypotekárneho úveru. Celý schvaľovací proces napokon v konečnom dôsledku urýchlite, ak si pred tým ako navštívite banku a podáte
                samotnú žiadosť skompletizujete všetky potrebné doklady. Potrebovať budete:
            </p>
            <ul>
                <li><strong>dva doklady totožnosti</strong> (napr. občiansky, vodičský preukaz)</li>
                <li><strong> doklady preukazujúce príjem</strong> – živnostníci predložia daňové priznanie, zamestnanci potvrdenie o výške príjmu od zamestnávateľa</li>
                <li><strong> doklady týkajúce sa zakladanej nehnuteľnosti</strong> – poistná zmluva s vinkuláciou v prospech banky, znalecký posudok (v prípade ak si banka nevyhotoví svoj posudok),</li>
                <li><strong> list vlastníctva, kópia katastrálnej mapy</strong></li>
                <li><strong> doklady preukazujúce účel použitia úveru</strong> – pri nehnuteľnosti ktorá sa stavia zmluva o dielo alebo zmluva o výstavbe, stavebné povolenie a geometrický plán.</li>
            </ul>
            <h1>
                Čo nasleduje po podaní žiadosti
            </h1>
            <p>
                Po kompletnom predložení dokladov sa naplno začne schvaľovací proces. Ten môže byť ukončený tak ako to sľubuje televízna reklama do 24 hodín, ale môže trvať aj niekoľko dní.
                <strong>
                    {"\u00A0"}V rámci neho si banka dôkladne preverí vašu bonitu,{"\u00A0"}
                </strong>
                ktorá rozhodne o tom, či vám bude hypotéka schválená alebo zamietnutá. Do úvahy sa pritom berie výška príjmu, rodinný stav, vek, dosiahnuté vzdelanie, počet vyživovaných osôb v domácnosti a
                <strong>
                    {"\u00A0"}v neposlednom rade úverová história.
                </strong>
            </p>
            <p>
                Po schválení hypotéky môže nasledovať podpísanie úverovej zmluvy, zmluvy o zriadení záložného práva a návrhu na vklad záložného práva do katastra nehnuteľností. Po podpise zmlúv dôjde k uvoľneniu finančných prostriedkov a ich prevedeniu na váš účet.
            </p>
            <h1>
                Riešením je tiež štátom dotovaná Hypotéka pre mladých
            </h1>
            <p>
                Zvýhodnená hypotéka pre mladých od 1. januára 2018 neponúka nižšie úročenie ako tomu bolo v minulosti, no napriek tomu môže byť atraktívnym bankovým produktom, ktorý vám umožní <a href="/katalog">vlastné bývanie v modernom montovanom dome</a>. Hypotéka nižšie úročenie nahradila
                daňovým bonusom, ktorý si
                budete môcť uplatniť vo výške 50 % zo zaplatených úrokov za príslušný kalendárny rok, maximálne však do výšky 400 €. Daňový bonus si môžete uplatňovať počas piatich rokov.
            </p>
            <p>
                Ak máte konkrétne otázky na financovanie montovaného domu, neváhajte nás
                <span className={classes.contacts} onClick={() => scrollToContacts()}> kontaktovať</span>
                . Ponúkneme vám služby nášho hypotekárneho poradcu, ktorý prispôsobí financovanie výstavby montovaného domu vašim
                individuálnym možnostiam.
            </p>
        </article>
    )
}


export default Hypoteka
