import classes from "./AboutUs.module.scss";
import mobile from "../../../assets/o nas2.jpg";
import bonsai from "../../../assets/bonsaj.png";
import title from "../../../assets/o nas napis.png";
import table from "../../../assets/background o nas.png";
import {Parallax, useParallaxController} from "react-scroll-parallax";


function AboutUs() {
    const parallaxController = useParallaxController()

    return (
        <div className={classes.aboutUs}>
            <span className={classes.desktop}>
                <Parallax className={classes.parallax1} translateY={["-30%", "-30%"]} translateX={["-5%", "15%"]} opacity={[0, 1, "easeInOut"]} startScroll={250} endScroll={550}>
                    <img src={bonsai} onLoad={() => parallaxController.update()} alt="Bonsai"/>
                </Parallax>
                <Parallax className={classes.parallax2} translateY={["5%", "15%"]} startScroll={0} endScroll={400}>
                    <img src={title} onLoad={() => parallaxController.update()} alt="Nápis"/>
                </Parallax>
                <img className={classes.parallax3} src={table} alt="Stôl"/>
            </span>
            <div className={classes.mobile}>
                <img src={mobile} alt="O nás"/>
            </div>
        </div>
    )
}

export default AboutUs


