import classes from "./ErrorPage.module.scss";
import PulseButton from "../../../components/pulseButton/PulseButton";


function Main404() {
    return (
        <div className={classes.page}>
            <p>Položka sa nenašla</p>
            <PulseButton text={window.location.host.split(".").pop() !== "cz" ? "Prejsť do katalógu" : "Přejít do katalogu"} redirectUrl="/katalog"/>
        </div>
    )
}

export default Main404