import classes from "./WhyUs.module.scss";
import title from "../../../assets/whyUs-title.png";
import titleCZ from "../../../assets/proc stavetCZ.png";
import background from "../../../assets/why us2.jpg";
import {Parallax, useParallaxController} from "react-scroll-parallax";


function WhyUs() {
    const parallaxController = useParallaxController()

    return (
        <div className={classes.whyUs}>
            <span className={classes.desktop}>
                <Parallax className={classes.parallax1} translateY={["0%", "200%"]}>
                    <img src={window.location.host.split(".").pop() !== "cz" ? title: titleCZ} onLoad={() => parallaxController.update()} alt="Prečo s nami názov"/>
                </Parallax>
                <img className={classes.parallax3} src={background} alt="Prečo s nami pozadie"/>
            </span>
            <span className={classes.mobile}><img src={background} alt="Prečo s nami"/></span>
        </div>
    )
}

export default WhyUs


