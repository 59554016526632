import classes from "./CatalogHomePageImg.module.scss";
import img from "../../../assets/2_xx.jpg";


function CatalogHomePageImg() {
    return (
        <div className={classes.whyUs}>
            <span className={classes.desktop}>
                <img className={classes.parallax3} src={img} alt="Katalóg img"/>
            </span>
            <span className={classes.mobile}><img src={img} alt="Katalóg img"/></span>
        </div>
    )
}

export default CatalogHomePageImg


