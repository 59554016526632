import classes from "./Articles.module.scss";
import img1 from "../../assets/ako-vybavit-stavebne-povolenie-768x336.jpg";
import {useEffect} from "react";


function StavebnePovolenie() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Ako postupovať pri vybavovaní stavebného povolenia</title>
            <img src={img1} alt="Ako postupovať pri vybavovaní stavebného povolenia"/>
            <p>
                <strong>
                    Kým proces alebo čas výstavby montovaného domu je v porovnaní s murovaným úplne rozdielny a vyznieva v prospech drevostavby, iné je to pri vybavovaní stavebného povolenia. Vybavovanie stavebného povolenia sa v ničom zásadnom neodlišuje a v obidvoch prípadoch stavebníci „dobehnú
                    do cieľa“ v rovnakom čase. Preto ak chcete ešte tento rok začať s výstavbou montovaného domu, máte najvyšší čas začať zháňať potrebné povolenia.
                </strong>
            </p>
            <p>
                Žiadosť o vydanie stavebného povolenia sa podáva na miestne príslušnom stavebnom úrade, ktorý podľa platnej legislatívy musí o žiadosti rozhodnúť do 30 dní alebo v zložitejších prípadoch najneskôr v lehote do 60 dní od podania žiadosti.Samozrejme takýto scenár by bol ešte stále
                optimálny, no vybavenie žiadosti môže trvať podstatne dlhšie a závisieť bude od toho, či stavebník predloží ku svojej žiadosti všetky potrebné doklady.
                <strong>
                    {"\u00A0"}Nekompletná dokumentácia totiž môže byť príčinou toho, že vydanie stavebného povolenia sa oddiali.
                </strong>
            </p>
            <p>
                Predísť prípadným výzvam stavebného úradu na doplnenie chýbajúcich príloh sa dá jednoducho tak, že
                <strong>
                    {"\u00A0"}niektoré doklady si vybavíte v dostatočnom časovom predstihu, teda pred podaním žiadosti o vydanie stavebného povolenia.{"\u00A0"}
                </strong>
                Na stavebný úrad potom podáte skutočne kompletnú žiadosť.No a aby ste v strese nemuseli rýchlo riešiť chýbajúce prílohy, poradíme vám na ktoré doklady by ste sa mali zamerať predovšetkým. Skôr ako si však povieme niečo ku konkrétnym dokladom, v nasledujúcich riadkoch uvedieme zoznam
                štandardne vyžadovaných príloh.
            </p>
            <h1>
                Prílohy žiadosti o vydanie stavebného povolenia
            </h1>
            <ul>
                <li>
                    <strong>
                        Kompletná projektová dokumentácia{"\u00A0"}
                    </strong>
                    vypracovaná oprávnenou osobou, ktorá musí byť v súlade s územným plánom mesta alebo obce. Túto dokumentáciu tvorí napríklad súhrnná technická správa, celková situácia stavby s vyznačením hranice pozemkov a ich parcelné čísla podľa katastra
                    nehnuteľností vrátane susedných pozemkov a jestvujúcich stavieb na nich, stavebné a vytyčovacie výkresy, projekt požiarnej ochrany, či projektová dokumentácia vykurovania a jednotlivých prípojok (plyn, voda, elektrika).
                </li>
                <li>
                    <strong>
                        Doklad o vlastníctve pozemku.
                    </strong>
                </li>
                <li>
                    <strong>
                        Snímka z katastrálnej mapy.
                    </strong>
                </li>
                <li>
                    <strong>
                        Identifikačné údaje stavebnej spoločnosti{"\u00A0"}
                    </strong>
                    ktorá bude realizovať výstavbu <a href="/katalog">montovaného domu</a> (vhodné je priložiť fotokópiu oprávnenia na vykonávanie stavebnej činnosti).
                </li>
                <li>
                    <strong>
                        Pri svojpomocnej výstavbe domu vyjadrenie stavebného dozoru{"\u00A0"}
                    </strong>
                    (alebo zhotoviteľa montovaného domu).
                </li>
                <li>
                    <strong>
                        Súhlas formou záväzného stanoviska na povolenie malých zdrojov znečisťovania od obce (mesta).
                    </strong>
                </li>
                <li>
                    <strong>
                        Právoplatné územné rozhodnutie{"\u00A0"}
                    </strong>
                    (prikladá sa vtedy ak ste nepožiadali o zlúčenie stavebného a územného konania).
                </li>
                <li>
                    <strong>
                        Súhlas formou záväzného stanoviska od okresného úradu – odbor pozemkový
                    </strong>
                </li>
                <li>
                    <strong>
                        Stanoviská vlastníkov sietí a zariadení technického vybavenia{"\u00A0"}
                    </strong>
                    (vodárenský podnik, energetici, poprípade SPP Distribúcia).
                </li>
            </ul>
            <p>
                Z týchto príloh chceme upriamiť vašu pozornosť predovšetkým na posledné tri prílohy, ktorých vybavenie môže zabrať najviac času.
            </p>
            <h1>
                Stanoviská vodárenského podniku, energetikov a SPP Distribúcie
            </h1>
            <p>
                O vydanie súhlasu na pripojenie montovaného domu k elektrickej sieti alebo na vodu a kanalizáciu by ste mali požiadať čo najskôr.
                <strong>
                    {"\u00A0"}Tieto inštitúcie totiž majú na vydanie stanoviska 30 dňovú zákonnú lehotu.{"\u00A0"}
                </strong>
                Ak však uvažujete, že budete na vykurovanie <a href="/katalog">montovaného domu</a> využívať plyn, tak o vydanie súhlasu požiadajte čo najrýchlejšie aj spoločnosť SPP Distribúcia a.s..
                <strong>
                    {"\u00A0"}K žiadosti spravidla postačí priložiť list vlastníctva, situačný nákres pripojenia a projektovú dokumentáciu.{"\u00A0"}
                </strong>
                V prípade schválenia vám vystavia súhlasné stanovisko a uzavrú s vami zmluvu.
            </p>
            <p>
                Súhlas formou záväzného stanoviska od okresného úradu – odbor pozemkový
            </p>
            <p>
                Pozemkový odbor okresného úradu by ste mali navštíviť tiež ešte pred samotným podaním žiadosti. Ak totiž plánujete stavať na pozemku ktorý je klasifikovaný ako orná pôda, budete bez ohľadu na to, či sa pozemok nachádza v zastavanom území obce alebo mimo neho, potrebovať
                <strong>
                    {"\u00A0"}súhlas okresného úradu – odboru pozemkového a lesného s vyňatím poľnohospodárskej pôdy z pozemkového fondu.
                </strong>
            </p>
            <p>
                Celý proces sa môže pritom poradne predĺžiť pretože ešte pred podaním žiadosti o vyňatie pôdy bude musieť geodet vyhotoviť geometrický plán, na základe ktorého sa vytvára nová parcela, ktorá sa navrhuje vyňať. Geometrický plán navyše musí odobriť a potvrdiť príslušný katastrálny úrad
                a až potom môžete požiadať o vyňatie pôdy.
            </p>
            <h1>
                Územné konanie a rozhodnutie
            </h1>
            <p>
                Územné rozhodnutie je vo všeobecnosti rozhodnutím o umiestnení stavby, ktorým sa určuje stavebný pozemok, umiestňuje sa stavba na ňom a určujú sa podmienky na jej umiestnenie.
            </p>
            <p>
                Najideálnejšie by bolo spojiť územné konanie so stavebným konaním. V praxi to však nie je vždy možné a najmä v prípadoch keď idete stavať na pozemku, ktorý sa nachádza mimo zastavaného územia obce (v extraviláne) alebo sa nenachádza v územnom pláne obce. Vtedy sa samostatnému
                územnému konaniu nevyhnete a preto bude vhodné začať konať čím skôr. Stavebný úrad má totiž v zmysle zákona
                <strong>
                    {"\u00A0"}povinnosť vydať rozhodnutie vo veci do 30 dní, no a v zložitejších prípadoch až do 60 dní.{"\u00A0"}
                </strong>
                Vybavenie stavebného povolenia sa tak môže poriadne predĺžiť.
            </p>
            <h1>
                Žiadosť a stavebné konanie
            </h1>
            <p>
                Samozrejme aj ostatné prílohy žiadosti sú nemenej dôležité, no ich vybavenie bude trvať podstatne menej ako tie ktoré sme práve spomenuli. Ak ste teda dali dokopy všetky doklady, ktoré majú tvoriť prílohu žiadosti, podajte si žiadosť o vydanie stavebného povolenia.
            </p>
            <p>
                Po podaní žiadosti veci naberú rýchly spád. Stavebný úrad zašle všetkým účastníkom konania oznámenie o začatí stavebného konania. Všetci účastníci budú mať zákonom stanovenú lehotu, v ktorej sa budú môcť k prebiehajúcemu konaniu vyjadriť.
            </p>
            <p>
                V prípade kompletnej žiadosti a ak účastníci konania nevyjadria proti stavbe pri ústnom pojednávaní žiadne námietky,tak stavebný úrad najneskôr pred uplynutím zákonom stanovenej lehoty vydá rozhodnutie o stavebnom povolení. Rozhodnutie je následne zaslané účastníkom konania, ktorý
                majú ešte 15 dňovú lehotu na prípadné odvolanie. Ak sa v tejto lehote voči rozhodnutiu stavebného úradu neodvolá nikto z účastníkov konania, rozhodnutie nadobudne právoplatnosť.
                <strong>
                    {"\u00A0"}Stavebné povolenie je zvyčajne platné dva roky od dátumu právoplatnosti rozhodnutia.
                </strong>
            </p>
        </article>
    )
}

export default StavebnePovolenie
