import classes from "./BackArrow.module.scss";
import backArrow from "../../../assets/sipka hore.png";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";


function BackArrow() {
    let history = useHistory()
    const [url, setUrl] = useState(history.location.pathname.split("/").pop())

    useEffect(() => {
        setUrl(history.location.pathname.split("/").pop())
    }, [history.location.pathname])

    function backClick() {
        history.push(history.location.pathname.slice(0, history.location.pathname.lastIndexOf("/")))
    }

    function title() {
        switch (url) {
            case "o-nas":
                return "O nás"
            case "katalog":
                return window.location.host.split(".").pop() !== "cz" ? "Katalóg" : "Katalog"
            case "realizacie":
                return window.location.host.split(".").pop() !== "cz" ? "Realizácie" : "Realizace"
            case "realizace":
                return "Realizace"
            case "stupne-vystavby":
                return window.location.host.split(".").pop() !== "cz" ? "Stupne výstavby" : "Stupně konstrukce"
            case "clanky":
                return "Články"
            default:
                return null
        }
    }

    return (
        <section className={classes.backArrow} style={{display: history.location.pathname === "/" || history.location.pathname === "/admin" ? "none" : "flex"}}>
            <button onClick={backClick}>
                <img src={backArrow} alt="Šípka naspäť"/>
            </button>
            <title>
                {title()}
            </title>
        </section>
    )
}

export default BackArrow
