import classes from "./LevelsOfConstruction.module.scss";
import title from "../../../assets/levels-title.png";
import titleCZ from "../../../assets/stupne konstrukceCZ.png";
import background from "../../../assets/levels of construction2.jpg";
import {Parallax, useParallaxController} from "react-scroll-parallax";


function LevelsOfConstruction() {
    const parallaxController = useParallaxController()

    return (
        <div className={classes.levels}>

            <span className={classes.desktop}>
                <Parallax className={classes.parallax1} translateY={["0%", "50%"]}>
                    <img src={window.location.host.split(".").pop() !== "cz" ? title : titleCZ} onLoad={() => parallaxController.update()} alt="Prečo s nami nápis"/>
                </Parallax>
                <img className={classes.parallax3} src={background} alt="Prečo s nami pozadie"/>
            </span>

            <span className={classes.mobile}><img src={background} alt="Stupne výstavby"/></span>
        </div>
    )
}

export default LevelsOfConstruction


