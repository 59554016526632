import "swiper/css/swiper.css";
import classes from "../gallery/selectedGallery/SelectedGallery.module.scss";
import Swiper from "react-id-swiper";
import AddImages from "./addImages/AddImages";
import RemoveImage from "./removeImage/RemoveImage";
import TransferData from "./transferData/TransferData";
import ChangeTitleImage from "./changeTitleImage/ChangeTitleImage";
import {useEffect} from "react";
import {Navigation, Pagination} from "swiper/js/swiper.esm";


function SelectedGallery({item, allData}) {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    const params = {
        effect: "coverflow",
        grabCursor: true,
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 1000,
            modifier: 1,
            slideShadows: false,
        },
        pagination: {
            el: ".swiper-pagination"
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        renderPrevButton: () => <button className={`swiper-button-prev ${classes.arrow}`}/>,
        renderNextButton: () => <button className={`swiper-button-next ${classes.arrow}`}/>,
        modules: [Navigation, Pagination],
    }

    return (
        <article className={classes.selectedGallery}>

            <section className={classes.text}>
                <h1>{allData[item]?.name}</h1>
                <p className={classes.description}>{allData[item]?.description}</p>
            </section>

            <TransferData item={allData[item]}/>

            <section className={classes.imagesCZ}>
                {(allData[item]?.images?.length > 1) ?
                    <Swiper {...params} activeSlideKey={`${Math.round(allData[item]?.images?.length / 2 - 1)}`}>
                        {(allData[item]?.images?.sort()).map((image, index) => {
                            return (
                                <span className={classes.imgCZ} key={index}>
                                    <ChangeTitleImage item={allData[item]} newImage={image}/>
                                    <RemoveImage item={allData[item]} imageUrl={image}/>
                                    <img src={image} alt={allData[item]?.name} loading="lazy" className={classes.imageMainRealizacie}/>
                                </span>
                            )
                        })}
                    </Swiper> :
                    (allData[item]?.images)?.map((image, index) => {
                        return (
                            <span className={classes.imgCZ} key={index}>
                                <ChangeTitleImage item={allData[item]} newImage={image}/>
                                <RemoveImage item={allData[item]} imageUrl={image}/>
                                <img src={image} alt={allData[item]?.name} loading="lazy" className={classes.imageMainRealizacie}/>
                            </span>
                        )
                    })
                }
            </section>

            <AddImages item={allData[item]}/>
        </article>
    )
}


export default SelectedGallery
