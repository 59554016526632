import CmsReducer from "./CmsReducer";
import AdminReducer from "./AdminReducer";
import CookiesReducer from "./CookiesReducer";
import CatalogRefsReducer from "./CatalogRefsReducer";
import DatabaseTextsReducer from "./DatabaseTextsReducer";
import RealizacieRefsReducer from "./RealizacieRefsReducer";
import DatabaseCatalogReducer from "./DatabaseCatalogReducer";
import DatabaseRealizacieReducer from "./DatabaseRealizacieReducer";
import PriceOfferSelectedReducer from "./PriceOfferSelectedReducer";
import DatabaseRealizujemeReducer from "./DatabaseRealizujemeReducer";
import {combineReducers} from "redux";


export default combineReducers({
    cms: CmsReducer,
    admin: AdminReducer,
    getCookies: CookiesReducer,
    databaseTexts: DatabaseTextsReducer,
    databaseCatalog: DatabaseCatalogReducer,
    databaseRealizacie: DatabaseRealizacieReducer,
    catalogRefs: CatalogRefsReducer,
    realizacieRefs: RealizacieRefsReducer,
    realizujeme: DatabaseRealizujemeReducer,
    priceOfferSelected: PriceOfferSelectedReducer
})
