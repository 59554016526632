import classes from "./AddCustomer.module.scss";
import * as xlsx from "xlsx";
import Dropzone from "react-dropzone";
import dragAndDrop from "../../../../assets/dragAndDrop.png";
import firebaseApp from "../../../../firebase";
import fileToArrayBuffer from "file-to-array-buffer";
import {toast} from "react-toastify";
import {setCms} from "../../../../redux/actions/cms";
import {connect} from "react-redux";
import {useState} from "react";
import {customerFields} from "../CustomerFields";
import {getFirestore, setDoc, doc} from "firebase/firestore";


function AddCustomer({cms, setCms}) {
    const [json, setJson] = useState([])
    const [json2, setJson2] = useState({})

    function buffer(file) {
        fileToArrayBuffer(file).then((data) => {
            const workbook = xlsx.read(data, {type: "array"})
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)
            setJson(json)
        })
    }

    function handleInput(e, field) {
        setJson2({...json2, [field]: e.target.value})
    }

    async function submitWithToast() {
        await toast.promise(async () => {
            if (json.length > 0) {
                const firestore = getFirestore(firebaseApp)
                for (const client of json) {
                    await setDoc(doc(firestore, "cms", client["meno zákazníka"]), client, {merge: true})
                        .then(() => {
                            setCms({...cms, [client["meno zákazníka"]]: client})
                        })
                }
                setJson([])
            }
        }, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    async function submitWithToast2() {
        await toast.promise(async () => {
            if (json2["meno zákazníka"]) {
                const firestore = getFirestore(firebaseApp)
                await setDoc(doc(firestore, "cms", json2["meno zákazníka"]), json2, {merge: true})
                    .then(() => {
                        setCms({...cms, [json2["meno zákazníka"]]: json2})
                    })
                setJson2({})
            }
        }, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    return (
        <div className={classes.layout}>
            <div className={classes.dialog}>
                <h3>Pridaj jedného klienta</h3>

                <p>
                    Vyplň manuálne nasledovné polia a potvrď.<br/>
                    <strong>PRIDÁ nový </strong> kontakt do aktuálnej databázy.<br/>
                    **Meno zákazníka je povinný údaj
                </p>

                <ul className={classes.form}>
                    {customerFields.map(field => (
                        <li key={field}>
                            <div>{field}</div>
                            <input
                                type="text"
                                placeholder={`Zadaj ${field}`}
                                value={json2[field] ? json2[field] : ""}
                                onChange={(e) => handleInput(e, field)}
                            />
                        </li>
                    ))}
                </ul>

                <button disabled={!json2["meno zákazníka"]} onClick={submitWithToast2}>Pridať nového zákazníka</button>
            </div>

            <h2>alebo</h2>

            <div className={classes.addClients}>
                <h3>Pridaj viacero klientov naraz</h3>
                <p>
                    Použi funkciu Drag and Drop a pretiahni Excel databázu do tohto poľa.<br/>
                    Pozor, Excel databáza musí byť v správnom tvare.<br/>
                    <strong>NAHRADÍ existujúce</strong> a <strong>DOPLNÍ chýbajúce</strong> kontakty v aktuálnej databáze.<br/>
                </p>

                <Dropzone
                    onDropAccepted={(files) => files.map(file => buffer(file))}
                    accept={"text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={classes.dropzone}>
                            <input {...getInputProps()} />
                            {json.length > 0 ?
                                <h1>Pocet riadkov: {json.length}</h1> :
                                <img src={dragAndDrop} alt="dragAndDrop" className={classes.dropImg}/>
                            }
                        </div>
                    )}
                </Dropzone>
                <button disabled={!json.length > 0} onClick={submitWithToast}>Potvrdiť excel upload</button>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        cms: state.cms
    }
}

export default connect(mapStateToProps, {setCms})(AddCustomer)