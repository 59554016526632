import classes from "./Articles.module.scss";
import img1 from "../../assets/drevo-768x336.jpg";
import {useEffect} from "react";


function DrevoAkoMaterial() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Drevo ako materiál – zaujímavosti o dreve, o ktorých ste možno netušili</title>
            <img src={img1} alt="Drevo ako materiál"/>
            <p>
                <strong>
                    Drevo patrí k výnimočným prírodným materiálom, ktoré v nás vyvolávajú pocity tepla, pohody a mnohých ďalších príjemných emócií. Vyznačuje sa vynikajúcimi tepelno-technickými, mechanickými i estetickými vlastnosťami a preto niet divu, že drevo ani s príchodom moderných materiálov
                    nestratilo nič zo svojej atraktivity. Dnes vás však nebudeme trápiť vzájomným porovnávaním materiálov a na drevo sa pozrieme trochu iným pohľadom.
                </strong>
            </p>
            <h1>
                Neobyčajná všestrannosť
            </h1>
            <p>
                Našim lesom môžeme ešte stále vďačiť za dostatok kvalitného dreva. Pri správnej starostlivosti o lesy a pri rozumnom hospodárení s drevom sa ani v blízkej budúcnosti nemusíme obávať jeho nedostatku. Pravdou však je, že tento tradičný prírodný materiál nám akosi zovšednel a na rozdiel
                od našich predkov si ho nevážime tak, ako by sme mali. Drevo je pritom skutočne úžasné a všestranné.
            </p>
            <p>
                Drevo je základným materiálom, bez ktorého sa <a href="/katalog">nezaobíde výstavba montovaných domov</a>,
                <strong>
                    {"\u00A0"}no rovnako tak sa bez neho nezaobídu ani architekti vytvárajúci zaujímavé konštrukčné detaily.{"\u00A0"}
                </strong>
                Vytvorí útulné interiéry a dych vám zoberú tiež efektné drevené fasády. Vďaka peknej
                textúre, či ľahkej
                tvarovateľnosti a obrábateľnosti sa teší veľkému záujmu v nábytkárskom priemysle. A takto by sme mohli pokračovať ďalej.
            </p>
            <p>

                <strong>
                    Potenciál dreva je však oveľa väčší, ako si vôbec dokážete predstaviť.{"\u00A0"}
                </strong>
                V nasledujúcich riadkoch sa preto pozrieme na netradičnejšie spôsoby jeho využitia, o ktorých ste pravdepodobne doteraz netušili.
            </p>
            <h1>
                Využiť sa dá nielen kvalitné drevo, aj dendromasa je zaujímavá
            </h1>
            <p>
                Ak ste si doteraz mysleli, že drevo sa používa iba ako surovina na výrobu buničiny a papiera, alebo ako stavebný materiál, či materiál na výrobu luxusného nábytku, tak ste sa veľmi mýlili. Napokon spomeňte si, aké všetky výrobky z dreva doma bežne používate. Jeho využitie je však
                samozrejme oveľa pestrejšie a efektne dokážeme zužitkovať aj menej kvalitné drevo, či spracovanú biomasu.
            </p>
            <p>
                Drevená biomasa alebo presnejšie povedané dendromasa
                <strong>
                    {"\u00A0"}obsahuje hlavné zložky ako je celulóza, hemicelulóza a lignín.{"\u00A0"}
                </strong>
                Vedľajšie zložky z dreva tzv.
                <strong>
                    {"\u00A0"}extraktívne látky pozostávajú z éterických{"\u00A0"}
                </strong>
                alebo
                <strong>
                    {"\u00A0"}mastných olejov, aromatických uhľovodíkov, prchavých kyselín, prírodných farbív, trieslovín, glykozidov, živíc, bielkovín, tukov i zložených cukrov.{"\u00A0"}
                </strong>
                Čo je však podstatné, hlavné zložky dreva rovnako ako aj vedľajšie sa uplatnia v rôznych oblastiach priemyselnej výroby.
            </p>
            <h1>
                Toto ste určite nevedeli
            </h1>
            <p>
                Extraktívne látky z dreva majú vynikajúce antioxidačné a dezinfekčné účinky, ktoré dokáže oceniť potravinársky i kozmetický priemysel. V potravinárskom priemysle sa tieto zložky využívajú na dochucovanie pokrmov a ako
                <strong>
                    {"\u00A0"}aditíva do nealkoholických nápojov.{"\u00A0"}
                </strong>
                V kozmetickom zasa
                <strong>
                    {"\u00A0"}na výrobu antibakteriálnych mydiel alebo kozmetiky, no a s obľubou sú používané tiež pri vytváraní voňaviek, či parfumov.{"\u00A0"}
                </strong>
            </p>
            <p>
                Vlastnosti extraktívnych zložiek nenechal bokom ani farmaceutický priemysel a tieto látky sú súčasťou viacerých liečiv. Spomenúť môžeme
                <strong>
                    {"\u00A0"}lieky určené na liečbu reumatickej horúčky, sklerózy, vysokého krvného tlaku, či dokonca rakoviny.{"\u00A0"}
                </strong>
                Vďaka svojim účinkom sa osvedčili aj v boji proti vírusom a baktériám.
            </p>
            <p>
                Ani to však nie je ešte ani zďaleka všetko. V chemickom priemysle sú zložky dreva využívané
                <strong>
                    {"\u00A0"}pri výrobe plastifikátorov, mazadiel, emulgátorov a mazadiel.{"\u00A0"}
                </strong>
                Používané sú tiež pri výrobe
                <strong>
                    {"\u00A0"}lakov alebo náterových farieb.{"\u00A0"}
                </strong>
                Niektoré farby dokonca využívajú biocídne vlastnosti extraktívnych látok a tieto náterové hmoty sú napokon používané pri ochranných náteroch samotného dreva.
            </p>
            <p>
                Možnosti využitia lignínu a vedľajších zložiek dreva sme tým ešte samozrejme nevyčerpali. Tieto zložky sú súčasťou receptúr pri výrobe
                <strong>
                    {"\u00A0"}izolačných materiálov,{"\u00A0"}
                </strong>
                alebo
                <strong>
                    {"\u00A0"}kobercov a nezaobíde sa bez nich ani výroba textílií, či lepidiel.{"\u00A0"}
                </strong>
                Extraktívne látky tvoria
                <strong>
                    {"\u00A0"}súčasť tlačiarenských farieb{"\u00A0"}
                </strong>
                a v podobe spojiva ich môžeme nájsť aj
                <strong>
                    {"\u00A0"}v krmive zvierat.
                </strong>
            </p>
        </article>
    )
}

export default DrevoAkoMaterial
