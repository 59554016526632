import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {connect} from "react-redux";
import {useState} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {toast} from "react-toastify";
import {setDatabaseTexts} from "../../../redux/actions/databaseTexts";


function SimpleText({databaseTexts, name, handleClose, setDatabaseTexts}) {
    const [state, setState] = useState(name ? {...databaseTexts?.texts[name]} : {})

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "texts", name), {...state}, {merge: true})
            .then(() => {
                setDatabaseTexts({...databaseTexts, texts: {...databaseTexts.texts, [name]: {...state}}})
            })
        setState(name ? {...databaseTexts?.texts[name]} : {})
        handleClose()
    }

    return (
        <div className={classes.simpleText}>
            {Object.keys(databaseTexts?.texts[name])
                .sort(function (a, b) {
                    return a.localeCompare(b)
                })
                .map((item, index) => {
                    if (item !== "type") {
                        return (
                            <div key={item + index} className={classes.section}>
                                <h3>{`${name} ${item}`}</h3>
                                <textarea
                                    placeholder={`${name} ${item}`}
                                    defaultValue={databaseTexts?.texts[name][item]}
                                    onChange={(e) => setState({...state, [item]: e.target.value})}
                                />
                            </div>
                        )
                    } else return null
                })}
            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts
    }
}

export default connect(mapStateToProps, {setDatabaseTexts})(SimpleText)