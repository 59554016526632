import classes from "./AdminCMSNavbar.module.scss";
import plus3 from "../../../../assets/plus.png";
import people from "../../../../assets/peopleColor.png";
import exportFile from "../../../../assets/export.png";
import {Link} from "react-router-dom";
import {useState} from "react";


function AdminCMSNavbar() {
    const [tab, setTab] = useState(0)

    return (
        <ul className={classes.navbar}>
            <li onClick={() => setTab(0)}>
                <Link to="/admin/klienti/pridat">
                    <p>Pridať zákazníka</p>
                    <img src={tab === 1 ? plus3 : plus3} alt="klienti domov" className={classes.img}/>
                </Link>
            </li>
            <li onClick={() => setTab(1)}>
                <Link to="/admin/klienti/vsetci">
                    <p>Všetci zákazníci</p>
                    <img src={tab === 0 ? people : people} alt="pridať klienta" className={classes.img}/>
                </Link>
            </li>
            <li onClick={() => setTab(2)}>
                <Link to="/admin/klienti/export">
                    <p>Možnosti exportu</p>
                    <img src={tab === 2 ? exportFile : exportFile} alt="export klientov" className={classes.img}/>
                </Link>
            </li>
        </ul>
    )
}

export default AdminCMSNavbar