import classes from "./Navbar.module.scss";
import BackArrow from "../main/backArrow/BackArrow";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Menu2 as Menu} from "tabler-icons-react";
import {slide as Slide} from "react-burger-menu"


function Navbar({admin}) {
    let history = useHistory()

    const items = ["Home", "O nás", "Katalóg", "Realizácie", "Realizujeme", "Stupne výstavby", "Skladby stien", "Články", "Cenová ponuka", "Kontakt"]
    const itemsCZ = ["Home", "O nás", "Katalog", "Realizace", "Realizujeme", "Stupně konstrukce", "Skladby stěn", "Články", "Cenová nabídka", "Kontakt"]
    const urls = ["/", "/o-nas", "/katalog", "/realizacie", "/realizujeme", "/stupne-vystavby", "/clanky/skladby-stien", "/clanky", "/cenova-ponuka", "",]

    async function handleRedirect(url, item) {
        if (item === "Kontakt") {
            if (history.location.pathname !== "/") await history.push("/")
            setTimeout(() => {
                const element = document.getElementById(item)
                const elementPosition = element.getBoundingClientRect().top
                window.scrollTo({top: elementPosition, behavior: "smooth"})
            }, 1000)
        } else history.push(url)
    }

    const styles = {
        bmBurgerButton: {
            position: "absolute",
            width: "36px",
            height: "30px",
            right: "10px",
            top: "10px",
            marginTop: admin ? 50 : 0
        },
        bmBurgerBars: {
            background: "#373a47"
        },
        bmBurgerBarsHover: {
            background: "#a90000"
        },
        bmCrossButton: {
            height: "24px",
            width: "24px"
        },
        bmCross: {
            background: "#bdc3c7"
        },
        bmMenuWrap: {
            top: 0,
            margin: 0,
            padding: 0,
            position: "absolute",
            width: "100%",
        },
        bmMenu: {
            background: "white",
            padding: "2.5em 1.5em 0",
            fontSize: "1.15em",
            margin: 0
        },
        bmMorphShape: {
            fill: "#373a47"
        },
        bmItemList: {
            color: "#b8b7ad",
            padding: "0.8em"
        },
        bmItem: {
            display: "inline-block"
        },
        bmOverlay: {
            background: "rgba(0, 0, 0, 0.3)",
            height: "100%"
        }
    }

    return (
        <nav className={classes.navbar}>
            <ul className={classes.list}>
                {(window.location.host.split(".").pop() !== "cz" ? items : itemsCZ).map((item, index) => {
                    return (
                        <li
                            key={item}
                            onClick={() => handleRedirect(urls[index], item)}
                            style={{borderBottom: history.location.pathname === urls[index] ? "2px solid black" : "none"}}
                        >
                            {item}
                        </li>
                    )
                })}
            </ul>

            <BackArrow/>

            <span className={classes.slider}>
                <Slide styles={styles} customBurgerIcon={<Menu className={classes.mobile}/>}>
                    <ul className={classes.list2}>
                        {(window.location.host.split(".").pop() !== "cz" ? items : itemsCZ).map((item, index) => {
                            return (
                                <li key={item} onClick={() => handleRedirect(urls[index], item)}>
                                    {item}
                                </li>
                            )
                        })}
                    </ul>
                </Slide>
            </span>
        </nav>
    )
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(Navbar)
