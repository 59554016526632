import classes from "./Articles.module.scss";
import img1 from "../../assets/dotacia-tepelne-cerpadlo-bruno-768x336.jpg";
import {useEffect} from "react";


function StatnaDotacia() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>Viete ako získať štátnu dotáciu na tepelné čerpadlo?</title>
            <img src={img1} alt="Viete ako získať štátnu dotáciu na tepelné čerpadlo?"/>
            <p>
                <strong>
                    Prvá etapa štátnej podpory zameraná na inštaláciu zariadení využívajúcich obnoviteľné zdroje energie tento rok vrcholí a národný projekt Zelená domácnostiam končí. Slovenská inovačná a energetická agentúra (SIEA) však ešte tento rok stihne vo forme poukážok medzi domácnosti
                    prerozdeliť približne 13 000 000 eur. Ak sa chcete aj vy uchádzať o poskytnutie príspevku na inštaláciu tepelného čerpadla, tak teraz máte jedinečnú príležitosť. Mali by ste sa však poponáhľať. Na oficiálnej webovej stránke národného projektu Zelená domácnostiam už boli totiž
                    zverejnené termíny konania jednotlivých kôl. Prvé z nich sa bude konať už začiatkom februára.
                </strong>
            </p>

            <p>
                Žiadosť o poukážku sa podáva prostredníctvom informačného systému prístupného zo stránky spomínaného národného projektu, ktorú je možné vyplniť iba počas konania aktuálneho kola. V rámci dotácie potom
                <strong>
                    {"\u00A0"}môžete získať 370 € na 1 kW výkonu tepelného čerpadla{"\u00A0"}
                </strong>,
                pričom
                <strong>
                    {"\u00A0"}maximálna výška dotácie predstavuje 3 700 €.{"\u00A0"}
                </strong>
                Aby však bola žiadosť o vydanie poukážky na inštaláciu tepelného čerpadla úspešná, poprípade aby bola vydaná poukážka platná budete musieť urobiť niekoľko krokov, ktorými vás teraz prevedieme.
            </p>
            <h1>
                1. Oboznámenie sa s podmienkami podpory
            </h1>
            <p>
                Pred podaním žiadosti sa uistite či vaša domácnosť spĺňa podmienky na získanie dotácie. Vo všeobecnosti platí, že o dotáciu na tepelné čerpadlo budete môcť požiadať ak ste fyzická osoba, ktorá:
            </p>
            <ul>
                <li>je vlastníkom rodinného domu</li>
                <li>je bezpodielovým vlastníkom rodinného domu</li>
                <li> je podielovým spoluvlastníkom rodinného domu a má oprávnenie rozhodovať o hospodárení so spoločnou vecou podľa väčšiny podielov</li>
            </ul>
            <p>
                V praxi to znamená, že
                <strong>
                    {"\u00A0"}preukážete vlastníctvo alebo spoluvlastníctvo rodinného alebo bytového domu.{"\u00A0"}
                </strong>
                Poukážku na inštaláciu tepelného čerpadla
                <strong>
                    {"\u00A0"}môžete získať aj na rozostavanú stavbu rodinného domu,{"\u00A0"}
                </strong>
                avšak táto stavba musí byť zapísaná na liste vlastníctva. K listu vlastníctva bude potrebné pripojiť kópiu právoplatného stavebného povolenia.
            </p>
            <h1>
                2. Oslovenie oprávneného zhotoviteľa a výber tepelného čerpadla
            </h1>
            <p>
                Oprávnený zhotoviteľ bude realizovať inštaláciu tepelného čerpadla. Prv ako si podáte žiadosť o vydanie poukážky môžete s oprávneným zhotoviteľom prejsť ešte raz podmienky získania dotácie a uistiť sa či ich spĺňate. Oprávnenému zhotoviteľovi môžete predložiť potrebnú technickú
                dokumentáciu rodinného domu. Najlepšie bude ak mu predložíte aj pôdorys domu a projekt vykurovacej sústavy s vyčíslením tepelných strát. Na základe predložených dokladov vám oprávnený zhotoviteľ vypracuje
                <strong>
                    {"\u00A0"}technický návrh riešenia inštalácie tepelného čerpadla a cenovú ponuku.{"\u00A0"}
                </strong>
                Technický návrh vám neskôr poslúži pri vypĺňaní žiadosti.
            </p>
            <p>
                S oprávneným zhotoviteľom konzultujte aj výber vhodného tepelného čerpadla, ktoré bude spĺňať požiadavky vašej domácnosti a technické podmienky kladené na tieto zariadenia. <strong>Tepelné čerpadlo sa totiž musí nachádzať na zozname podporovaných zariadení.</strong> Zoznam
                podporovaných zariadení je
                pravidelne aktualizovaný a nachádzajú sa na ňom kvalitné zariadenia s primeraným výkonom i dostatočnou životnosťou.
            </p>
            <h1>
                3. Uzatvorenie zmluvy s oprávneným zhotoviteľom
            </h1>
            <p>
                V prípade, že vám vyhovuje technický návrh a cenová ponuka, môžete s oprávneným zhotoviteľom uzavrieť zmluvu. Pred uzavretím písomnej zmluvy sa však ubezpečte, že v stanovenom termíne bude tepelné čerpadlo možné oprávneným zhotoviteľom nielen dodať, ale aj nainštalovať. Zariadenie
                uvedené na poukážke sa totiž nemôže meniť a musí sa zhodovať s nainštalovaným. Vo všeobecnosti sa odporúča do zmluvy zakomponovať rady SIEA, ktoré sú k dispozícii na internetovej stránke národného projektu Zelená domácnostiam v časti „Odporúčania pre domácnosti“.
            </p>
            <h1>
                Podanie žiadosti o vydanie poukážky
            </h1>
            <p>
                Po oboznámení sa s podmienkami dotácie, oslovení oprávneného zhotoviteľa, vybratí vhodného tepelného čerpadla a uzatvorení zmluvy sme sa napokon dostali k podaniu žiadosti o vydanie poukážky. Ako sme už v úvode spomínali, tú si môžete podať iba počas prebiehajúceho kola. Kedy to bude
                teda možné? Na základe zverejneného harmonogramu pre prvý štvrťrok sa pripravte na tieto termíny podávania žiadostí.
                <strong>
                    {"\u00A0"}Jednotlivé kolá sa otvárajú v stanovený deň o 15-tej hodine.{"\u00A0"}
                </strong>
            </p>
            <p>
                Najbližší termín na podanie žiadostí môžu teda využiť žiadatelia, ktorý pochádzajú mimo Bratislavského samosprávneho kraja. Žiadosť si podajte cez informačný systém webovej stránky národného projektu Zelená domácnostiam. Stránka disponuje presným manuálom, ktorý vám vypĺňanie
                žiadosti uľahčí.
            </p>
            <p>
                Po vyplnení formulára vám bude na vašu e-mailovú adresu doručená správa. Nezabudnite preto kliknúť na odkaz ktorý bude vložený do správy. Potvrdíte tým váš záujem o vydanie poukážky a až vtedy začne byť vaša žiadosť posudzovaná.
            </p>
            <h1>
                Čo bude nasledovať po podaní žiadosti?
            </h1>
            <p>
                Po posúdení žiadostí o vydanie poukážky budú žiadateľom na ich e-mailové adresy doručené informácie o schválení alebo neschválení dotácie. V prípade schválenia vašej žiadosti vám najneskôr do 30 dní od potvrdenia žiadosti o vydanie poukážky bude doručená zmluva o poskytnutí príspevku
                a užívaní zariadenia. Spolu so zmluvou vám bude doručená poukážka. Nezabudnite, že poukážku si musíte u oprávneného zhotoviteľa uplatniť najneskôr do 30 dní od dátumu vydania a inštalácia musí byť vykonaná do 3 mesiacov. Dátum vydania bude uvedený na poukážke. Dotácia môže byť
                použitá na zakúpenie tepelného čerpadla, akumulačného zásobníka vody, komponentov potrebných na prevádzku tepelného čerpadla, montážneho materiálu a na úhradu zemných vrtov, montážnych prác, skúšok a revízií. Tepelné čerpadlo potom budete musieť prevádzkovať minimálne dva roky.
            </p>
            <h1>
                Tepelné čerpadlá stoja za trochu námahy
            </h1>
            <p>
                Dotácia od štátu rozhodne stojí nielen za úvahu, ale aj za tú trochu námahy, ktorá je spojená s vydaním poukážky. Štátna pomoc pri inštalácii zariadení využívajúcich obnoviteľné zdroje bude s najväčšou pravdepodobnosťou pokračovať aj v budúcnosti, ale jej výška a podoba je zatiaľ
                nejasná. Ak ste teda uvažovali o inštalácii tepelného čerpadla, nečakajte a začnite konať. Tepelné čerpadlá vďaka využívaniu obnoviteľnej energie prispievajú k efektívnemu znižovaniu prevádzkových nákladov na prípravu teplej vody i vykurovanie interiéru montovaných domov a už onedlho
                môžu zraziť účty za vykurovanie na minimum aj vo vašej domácnosti.
            </p>
        </article>
    )
}

export default StatnaDotacia
