import Header from "../../components/admin/header/Header";
import Navbar from "../../components/navbar/Navbar";
import GalleryPage from "../../components/gallery/GalleryPage";


function Realizations() {
    return (
        <span>
            <Header/>
            <Navbar/>
            <GalleryPage url="/realizacie" path="realizacie"/>
        </span>
    )
}


export default Realizations
