import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCyS3HrwkBqARDBDteoqSZHbGgDFy85N-4",
    authDomain: "drevostavby-bruno.firebaseapp.com",
    projectId: "drevostavby-bruno",
    storageBucket: "drevostavby-bruno.appspot.com",
    messagingSenderId: "443423083852",
    appId: "1:443423083852:web:80c53dd9eac75fde6913e9",
    measurementId: "G-G458SJ2EVR"
}

const firebaseApp = initializeApp(firebaseConfig)
getAnalytics(firebaseApp)
export default firebaseApp