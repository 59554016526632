import classes from "./RemoveImageFromGalleryItem.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {Trash} from "tabler-icons-react";
import {connect} from "react-redux";
import {useState} from "react";
import {Dialog, DialogContent} from "@mui/material";
import {doc, getFirestore, setDoc} from "firebase/firestore";


function RemoveImageGalleryItem({databaseCatalog, databaseRealizacie, itemName, imageUrl, page, admin}) {
    const [opened, setOpened] = useState(false)

    function galleryItem() {
        if (page) {
            if (page === "katalog" && databaseCatalog?.catalog[itemName]) {
                return databaseCatalog?.catalog[itemName]
            } else if (page === "realizacie" && databaseRealizacie?.realizacie[itemName]) {
                return databaseRealizacie?.realizacie[itemName]
            }
        }
    }

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        if (galleryItem()) {
            const firestore = getFirestore(firebaseApp)
            await setDoc(doc(firestore, page, itemName), {...galleryItem(), images: galleryItem()?.images.filter(e => e !== imageUrl)}, {merge: true})
            setOpened(false)
        }
    }

    function handleEsc(e) {
        if (e.key === "Escape") {
            setOpened(false)
        }
    }

    return admin ? (
        <div>
            <Dialog
                open={opened}
                fullWidth
                className={classes.dialogWrapper}
                onKeyUp={handleEsc}
                transitionDuration={1000}
            >
                <DialogContent className={classes.dialogWrapper}>
                    <h1>{`Ste si istý, že chcete vymazať obrazok ${itemName} z ${page}`}</h1>

                    <div className={classes.buttons}>
                        <button onClick={submitWithToast}>ÁNO</button>
                        <button onClick={() => setOpened(false)}>NIE</button>
                    </div>

                </DialogContent>
            </Dialog>

            <Trash onClick={() => setOpened(true)} className={classes.removeIcon}/>
        </div>
    ) : null
}

const mapStateToProps = state => {
    return {
        admin: state.admin,
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie
    }
}

export default connect(mapStateToProps)(RemoveImageGalleryItem)