import classes from "./ComponentWrapper.module.scss";


function ComponentWrapper({component1, component2, switchMobile}) {
    return (
        <div className={classes.wrapper}>
            <div className={classes.desktop}>{component1}</div>
            <div className={classes.desktop}>{component2}</div>

            <div className={classes.mobile}>{switchMobile ? component2 : component1}</div>
            <div className={classes.mobile}>{switchMobile ? component1 : component2}</div>
        </div>
    )
}

export default ComponentWrapper


