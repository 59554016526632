import classes from "./Components.module.scss";
import Best from "./main/best/Best";
import Home from "./main/home/Home";
import WhyUs from "./main/whyUs/WhyUs";
import Navbar from "./navbar/Navbar";
import AboutUs from "./main/aboutUs/AboutUs";
import Contact from "./main/contact/Contact";
import HomeImg from "./main/home/HomeImg";
import WhyUsText from "./main/whyUs/WhyUsText";
import AboutUsText from "./main/aboutUs/AboutUsText";
import InteractiveMap from "./main/interactiveMap/InteractiveMap";
import ComponentWrapper from "./componentWrapper/ComponentWrapper";
import PriceOfferBanner from "./priceOfferBanner/PriceOfferBanner";
import CatalogHomePageImg from "./main/catalogHomePage/CatalogHomePageImg";
import CatalogHomePageText from "./main/catalogHomePage/CatalogHomePageText";
import LevelsOfConstruction from "./main/levelsOfConstruction/LevelsOfConstruction";
import RealizacieHomePageImg from "./main/realizacieHomePage/RealizacieHomePageImg";
import RealizacieHomePageText from "./main/realizacieHomePage/RealizacieHomePageText";
import LevelsOfConstructionText from "./main/levelsOfConstruction/LevelsOfConstructionText";
import {useEffect} from "react";


function Components() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <span className={classes.layout}>
            <Navbar/>
            <ul className={classes.components}>
                <li>
                    <ComponentWrapper switchMobile component1={<Home/>} component2={<HomeImg/>}/>
                </li>
                <li className={classes.mobile}>
                    <h1>O nás</h1>
                </li>
                <li>
                    <ComponentWrapper component1={<AboutUs/>} component2={<AboutUsText/>}/>
                </li>
                <li className={classes.mobile}>
                    <h1>{window.location.host.split(".").pop() !== "cz" ? "Katalóg" : "Katalog"}</h1>
                </li>
                 <li>
                    <ComponentWrapper switchMobile component1={<CatalogHomePageText/>} component2={<CatalogHomePageImg/>}/>
                </li>
                 <li className={classes.mobile}>
                     <h1>{window.location.host.split(".").pop() !== "cz" ? "Realizácie" : "Realizace"}</h1>
                 </li>
                <li>
                    <ComponentWrapper component1={<RealizacieHomePageImg/>} component2={<RealizacieHomePageText/>}/>
                </li>
                <li className={classes.mobile}>
                    <h1>{window.location.host.split(".").pop() !== "cz" ? "Prečo s nami" : "Proč s námi"}</h1>
                </li>
                <li>
                    <ComponentWrapper switchMobile component1={<WhyUsText/>} component2={<WhyUs/>}/>
                </li>
                <li className={classes.mobile}>
                    <h1>{window.location.host.split(".").pop() !== "cz" ? "Stupne výstavby" : "Stupně výstavby"}</h1>
                </li>
                <li>
                    <ComponentWrapper component1={<LevelsOfConstruction/>} component2={<LevelsOfConstructionText/>}/>
                </li>
                <li>
                    <PriceOfferBanner/>
                </li>
                <li>
                    <Best/>
                </li>
                <li>
                    <InteractiveMap/>
                </li>
                <li id="Kontakt">
                    <Contact/>
                </li>
            </ul>
        </span>
    )
}

export default Components
