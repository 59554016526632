import classes from "./EditWrapper.module.scss";
import DialogWrapper from "../dialogWrapper/DialogWrapper";
import {Edit} from "tabler-icons-react";
import {connect} from "react-redux";
import {useState} from "react";


function EditWrapper({children, fullHeight, page, path, name, value, admin, dialogCase, centered, smallIcon}) {
    const [open, setOpen] = useState(false)

    return admin ?
        <div
            className={classes.wrapper}
            style={{
                height: fullHeight ? "100%" : "fit-content",
                display: centered ? "flex" : undefined,
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <button onClick={() => setOpen(true)}>{smallIcon ? <Edit className={classes.editIcon}/> : "upraviť"}</button>
            <DialogWrapper
                open={open}
                handleClose={() => setOpen(false)}
                path={path}
                page={page}
                name={name}
                value={value}
                dialogCase={dialogCase}
            />
            {children}
        </div> :
        <span
            className={classes.span}
            style={{
                height: fullHeight ? "100%" : "fit-content",
                display: centered ? "flex" : undefined,
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            {children}
        </span>
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(EditWrapper)


