import classes from "./PriceOfferCTA.module.scss";
import img from "../../assets/cp ikona.png";
import {useHistory} from "react-router-dom";


function PriceOfferCTA() {
    let history = useHistory()

    function redirect() {
        history.push("/cenova-ponuka")
    }

    return (
        <div className={classes.wrapper} onClick={redirect}>
            <img src={img} alt="cenová ponuka"/>
        </div>
    )
}

export default PriceOfferCTA
