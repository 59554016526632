import classes from "./SearchBar.module.scss";
import {Input} from "@mui/material";


function SearchBar({value, onType, onSubmit, placeholder}) {
    function handleEnter(e) {
        if (e.key === "Enter") {
            onSubmit()
        }
    }

    return (
        <div className={classes.searchBar} onKeyDown={handleEnter}>
            <Input
                className={classes.input}
                placeholder={placeholder}
                size="lg"
                autoFocus
                value={value}
                onChange={onType}
                disableUnderline
            />
        </div>
    )
}

export default SearchBar