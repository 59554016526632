import classes from "./AddImages.module.scss";
import Dropzone from "react-dropzone";
import dragAndDrop from "../../../assets/dragAndDrop.png";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {useState} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {getDownloadURL, getStorage, ref, updateMetadata, uploadBytes} from "firebase/storage";


function AddImages({admin, item}) {
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])

    const firestore = getFirestore(firebaseApp)
    const storage = getStorage()

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Obrázok úspešne pridaný. Skôr ako budete pokračovať prosím refreshnite stránku.",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        setLoading(true)
        const imageRefs = []

        for (const image of images) {
            const storageImagesRef = ref(storage, image.name)
            await uploadBytes(storageImagesRef, image.buffer).then(async e => {
                const test = await getDownloadURL(e.ref)
                await updateMetadata(storageImagesRef, {cacheControl: "public,max-age=9999999999999999999"})
                imageRefs.push(test)
            })
        }

        await setDoc(doc(firestore, "realizujeme", item.name), {...item, images: item?.images.concat(imageRefs)}, {merge: true})
        setImages([])
        setLoading(false)
    }


    function onDrop(files) {
        let image = []
        for (const file of files) {
            if (file.type === "application/jpg" || file.type === "application/jpeg" || file.type === "application/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                image.push({buffer: file, name: file.name})
            }
        }
        setImages([...images, ...image])
    }

    return admin ? (
        <div className={classes.admin}>
            <h2>Upraviť aktuálnu položku</h2>
            <span>
                <p>Nové obrázky budú <strong>pridané</strong> k existujúcim obrázkom</p>
                <p>Prosím uistite sa, že nové obrázky majú unikátny názov pred uploadom</p>
            </span>
            <Dropzone
                multiple
                onDropAccepted={onDrop}
                accept={"image/png, image/jpg, image/jpeg, application/png, application/jpg, application/jpeg"}
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <img src={dragAndDrop} alt="dragAndDrop" className={classes.dragAndDrop}/>
                    </div>
                )}
            </Dropzone>

            {loading ? "Uploading..." :
                <div className={classes.upload}>
                    <p>Položky na uploadnutie</p>
                    <ul className={classes.confirm}>
                        <li style={{display: images.length > 0 ? "flex" : "none"}}>Fotogaléria ({images.length} ks)</li>
                    </ul>
                    <button onClick={submitWithToast}>Uploadnúť</button>
                </div>
            }
        </div>
    ) : null
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(AddImages)
