import classes from "./ChangeTitleImage.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {doc, getFirestore, setDoc} from "firebase/firestore";


function ChangeTitleImage({admin, item, newImage}) {
    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Obrázok úspešne zvolený",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "realizujeme", item.name),
            {
                name: item?.name,
                description: item?.description,
                type: item?.type,
                info: item?.info,
                pdf: item?.pdf,
                images: item?.images,
                mainImage: newImage,
                timestamp: item?.timestamp
            }, {merge: true})
    }

    return admin ? (
        <button onClick={submitWithToast} className={classes.change}>
            Nastaviť ako hlavný obrázok
        </button>
    ) : null
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps)(ChangeTitleImage)
