import classes from "./FullGallery.module.scss";
import Info2 from "../info2/Info2";
import Loading from "../../../router/screens/loading/Loading";
import DeleteItem from "../deleteItem/DeleteItem";
import firebaseApp from "../../../firebase";
import PriceOfferCTA from "../../priceOfferCTA/PriceOfferCTA";
import {Edit} from "tabler-icons-react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {setDatabaseCatalog} from "../../../redux/actions/databaseCatalog";
import {useEffect, useState} from "react";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import FreeProjectPopup from "../freeProjectPopup/FreeProjectPopup";
import FreeProjectOfferCTA from "../../freeProjectOfferCTA/FreeProjectOfferCTA";


function FullGalleryCatalog({databaseCatalog, setDatabaseCatalog, catalogRefs, admin}) {
    let history = useHistory()
    const firestore = getFirestore(firebaseApp)
    const [loading, setLoading] = useState(true)
    const [rooms, setRooms] = useState([])

    const url = "katalog"
    const urlBase = "/katalog"

    useEffect(() => {
        if (!databaseCatalog.catalog) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [databaseCatalog.catalog])

    useEffect(() => {
        async function loadDatabase() {
            if (!databaseCatalog.catalog) {
                await loadCatalog()
            }
        }

        async function loadCatalog() {
            if (!databaseCatalog?.catalog && catalogRefs) {
                let catalog = {}
                for (const item of catalogRefs) {
                    const snapshot = await getDoc(doc(firestore, `katalog/${item.id}`))
                    catalog[item.id] = snapshot.data()
                }

                if (databaseCatalog && databaseCatalog.catalog) {
                    setDatabaseCatalog({...databaseCatalog, catalog: {...databaseCatalog.catalog, ...catalog}})
                } else {
                    setDatabaseCatalog({catalog: catalog})
                }
            }
        }

        loadDatabase().catch()
    }, [firestore, databaseCatalog, catalogRefs])

    function handleRedirect(item) {
        if (typeof item === "string") {
            history.push(`${urlBase}/${item}`)
        } else {
            history.push(urlBase)
        }
    }

    const roomOptions = [2, 3, 4, 5]

    function handleCheckbox(value) {
        let newRooms = [...rooms]
        if (newRooms.includes(value)) {
            newRooms.splice(rooms.indexOf(value), 1)
        } else {
            newRooms.push(value)
        }
        setRooms([...newRooms])
    }

    function searchByRoom() {
        return (
            <div>
                <p>Filtrovať podľa počtu izieb</p>

                <div className={classes.searchList}>
                    {roomOptions.map(option => {
                        return (
                            <div key={option}>
                                <input type="checkbox" checked={rooms.includes(option)} onChange={() => handleCheckbox(option)}/>
                                <label>{`${option} ${option < 5 ? "izby" : "izieb"}`}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return !loading || databaseCatalog.catalog ? (
        <div className={classes.wrapper}>
            {/*<FreeProjectOfferCTA/>*/}
            <PriceOfferCTA/>

            {/*<FreeProjectPopup/>*/}

            {searchByRoom()}

            <div className={classes.fullGallery}>
                {databaseCatalog?.catalog && Object.keys(databaseCatalog?.catalog).sort(function (a, b) {
                    return databaseCatalog?.catalog[a]?.timestamp > databaseCatalog?.catalog[b]?.timestamp ? -1 : 1;
                }).map((item, index) => {
                    const show = rooms.length === 0 || rooms.includes(databaseCatalog?.catalog[item]?.info["počet izieb"])
                    return show ? (
                        <div key={index} className={classes.item} id={databaseCatalog?.catalog[item]?.name}>
                            <Edit style={{display: admin ? "block" : "none"}} onClick={() => history.push(`${urlBase}/${databaseCatalog?.catalog[item]?.name}`)} className={classes.editIcon}/>
                            <DeleteItem itemName={databaseCatalog?.catalog[item]?.name} page={url}/>
                            <span onClick={() => handleRedirect(item)}>
                                <div className={classes.titleAndImg}>
                                     <h1>{databaseCatalog?.catalog[item]?.name}</h1>
                                     <div className={classes.imageWrapper} onClick={() => handleRedirect(item)}>
                                         <img src={databaseCatalog?.catalog[item]?.mainImage} alt={databaseCatalog?.catalog[item]?.name} loading="lazy" className={classes.oneImageCatalog}/>
                                         <div className={classes.test}>
                                            <div className={`${classes.title} ${classes.text}`}>
                                                {databaseCatalog?.catalog[item]?.info ?
                                                    <div className={classes.info}>
                                                        <Info2 itemInfo={databaseCatalog?.catalog[item]?.info}/>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    ) : null
                })}
            </div>
        </div>
    ) : <Loading/>
}


const mapStateToProps = state => {
    return {
        admin: state.admin,
        databaseCatalog: state.databaseCatalog,
        catalogRefs: state.catalogRefs,
    }
}

export default connect(mapStateToProps, {
    setDatabaseCatalog
})(FullGalleryCatalog)
