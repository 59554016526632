import {ADMIN} from "../types";


const AdminReducer = (state = false, action) => {
    switch (action.type) {
        case ADMIN:
            return action.payload
        default:
            return state
    }
}

export default AdminReducer





