import classes from "./Header.module.scss";
import img1 from "../../../assets/katalog.png";
import img2 from "../../../assets/domcek.png";
import img3 from "../../../assets/clients icon.png";
import firebaseApp from "../../../firebase";
import {connect} from "react-redux";
import {getAuth} from "firebase/auth";
import {setAdmin} from "../../../redux/actions/admin";
import {useHistory} from "react-router-dom";


function Header({admin, setAdmin}) {
    let history = useHistory()

    function handleRedirect(url) {
        history.push(url)
    }

    async function logout() {
        await getAuth(firebaseApp).signOut()
        setAdmin(false)
    }

    return admin ? (
        <div className={classes.header}>
            <nav>
                <div
                    className={classes.item}
                    onClick={() => handleRedirect("/admin/katalog")}
                    style={{backgroundColor: history.location.pathname === "/admin/katalog" ? "var(--dark-red)" : "var(--red)"}}
                >
                    <p>Úpravy v katalógu</p>
                    <img src={img1} alt="Úpravy v katalógu"/>
                </div>
                <div
                    className={classes.item}
                    onClick={() => handleRedirect("/admin/realizacie")}
                    style={{backgroundColor: history.location.pathname === "/admin/realizacie" ? "var(--dark-red)" : "var(--red)"}}>
                    <p>Úpravy v realizáciách</p>
                    <img src={img2} alt="Úpravy v realizáciách"/>
                </div>
                <div
                    className={classes.item}
                    onClick={() => handleRedirect("/admin/klienti")}
                    style={{backgroundColor: history.location.pathname === "/admin/klienti" ? "var(--dark-red)" : "var(--red)"}}
                >
                    <p>Klienti</p>
                    <img src={img3} alt="Klienti"/>
                </div>
            </nav>
            <div
                className={classes.admin}
                onClick={() => logout()}
            >
                Odhlásiť
            </div>
            <div
                className={classes.admin}
                onClick={() => handleRedirect("/")}
                style={{backgroundColor: history.location.pathname === "/" ? "var(--dark-red)" : "var(--red)"}}
            >
                Admin mód
            </div>
        </div>
    ) : null
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps, {setAdmin})(Header)


