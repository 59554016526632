import classes from "./Best.module.scss";


function Best() {
    return (
        <span className={classes.best}>
            <h1>
                {window.location.host.split(".").pop() !== "cz" ?
                    "Sme jednotka na slovenskom trhu" :
                    "JSME JEDNIČKOU NA SLOVENSKÉM TRHU"
                }
            </h1>
        </span>
    )
}

export default Best
