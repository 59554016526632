import classes from "./Articles.module.scss";
import img1 from "../../assets/drevodom-768x336.jpg";
import {useEffect} from "react";


function OsemDovodov() {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <article className={classes.article}>
            <title>8 presvedčivých dôvodov, prečo povedať drevostavbe ÁNO</title>
            <img src={img1} alt="8 presvedčivých dôvodov, prečo povedať drevostavbe ÁNO"/>
            <p>
                <strong>
                    Ešte stále na Slovensku prevláda názor, že najlepšou voľbou materiálu pri stavbe rodinného domu je tehla, pretože murovaný dom je pevný, kvalitný a vydrží mnoho rokov. Murovaný dom ešte dlho ostane klasikou, ale v poslednom období sa často môžete stretnúť s montovanými domami.
                    Poďme sa teda na ne pozrieť bližšie.
                </strong>
            </p>
            <h1>
                Drevostavby
            </h1>
            <p>
                Pre mnohých sú montované len domami bez budúcnosti, pretože drevo považujú za materiál s nízkou odolnosťou voči chladu, hluku a ohňu. Drevo je však materiál s úžasnými vlastnosťami a do stavebníctva jednoducho patrí. Drevostavba je stavba, ktorá používa drevo a materiály na jeho báze
                pre svoju nosnú konštrukciu.
                <strong>
                    {"\u00A0"}Drevo na konštrukcie prešlo za posledné roky značným vývojom.{"\u00A0"}
                </strong>
                Preto je považované za rovnocenný materiál k ostatným materiálom používaným pri výstavbe domov. Poďme sa teda pozrieť na 8 dôvodov, prečo sa rozhodnúť pre takýto dom.
            </p>
            <h1>
                1. Montovaný dom je ekologický
            </h1>
            <p>
                Konštrukčný základ montovaných domov je z dreva, čo im dodáva ekologický rozmer.
                <strong>
                    {"\u00A0"}Drevo je materiál, ktorý je obnoviteľný, recyklovateľný i ekologický.{"\u00A0"}
                </strong>
                Ponúka to, čo žiadny iný stavebný materiál. Slúži človeku počas svojho života, keď spotrebováva CO2 a produkuje
                kyslík. Potom je z
                neho stavebný materiál s bezkonkurenčnými mechanickými vlastnosťami vzhľadom na svoju hmotnosť. Patrí medzi obnoviteľné zdroje materiálu, pretože po vyťažení sa vysadí a o niekoľko desiatok rokov je znovu k dispozícii na výstavbu. Je samozrejme aj nosičom energie, pretože pri jeho
                likvidácii sa získava energia.
            </p>
            <p>
                2. Rýchla výstavba
            </p>
            <p>
                Výstavba montovaného domu je oproti murovaným domom rýchlejšia, pretože pri nej odpadajú mokré stavebné postupy. V takomto dome sa dá reálne bývať aj za 3 mesiace. Samozrejme rýchlosť výstavby závisí aj od zvoleného typu konštrukčného systému. Podstatný rozdiel v rýchlosti je hlavne
                pri poschodových domoch. Výstavbu montovaného domu môžete realizovať aj v akomkoľvek ročnom období, čo je veľmi atraktívne.
            </p>
            <h1>
                3. Zdravé bývanie
            </h1>

            <p>
                Medzi ďalšie presvedčivé dôvody, prečo voliť drevostavbu, je aj zdravé bývanie v nej. Keďže ide o suchý proces výstavby, dá sa v nej bývať okamžite. Môžete teda vylúčiť aj chyby spôsobené vplyvom technologickej vlhkosti. Vďaka drevu je zabezpečená v budove príjemná tepelná a
                vlhkostná klíma.
                <strong>
                    {"\u00A0"}Drevené domy majú schopnosť regulovať vlhkosť v interiéri.{"\u00A0"}
                </strong>
                Drevo ju vynikajúco absorbuje a naopak, keď je v interiéri veľmi suchý vzduch, postupne vlhkosť uvoľňuje naspäť do interiéru. Zabránite tým tvorbe plesní, ktoré škodia zdraviu. Nesmiete
                samozrejme zanedbať
                pravidelné vetranie.
            </p>

            <h1>
                4. Nižšia cena
            </h1>

            <p>
                Ďalším dôvodom pre montovaný dom je jeho nižšia cena. Aj keď je cena montovanej drevostavby rádovo nižšia len o 5 – 10 % oproti murovanému domu, na čo netreba zabúdať je, že pri montovanom dome máte presnú kalkuláciu a cena je dopredu známa. Dokonca pri objednaní montovanej
                drevostavby z <a href="/katalog">katalógu stavebnej firmy</a>, máte istotu, že zmluvnú cenu stavebná firma musí dodržať. Ku všetkému treba zarátať aj rýchlosť nasťahovania sa do tohto domu. Keď bývate v podnájme a súri vás čas výstavby, je drevostavba výhodným riešením. Náklady,
                ktoré by ste ešte platili za drahý podnájom môžete odrátať od stavby montovaného domu, čím v konečnom dôsledku ešte ušetríte.
            </p>

            <h1>
                5. Dobré tepelno-izolačné vlastnosti
            </h1>

            <p>
                Ďalšou výhodou montovaného domu je, že
                <strong>
                    {"\u00A0"}pri rovnakej šírke obvodovej steny poskytuje oveľa vyšší stupeň tepelnej izolácie ako murovaný dom.{"\u00A0"}
                </strong>
                Ak chcete postaviť murovaný dom v zmysle súčasných požiadaviek, potrebujete postaviť stenu hrubú minimálne 30 cm s 20 cm
                izolačnou vrstvou. Pri
                montovanom dome na dosiahnutie potrebného tepelného odporu postačia užšie steny s hrúbkou 27 – 30 cm. Skladby obvodových konštrukcií drevostavieb dosahujú tepelný odpor R = 5,5 m2.K/W. Takáto vysoká hodnota znamená nízku spotrebu tepla na vykurovanie a v lete zase spotrebu energie na
                chladenie.
            </p>

            <h1>
                6. Väčší vnútorný priestor
            </h1>

            <p>
                S hrúbkou obvodových múrov ide ruka v ruke aj úžitková plocha na rovnako veľkom pôdoryse domu. Väčší vnútorný priestor montovaného domu oproti klasickému domu vám zaistí lepšie využitie zastavanej plochy. Možno sa vám to môže zdať zanedbateľné, avšak
                <strong>
                    {"\u00A0"}aby ste mali rovnakú úžitkovú plochu montovaného domu ako v murovanom dome, môžete postaviť dom menší.{"\u00A0"}
                </strong>
                Alebo získate pri rovnakom pôdoryse priestor navyše, napríklad s celkovou plochou 100 m2 získate 10 m2 veľkú izbu naviac. Privítajú to hlavne stavebníci, ktorí bojujú s malým pozemkom, kde príde vhod každý priestor navyše.
            </p>

            <h1>
                7. Životnosť
            </h1>
            <p>
                Odporcovia montovaných domov uvádzajú ich kratšiu životnosť. Všetko závisí od kvality realizácie a od toho ako sa o svoj dom staráte.
                <strong>
                    {"\u00A0"}Aj murovaný dom, keď je postavený lajdácky vydrží kratšie, ako kvalitne zrealizovaný montovaný dom.{"\u00A0"}
                </strong>
                Všetci sa pýtajú, či montovaný
                dom vydrží 100
                rokov. Ale ruku na srdce, ktorý murovaný dom vydrží 100 rokov v perfektnom stave? Aj 80 ročný murovaný dom je niekedy vhodný tak na zbúranie ako jeho nákladná oprava. Každý kvalitne postavený dom vydrží aj 2 generácie, ale požiadavky každej generácie sú iné, preto bez zásadných zmien
                a rekonštrukcie v takýchto starých domoch bývanie nie je možné.
            </p>

            <h1>
                8. Variabilnosť
            </h1>

            <p>
                V neposlednom rade sú montované domy variabilné, to znamená ľahšie sa prestavujú a priečky sa dajú jednoduchšie premiestňovať. Umožňuje tak vytvárať akékoľvek dispozičné riešenia. Montované prvky ponúkajú možnosť jednoduchých zmien v dispozícii stavby aj počas jej plnej prevádzky. V
                prípade, že stavebník má konkrétnu predstavu, je možné projekt stavby prispôsobiť jeho požiadavkám.
            </p>
        </article>
    )
}

export default OsemDovodov
