import {DATABASE_REALIZUJEME} from "../types";


const DatabaseRealizujemeReducer = (state = [], action) => {
    switch (action.type) {
        case DATABASE_REALIZUJEME:
            return action.payload
        default:
            return state
    }
}

export default DatabaseRealizujemeReducer





