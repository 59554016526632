import classes from "./AdminGalleryWrapper.module.scss";
import logo from "../../../assets/logoFull.png";
import Header from "../header/Header";
import EditTab from "./editTab/EditTab";
import UploadTab from "./uploadTab/UploadTab";
import AdminNavbar from "./adminNavbar/AdminNavbar";
import CurrencyTab from "./currencyTab/CurrencyTab";
import Unauthorized from "../unauthorized/Unauthorized";
import {connect} from "react-redux";
import {setAdmin} from "../../../redux/actions/admin";
import {Route, Switch} from "react-router-dom";


function AdminGalleryWrapper({page, admin}) {
    function displayLogo() {
        return (
            <div className={classes.images}>
                <img src={logo} alt="logo"/>
            </div>
        )
    }

    if (admin) return (
        <div className={classes.wrapper}>
            <Header/>
            <AdminNavbar page={page}/>

            <Switch>
                <Route path={`/admin/${page}/pridat`} component={() => <UploadTab page={page}/>}/>
                <Route path={`/admin/${page}/upravit`} component={() => <EditTab page={page}/>}/>
                <Route path={`/admin/${page}/prepocet-ceny`} component={() => <CurrencyTab page={page}/>}/>
                <Route path={`/admin/${page}`} component={() => displayLogo()}/>
            </Switch>
        </div>
    )
    else return <Unauthorized/>
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    }
}

export default connect(mapStateToProps, {setAdmin})(AdminGalleryWrapper)
