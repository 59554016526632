import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {connect} from "react-redux";
import {useState} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {toast} from "react-toastify";
import {setDatabaseTexts} from "../../../redux/actions/databaseTexts";


function LevelsTabText({databaseTexts, databaseLevelsTabTexts, value, handleClose, setDatabaseTexts}) {
    const [state, setState] = useState(value ? {...databaseLevelsTabTexts[value]} : null)

    async function submitWithToast() {
        await toast.promise(submit, {
            pending: "Prebieha upload do databázy",
            success: "Upload úspešný",
            error: "Upload neúspešný"
        })
    }

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "texts", "levelsTab"), {...databaseLevelsTabTexts, [value]: state}, {merge: true})
            .then(() => {
                setDatabaseTexts({...databaseTexts, texts: {...databaseTexts.texts, levelsTab: {...databaseLevelsTabTexts, [value]: state}}})
            })
        setState({...databaseLevelsTabTexts[value]})
        handleClose()
    }

    function handleArrayItemChange(newValue, index) {
        let newItems = databaseLevelsTabTexts[value].items
        newItems[index] = newValue

        setState({...state, items: newItems})
    }

    return (
        <div className={classes.withObject}>
            {Object.keys(databaseLevelsTabTexts[value]).map((item, index) => {
                if (item === "title") {
                    return (
                        <div key={item + index} className={classes.section}>
                            <h3>{`LevelsTab ${item}`}</h3>
                            <input
                                placeholder={`LevelsTab ${item}`}
                                defaultValue={databaseLevelsTabTexts[value][item]}
                                onChange={(e) => setState({...state, [item]: e.target.value})}
                            />
                        </div>
                    )
                }
                if (item === "items") {
                    return (
                        <div key={index}>
                            <h3>{`LevelsTab ${item}`}</h3>
                            {databaseLevelsTabTexts[value].items.map((test, index2) => {
                                return <textarea key={index2} defaultValue={test} onChange={(e) => handleArrayItemChange(e.target.value, index2)}/>
                            })}
                        </div>
                    )
                }
                else return null
            })}
            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts,
        databaseLevelsTabTexts: state.databaseTexts?.texts?.levelsTab
    }
}

export default connect(mapStateToProps, {setDatabaseTexts})(LevelsTabText)