import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {setDatabaseCatalog} from "../../../redux/actions/databaseCatalog";
import {setDatabaseRealizacie} from "../../../redux/actions/databaseRealizacie";
import {deleteDoc, doc, getFirestore, setDoc} from "firebase/firestore";


function ChangeThisFieldInGalleryItem({databaseCatalog, databaseRealizacie, page, setDatabaseCatalog, setDatabaseRealizacie, path, value, handleClose}) {
    const [state, setState] = useState(galleryItem()[value])

    let history = useHistory()

    function galleryItem() {
        if (page) {
            if (page === "katalog" && databaseCatalog?.catalog[path]) {
                return databaseCatalog?.catalog[path]
            } else if (page === "realizacie" && databaseRealizacie?.realizacie[path]) {
                return databaseRealizacie?.realizacie[path]
            }
        }
    }

    async function submitWithToast() {
        if (!Object.keys(page === "katalog" ? databaseCatalog.catalog : databaseRealizacie.realizacie).includes(state.toString())) {
            await toast.promise(submit, {
                pending: "Prebieha upload do databazy",
                success: "Upload uspesny",
                error: "Upload neuspesny"
            })
        } else {
            toast.info(`Dané meno sa už v ${page.toUpperCase()} nachádza, prosím vyberte iné`)
        }
    }

    async function submit() {
        const firestore = getFirestore(firebaseApp)
        if (value !== "name") {
            await setDoc(doc(firestore, page, path), {...galleryItem(), [value]: state}, {merge: true})
                .then(() => {
                    page === "katalog" ?
                        setDatabaseCatalog({...databaseCatalog, catalog: {...databaseCatalog.catalog, [path]: {...galleryItem(), [value]: state}}}) :
                        setDatabaseRealizacie({...databaseRealizacie, realizacie: {...databaseRealizacie.realizacie, [path]: {...galleryItem(), [value]: state}}})
                })
        } else {
            await setDoc(doc(firestore, page, state.toString()), {...galleryItem(), [value]: state}, {merge: true})
            await deleteDoc(doc(firestore, page, path))
                .then(() => {
                    history.push(`/${page}/${state.toString()}`)
                })
        }
        handleClose()
    }

    return (
        <div className={classes.changeOneField}>
            <h1>{path}</h1>
            <textarea defaultValue={galleryItem()[value]} onChange={(e) => setState(e.target.value)}/>
            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        databaseCatalog: state.databaseCatalog,
        databaseRealizacie: state.databaseRealizacie,
    }
}

export default connect(mapStateToProps, {setDatabaseCatalog, setDatabaseRealizacie})(ChangeThisFieldInGalleryItem)