import classes from "./Articles.module.scss";
import Header from "../../../components/admin/header/Header";
import Navbar from "../../../components/navbar/Navbar";
import clanky1 from "../../../assets/clanky1.png";
import clanky2 from "../../../assets/clanky2.png";
import clanky3 from "../../../assets/clanky3.png";
import clanky4 from "../../../assets/clanky4.png";
import clanky5 from "../../../assets/clanky5.png";
import clanky6 from "../../../assets/clanky6.png";
import clanky7 from "../../../assets/clanky7.png";
import clanky8 from "../../../assets/clanky8.png";
import clanky9 from "../../../assets/clanky9.png";
import clanky10 from "../../../assets/clanky10.png";
import clanky11 from "../../../assets/clanky11.png";
import clanky12 from "../../../assets/clanky12.png";
import clanky13 from "../../../assets/clanky13.png";
import clanky14 from "../../../assets/clanky14.png";
import clanky15 from "../../../assets/clanky15.png";
import clanky16 from "../../../assets/clanky16.png";
import clanky17 from "../../../assets/clanky17.png";
import clanky18 from "../../../assets/clanky18.png";
import StatnaDotacia from "../../../components/articles/StatnaDotacia";
import StavebnePovolenie from "../../../components/articles/StavebnePovolenie";
import SpravnaFirma from "../../../components/articles/SpravnaFirma";
import Kolaudacia from "../../../components/articles/Kolaudacia";
import Hypoteka from "../../../components/articles/Hypoteka";
import SpravnyProjekt from "../../../components/articles/SpravnyProjekt";
import NajvhodnejsiaStrecha from "../../../components/articles/NajvhodnejsiaStrecha";
import OsemDovodov from "../../../components/articles/OsemDovodov";
import DrevoAkoMaterial from "../../../components/articles/DrevoAkoMaterial";
import TepelneCerpadlo from "../../../components/articles/TepelneCerpadlo";
import SkladbyStien from "../../../components/articles/SkladbyStien";
import RekuperaciaVzduchu from "../../../components/articles/RekuperaciaVzduchu";
import SedemOtazok from "../../../components/articles/SedemOtazok";
import PraktickeRady from "../../../components/articles/PraktickeRady";
import SvahovityPozemok from "../../../components/articles/SvahovityPozemok";
import Myty from "../../../components/articles/Myty";
import AkoVykurovat from "../../../components/articles/AkoVykurovat";
import Articles404 from "../errorPages/404Articles";
import PriceOfferCTA from "../../../components/priceOfferCTA/PriceOfferCTA";
import {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router-dom";


function Articles() {
    let history = useHistory()

    const articles = [
        {text: "Praktické rady ako zariadiť drevodom", url: "prakticke-rady-ako-zariadit-drevodom", img: clanky1},
        {text: "Svahovitý pozemok - poradíme vám čo s ním", url: "svahovity-pozemok-poradime-vam-co-s-nim", img: clanky6},
        {text: "Mýty o montovaných domoch - veríte im aj vy?", url: "myty-o-montovanych-domoch", img: clanky5},
        {text: "Stupne výstavby montovaného domu", url: "stupne-stavby-montovaneho-domu", img: clanky4},
        {text: "Ako vykurovať drevodom? Poradíme!", url: "ako-vykurovat-drevodom", img: clanky3},
        {text: "Viete ako získať štátnu dotáciu na tepelné čerpadlo?", url: "viete-ako-ziskat-statnu-dotaciu-na-tepelne-cerpadlo", img: clanky2},
        {text: "Ako postupovať pri vybavovaní stavebného povolenia", url: "ako-postupovat-pri-vybavovani-stavebneho povolenia", img: clanky7},
        {text: "Ako si vybrať správnu firmu pre stavbu domu", url: "ako-si-vybrat-spravnu-firmu-pre-stavbu-domu", img: clanky9},
        {text: "Čo všetko je potrebné ku kolaudácií?", url: "co-vsetko-je-potrebne-ku-kolaudacii", img: clanky8},

        {text: "Ako postupovať pri vybavení hypotéky", url: "ako-postupovat-pri-vybaveni-hypoteky", img: clanky18},
        {text: "Ako si vybrať správny projekt", url: "ako-si-vybrat-spravny-projekt", img: clanky17},
        {text: "Aká je najvhodnejšia strecha na drevodom", url: "aka-je-najvhodnejsia-strecha-na-drevodom", img: clanky16},
        {text: "8 presvedčivých dôvodov, prečo povedať drevostavbe ÁNO", url: "8-presvedcivych-dovodov-preco-povedať-drevostavbe-ano", img: clanky15},
        {text: "Drevo ako materiál – zaujímavosti o dreve, o ktorých ste možno netušili", url: "drevo-ako-material-zaujimavosti-o-dreve", img: clanky14},
        {text: "Tepelné čerpadlo", url: "tepelne-cerpadlo", img: clanky13},
        {text: "Skladby stien", url: "skladby-stien", img: clanky12},
        {text: "Rekuperácia vzduchu", url: "rekuperacia-vzduchu", img: clanky11},
        {text: "7 najčastejších otázok a odpovedí týkajúcich sa výstavby drevodomu", url: "7-najcastejsich-otazok-a-odpovedi-tykajucich-sa-vystavby-drevodomu", img: clanky10},
    ]

    function redirect(url) {
        if (url === "stupne-stavby-montovaneho-domu") {
            history.push("/stupne-vystavby")
        } else {
            history.push(`/clanky/${url}`)
        }
    }

    function allArticles() {
        return (
            <ul className={classes.list}>
                {articles.map((item, index) => {
                    return (
                        <li key={index} onClick={() => redirect(item.url)}>
                            <img src={item.img} alt={item.text}/>
                            <p>{item.text}</p>
                        </li>
                    )
                })}
            </ul>
        )
    }

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <div className={classes.articles}>
            <Header/>
            <Navbar/>
            <PriceOfferCTA/>

            <span>
                <Switch>
                    <Route exact path="/clanky/prakticke-rady-ako-zariadit-drevodom" component={PraktickeRady}/>
                    <Route exact path="/clanky/svahovity-pozemok-poradime-vam-co-s-nim" component={SvahovityPozemok}/>
                    <Route exact path="/clanky/myty-o-montovanych-domoch" component={Myty}/>
                    <Route exact path="/clanky/ako-vykurovat-drevodom" component={AkoVykurovat}/>
                    <Route exact path="/clanky/viete-ako-ziskat-statnu-dotaciu-na-tepelne-cerpadlo" component={StatnaDotacia}/>
                    <Route exact path="/clanky/ako-postupovat-pri-vybavovani-stavebneho povolenia" component={StavebnePovolenie}/>
                    <Route exact path="/clanky/ako-si-vybrat-spravnu-firmu-pre-stavbu-domu" component={SpravnaFirma}/>
                    <Route exact path="/clanky/co-vsetko-je-potrebne-ku-kolaudacii" component={Kolaudacia}/>
                    <Route exact path="/clanky/ako-postupovat-pri-vybaveni-hypoteky" component={Hypoteka}/>
                    <Route exact path="/clanky/ako-si-vybrat-spravny-projekt" component={SpravnyProjekt}/>
                    <Route exact path="/clanky/aka-je-najvhodnejsia-strecha-na-drevodom" component={NajvhodnejsiaStrecha}/>
                    <Route exact path="/clanky/8-presvedcivych-dovodov-preco-povedať-drevostavbe-ano" component={OsemDovodov}/>
                    <Route exact path="/clanky/drevo-ako-material-zaujimavosti-o-dreve" component={DrevoAkoMaterial}/>
                    <Route exact path="/clanky/tepelne-cerpadlo" component={TepelneCerpadlo}/>
                    <Route exact path="/clanky/skladby-stien" component={SkladbyStien}/>
                    <Route exact path="/clanky/rekuperacia-vzduchu" component={RekuperaciaVzduchu}/>
                    <Route exact path="/clanky/7-najcastejsich-otazok-a-odpovedi-tykajucich-sa-vystavby-drevodomu" component={SedemOtazok}/>
                    <Route exact path="/clanky" component={() => allArticles()}/>
                    <Route component={() => <Articles404/>}/>
                </Switch>
            </span>
        </div>
    )
}


export default Articles
