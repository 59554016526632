import classes from "./Levels.module.scss";
import mainImage from "../../../assets/stupne výstavby hlavny.png";
import img1 from "../../../assets/stupne-vystavby-1.jpg";
import img2 from "../../../assets/stupne-vystavby-2.jpeg";
import img3 from "../../../assets/stupne-vystavby-3.jpg";
import img4 from "../../../assets/stupne-vystavby-4.jpeg";
import img5 from "../../../assets/stupne-vystavby-5.jpg";
import Header from "../../../components/admin/header/Header";
import Navbar from "../../../components/navbar/Navbar";
import EditWrapper from "../../../components/editWrapper/EditWrapper";
import PulseButton from "../../../components/pulseButton/PulseButton";
import PriceOfferCTA from "../../../components/priceOfferCTA/PriceOfferCTA";
import {connect} from "react-redux";
import {useEffect} from "react";


function Levels({databaseTexts}) {
    const images = [img1, img2, img3, img4, img5]

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    function sortObject(obj) {
        return Object.keys(obj).sort().reduce(function (result, key) {
            result[key] = obj[key]
            return result
        }, {})
    }

    return (
        <span>
            <Header/>
            <Navbar/>
            <PriceOfferCTA/>

            <article className={classes.levels}>
                <section className={classes.mainImage}>
                    <img src={mainImage} alt="Stupne výstavby"/>
                </section>
                {Object.values(sortObject(databaseTexts.texts.levelsTab)).map((item, index) => {
                    return (
                        <section
                            key={index}
                            style={{flexDirection: item.left ? "row" : "row-reverse"}}
                            className={`${classes.section} ${item.left ? classes.reverse : undefined}`}
                        >
                            {images[index] ? <img src={images[index]} alt="Stupne výstavby"/> : null}
                            <div className={classes.text}>
                                <h1>{window.location.host.split(".").pop() !== "cz" ? item?.title : item?.titleCZ}</h1>
                                <EditWrapper dialogCase={images[index] ? "levelsTab" : undefined} value={images[index] ? `faza${index + 1}` : undefined}>
                                    <ul>
                                        {(window.location.host.split(".").pop() !== "cz" ? item?.items?.map((item, index) => {
                                            return (
                                                <li key={index}>{item}</li>
                                            )
                                        }) : item?.itemsCZ?.map((item, index) => {
                                            return (
                                                <li key={index}>{item}</li>
                                            )
                                        }))}
                                    </ul>
                                </EditWrapper>
                            </div>
                        </section>
                    )
                })}

                <PulseButton text={window.location.host.split(".").pop() !== "cz" ? "Prejsť do katalógu" : "Přejít do katalogu"} redirectUrl="/katalog"/>
            </article>
        </span>
    )
}


const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts
    }
}

export default connect(mapStateToProps)(Levels)
