import classes from "../DialogWrapper.module.scss";
import firebaseApp from "../../../firebase";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {setDatabaseTexts} from "../../../redux/actions/databaseTexts";
import {useEffect, useState} from "react";
import {doc, getFirestore, setDoc} from "firebase/firestore";


function AddContact({databaseContactsTexts, databaseTexts, setDatabaseTexts, handleClose}) {
    const [state, setState] = useState({name: "", job: "", phone: "", email: "", jobCZ: "", location: "all", timestamp: new Date().getTime()})
    const [existingContacts, setExistingContacts] = useState([])

    useEffect(() => {
        let people = []
        for (const person of Object.values(databaseContactsTexts.people)) {
            people.push(person.name)
        }
        setExistingContacts(people)
    }, [databaseContactsTexts.people])

    async function submit() {
        const people = databaseContactsTexts.people
        people[state.name] = state
        const firestore = getFirestore(firebaseApp)
        await setDoc(doc(firestore, "texts", "contacts"), {...databaseContactsTexts, people: people}, {merge: true})
            .then(() => {
                setDatabaseTexts({...databaseTexts, texts: {...databaseTexts.texts, contacts: {...databaseTexts.texts.contacts, people: people}}})
            })
        setState({name: "", job: "", phone: "", email: "", jobCZ: "", location: "all", timestamp: new Date().getTime()})
        handleClose()
    }

    async function submitWithToast() {
        if (!existingContacts.includes(state.name)) {
            await toast.promise(submit, {
                pending: "Prebieha upload do databazy",
                success: "Upload uspesny",
                error: "Upload neuspesny"
            })
        } else {
            toast.info("Dané meno sa už v kontaktoch nachádza, prosím vyberte iné")
        }
    }

    return (
        <div>
            <h2>Pridať kontakt</h2>
            <div className={classes.location}>
                <h3>Kde sa má kontakt zobraziť?</h3>
                <ul>
                    <li>
                        <p>Všade</p>
                        <input type="checkbox" checked={state.location === "all"} onClick={(e) => setState({...state, location: "all"})} onChange={() => console.log()}/>
                    </li>
                    <li>
                        <p>Iba SK</p>
                        <input type="checkbox" checked={state.location === "sk"} onClick={(e) => setState({...state, location: "sk"})} onChange={() => console.log()}/>
                    </li>
                    <li>
                        <p>Iba CZ</p>
                        <input type="checkbox" checked={state.location === "cz"} onClick={(e) => setState({...state, location: "cz"})} onChange={() => console.log()}/>
                    </li>
                </ul>
            </div>

            <div className={classes.contacts}>
                <h3>Meno</h3>
                <input autoFocus value={state.name} onChange={(e) => setState({...state, name: e.target.value})}/>
            </div>
            <div className={classes.contacts}>
                <h3>Pozícia</h3>
                <input value={state.job} onChange={(e) => setState({...state, job: e.target.value})}/>
            </div>
            <div className={classes.contacts}>
                <h3>Pozícia CZ</h3>
                <input value={state.jobCZ} onChange={(e) => setState({...state, jobCZ: e.target.value})}/>
            </div>
            <div className={classes.contacts}>
                <h3>Mobil</h3>
                <input value={state.phone} onChange={(e) => setState({...state, phone: e.target.value})}/>
            </div>
            <div className={classes.contacts}>
                <h3>Email</h3>
                <input value={state.email} onChange={(e) => setState({...state, email: e.target.value})}/>
            </div>

            <button className={classes.submitButton} onClick={submitWithToast}>Uložiť zmeny</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        databaseTexts: state.databaseTexts,
        databaseContactsTexts: state.databaseTexts?.texts?.contacts
    }
}

export default connect(mapStateToProps, {setDatabaseTexts})(AddContact)